import { FC, useState, useEffect } from 'react';
import { Wand2, Loader2, Upload } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useAISettings } from '@/hooks/useAISettings';
import { generateImage, uploadGeneratedImage } from '@/services/api/image';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Checkbox } from '@/components/ui/checkbox';

interface ImageGeneratorProps {
    onImageSelect: (url: string) => void;
    blogPost?: {
        title: string;
        excerpt?: string;
        content?: string;
    };
}

interface FieldSelection {
    title: boolean;
    excerpt: boolean;
    content: boolean;
}

const ImageGenerator: FC<ImageGeneratorProps> = ({ onImageSelect, blogPost }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [size, setSize] = useState('1024x1024');
    const [style, setStyle] = useState<string>();
    const [generatedImages, setGeneratedImages] = useState<string[]>([]);
    const [selectedFields, setSelectedFields] = useState<FieldSelection>({
        title: true,
        excerpt: true,
        content: true
    });

    const { settings: aiSettings, loading: settingsLoading } = useAISettings();
    const provider = aiSettings.imageProvider;
    const model = aiSettings.imageModels[provider].find(
        m => m.id === aiSettings.selectedImageModel[provider]
    );

    // Generate default prompt when dialog opens or blog content changes
    useEffect(() => {
        if (open && blogPost?.title) {
            let newPrompt = '';
            const promptParts: string[] = [];

            if (selectedFields.title && blogPost.title) {
                promptParts.push(`Create an image that will complement this blog post titled "${blogPost.title}".`);
            }

            if (selectedFields.excerpt && blogPost.excerpt) {
                promptParts.push(`The post is about: ${blogPost.excerpt}`);
            }

            if (selectedFields.content && blogPost.content) {
                // Extract first 200 characters of content for context
                const contentPreview = blogPost.content.replace(/<[^>]*>/g, '').slice(0, 200);
                if (contentPreview) {
                    promptParts.push(`Here's a preview of the content: ${contentPreview}...`);
                }
            }

            newPrompt = promptParts.join(' ');
            setPrompt(newPrompt || 'Create an image for a blog post.');
        }
    }, [open, blogPost, selectedFields]);

    const handleGenerate = async () => {
        if (!prompt.trim()) {
            toast.error('Please enter a prompt');
            return;
        }

        setLoading(true);
        try {
            const { data, error } = await generateImage({
                prompt,
                size,
                style,
                n: 4 // Generate 4 variations
            });

            if (error) throw new Error(error);
            if (!data) throw new Error('No images generated');

            setGeneratedImages(data);
        } catch (error) {
            console.error('Error generating images:', error);
            toast.error(error instanceof Error ? error.message : 'Failed to generate images');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectImage = async (imageData: string) => {
        setLoading(true);
        try {
            const path = `generated/${Date.now()}.png`;
            const { url, error } = await uploadGeneratedImage(imageData, path);
            
            if (error) throw new Error(error);
            if (!url) throw new Error('Failed to get upload URL');

            onImageSelect(url);
            setOpen(false);
            toast.success('Image added successfully');
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error(error instanceof Error ? error.message : 'Failed to upload image');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <button
                    className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 
                        text-gray-700 dark:text-gray-200 rounded-lg cursor-pointer transition-colors text-sm font-medium"
                >
                    <Wand2 className="w-4 h-4" />
                    Generate Image
                </button>
            </DialogTrigger>
            <DialogContent className="max-w-3xl">
                <DialogHeader>
                    <DialogTitle>Generate Blog Image</DialogTitle>
                    <DialogDescription>
                        Generate unique images for your blog post using AI
                    </DialogDescription>
                </DialogHeader>

                <div className="space-y-6">
                    {/* Field Selection */}
                    <div className="space-y-2">
                        <Label>Include in Prompt</Label>
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center space-x-2">
                                <Checkbox 
                                    id="title"
                                    checked={selectedFields.title}
                                    onCheckedChange={(checked) => 
                                        setSelectedFields(prev => ({ ...prev, title: checked as boolean }))
                                    }
                                />
                                <label 
                                    htmlFor="title"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Blog Title
                                </label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Checkbox 
                                    id="excerpt"
                                    checked={selectedFields.excerpt}
                                    onCheckedChange={(checked) => 
                                        setSelectedFields(prev => ({ ...prev, excerpt: checked as boolean }))
                                    }
                                />
                                <label 
                                    htmlFor="excerpt"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Blog Excerpt
                                </label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Checkbox 
                                    id="content"
                                    checked={selectedFields.content}
                                    onCheckedChange={(checked) => 
                                        setSelectedFields(prev => ({ ...prev, content: checked as boolean }))
                                    }
                                />
                                <label 
                                    htmlFor="content"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Blog Content Preview
                                </label>
                            </div>
                        </div>
                    </div>

                    {/* Controls */}
                    <div className="space-y-4">
                        <div className="space-y-2">
                            <Label>Prompt</Label>
                            <Input
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                                placeholder="Describe the image you want to generate..."
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label>Size</Label>
                                <Select value={size} onValueChange={setSize}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select size" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {model?.sizes?.map(s => (
                                            <SelectItem key={s} value={s}>{s}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>

                            {model?.styles && (
                                <div className="space-y-2">
                                    <Label>Style</Label>
                                    <Select value={style} onValueChange={setStyle}>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select style" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {model.styles.map(s => (
                                                <SelectItem key={s} value={s}>
                                                    {s.split('-').map(word => 
                                                        word.charAt(0).toUpperCase() + word.slice(1)
                                                    ).join(' ')}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            )}
                        </div>

                        <Button
                            className="w-full"
                            onClick={handleGenerate}
                            disabled={loading || !prompt.trim()}
                        >
                            {loading ? (
                                <>
                                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                                    Generating...
                                </>
                            ) : (
                                <>
                                    <Wand2 className="w-4 h-4 mr-2" />
                                    Generate
                                </>
                            )}
                        </Button>
                    </div>

                    {/* Generated Images */}
                    {generatedImages.length > 0 && (
                        <div className="grid grid-cols-2 gap-4">
                            {generatedImages.map((imageData, index) => (
                                <div key={index} className="relative group">
                                    <img
                                        src={`data:image/png;base64,${imageData}`}
                                        alt={`Generated image ${index + 1}`}
                                        className="w-full h-auto rounded-lg border border-gray-700"
                                    />
                                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black/50 rounded-lg">
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => handleSelectImage(imageData)}
                                            disabled={loading}
                                        >
                                            <Upload className="w-4 h-4 mr-2" />
                                            Select
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ImageGenerator;
