import { useState, useEffect } from 'react';
import { aiConfig, type AIConfig } from '@/services/config/ai';
import { aiSettingsApi } from '@/services/api/aiSettings';

export const useAISettings = () => {
  const [settings, setSettings] = useState<AIConfig>(aiConfig);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await aiSettingsApi.getSettings();
        if (settings) {
          setSettings(settings);
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to load AI settings'));
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  return {
    settings,
    loading,
    error
  };
};
