import { aiSettingsApi } from '@/services/api/aiSettings';

export type AIProvider = 'openai' | 'deepseek';
export type ImageProvider = 'openai' | 'stability';

export interface AIConfig {
  provider: AIProvider;
  imageProvider: ImageProvider;
  endpoints: {
    [key in AIProvider]: string;
  };
  imageEndpoints: {
    [key in ImageProvider]: string;
  };
  models: {
    [key in AIProvider]: {
      id: string;
      name: string;
      is_available: boolean;
    }[];
  };
  imageModels: {
    [key in ImageProvider]: {
      id: string;
      name: string;
      is_available: boolean;
      sizes?: string[];
      styles?: string[];
    }[];
  };
  selectedModel: {
    [key in AIProvider]: string;
  };
  selectedImageModel: {
    [key in ImageProvider]: string;
  };
}

const defaultConfig: AIConfig = {
  provider: 'openai',
  imageProvider: 'openai',
  endpoints: {
    openai: 'https://api.openai.com/v1/chat/completions',
    deepseek: 'https://api.deepseek.ai/v1/chat/completions'
  },
  imageEndpoints: {
    openai: 'https://api.openai.com/v1/images/generations',
    stability: 'https://api.stability.ai/v1/generation'
  },
  models: {
    openai: [
      { id: 'gpt-4', name: 'GPT-4', is_available: true },
      { id: 'gpt-4o-mini', name: 'gpt-4o-mini', is_available: true },
    ],
    deepseek: [
      { id: 'deepseek-chat', name: 'DeepSeek Chat', is_available: true },
      { id: 'deepseek-coder', name: 'DeepSeek Coder', is_available: false },
    ],
  },
  imageModels: {
    openai: [
      { 
        id: 'dall-e-3', 
        name: 'DALL-E 3',
        is_available: true,
        sizes: ['1024x1024', '1024x1792', '1792x1024'],
        styles: ['vivid', 'natural']
      },
      { 
        id: 'dall-e-2', 
        name: 'DALL-E 2',
        is_available: true,
        sizes: ['256x256', '512x512', '1024x1024']
      }
    ],
    stability: [
      {
        id: 'stable-diffusion-xl-1024-v1-0',
        name: 'Stable Diffusion XL',
        is_available: true,
        sizes: ['1024x1024', '1152x896', '896x1152'],
        styles: ['enhance', 'anime', 'photographic', 'digital-art', 'origami', 'line-art', 'analog-film', 'neon-punk', 'isometric', 'low-poly', 'pixel-art', 'modeling-compound', 'cinematic', '3d-model']
      }
    ]
  },
  selectedModel: {
    openai: 'gpt-3.5-turbo',
    deepseek: 'deepseek-chat',
  },
  selectedImageModel: {
    openai: 'dall-e-3',
    stability: 'stable-diffusion-xl-1024-v1-0'
  }
};

// Load config from Supabase or use default
async function loadConfig(): Promise<AIConfig> {
  try {
    // Try to get settings from Supabase
    const settings = await aiSettingsApi.getSettings();
    if (settings) {
      // Validate and merge with default config to ensure all required fields exist
      return {
        ...defaultConfig,
        ...settings,
        models: {
          ...defaultConfig.models,
          ...settings.models,
        },
        endpoints: {
          ...defaultConfig.endpoints,
          ...settings.endpoints,
        },
        imageModels: {
          ...defaultConfig.imageModels,
          ...settings.imageModels,
        },
        imageEndpoints: {
          ...defaultConfig.imageEndpoints,
          ...settings.imageEndpoints,
        },
        selectedModel: {
          ...defaultConfig.selectedModel,
          ...settings.selectedModel,
        },
        selectedImageModel: {
          ...defaultConfig.selectedImageModel,
          ...settings.selectedImageModel,
        },
      };
    }

    // If no settings exist, initialize with default config
    await aiSettingsApi.initializeSettings(defaultConfig);
    return defaultConfig;
  } catch (error) {
    console.error('Error loading AI config:', error);
    return defaultConfig;
  }
}

// Save config to Supabase
export async function saveConfig(config: Partial<AIConfig>) {
  try {
    await aiSettingsApi.updateSettings(config);
    // Update the singleton instance
    Object.assign(aiConfig, { ...aiConfig, ...config });
  } catch (error) {
    console.error('Error saving AI config:', error);
  }
}

// Reset config to default
export const resetConfig = async () => {
  try {
    await aiSettingsApi.updateSettings(defaultConfig);
    aiConfig = { ...defaultConfig };
  } catch (error) {
    console.error('Error resetting AI config:', error);
    throw error;
  }
};

// Initialize with default config, will be updated after async load
export let aiConfig: AIConfig = defaultConfig;

// Load the config asynchronously
loadConfig().then(config => {
  aiConfig = config;
}).catch(error => {
  console.error('Error loading AI config:', error);
});
