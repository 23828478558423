import { FC } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

interface Model {
  id: string;
  name: string;
  is_available: boolean;
}

interface ModelManagerProps {
  models: Model[];
  onAddModel: () => void;
  onRemoveModel: (id: string) => void;
  onUpdateModel: (id: string, updates: Partial<Model>) => void;
}

export const ModelManager: FC<ModelManagerProps> = ({
  models,
  onAddModel,
  onRemoveModel,
  onUpdateModel,
}) => {
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Available Models</h3>
        <Button
          onClick={() => {
            console.log('Add Model button clicked');
            onAddModel();
          }}
          variant="outline"
          size="sm"
          className="flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Add Model
        </Button>
      </div>
      
      <div className="space-y-4">
        {models.map((model) => (
          <div
            key={model.id}
            className="flex items-center gap-4 p-4 border rounded-lg bg-card"
          >
            <div className="flex-grow space-y-4">
              <div className="flex gap-4">
                <div className="flex-1">
                  <Label>Model ID</Label>
                  <Input
                    value={model.id}
                    onChange={(e) => onUpdateModel(model.id, { id: e.target.value })}
                    placeholder="Enter model ID"
                  />
                </div>
                <div className="flex-1">
                  <Label>Display Name</Label>
                  <Input
                    value={model.name}
                    onChange={(e) => onUpdateModel(model.id, { name: e.target.value })}
                    placeholder="Enter display name"
                  />
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  checked={model.is_available}
                  onCheckedChange={(checked) =>
                    onUpdateModel(model.id, { is_available: checked })
                  }
                  className="bg-gray-300 dark:bg-gray-600 data-[state=checked]:bg-primary-500 dark:data-[state=checked]:bg-primary-400 border-2 border-gray-400 dark:border-gray-500"
                />
                <Label className="text-foreground dark:text-gray-200">Available</Label>
              </div>
            </div>
            <Button
              variant="ghost"
              size="icon"
              className="text-destructive hover:text-destructive hover:bg-destructive/10"
              onClick={() => onRemoveModel(model.id)}
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
