import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PrivacyDialog from '@components/dialogs/PrivacyDialog';
import TermsDialog from '@components/dialogs/TermsDialog';
import DocumentationDialog from '@components/dialogs/DocumentationDialog';
import { NewsletterSubscribe } from '@components/newsletter/NewsletterSubscribe';

interface FooterProps {
  showNewsletter?: boolean;
}

const Footer: React.FC<FooterProps> = ({ showNewsletter = true }) => {
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isDocumentationOpen, setIsDocumentationOpen] = useState(false);

  return (
    <>
      {showNewsletter && <NewsletterSubscribe />}
      <footer className="bg-gray-900 text-white">
        <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="col-span-1">
              <h3 className="text-xl font-bold text-white">Scholar Tree</h3>
              <p className="mt-4 text-gray-300 dark:text-gray-200">
                Empowering students with AI-powered learning tools and personalized tutoring experiences.
              </p>
            </div>

            <div>
              <h3 className="text-sm font-semibold text-gray-200 dark:text-gray-100 uppercase tracking-wider">Resources</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <button 
                    onClick={() => setIsDocumentationOpen(true)}
                    className="text-base text-gray-300 dark:text-gray-200 hover:text-white"
                  >
                    Documentation
                  </button>
                </li>
                <li>
                  <a href="#" className="text-base text-gray-300 dark:text-gray-200 hover:text-white">
                    Tutorials
                  </a>
                </li>
                <li>
                  <Link to="/blog" className="text-base text-gray-300 dark:text-gray-200 hover:text-white">
                    Blog
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-sm font-semibold text-gray-200 dark:text-gray-100 uppercase tracking-wider">Affiliate Program</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link to="/affiliate" className="text-base text-gray-300 dark:text-gray-200 hover:text-white">
                    Affiliate Overview
                  </Link>
                </li>
                <li>
                  <Link to="/affiliate/login" className="text-base text-gray-300 dark:text-gray-200 hover:text-white">
                    Affiliate Login
                  </Link>
                </li>
                <li>
                  <Link to="/affiliate/apply" className="text-base text-gray-300 dark:text-gray-200 hover:text-white">
                    Become an Affiliate
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-sm font-semibold text-gray-200 dark:text-gray-100 uppercase tracking-wider">Legal</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <button 
                    onClick={() => setIsPrivacyOpen(true)}
                    className="text-base text-gray-300 dark:text-gray-200 hover:text-white"
                  >
                    Privacy Policy
                  </button>
                </li>
                <li>
                  <button 
                    onClick={() => setIsTermsOpen(true)}
                    className="text-base text-gray-300 dark:text-gray-200 hover:text-white"
                  >
                    Terms of Service
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-800">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <p className="text-base text-center text-gray-300 dark:text-gray-200">
              &copy; {new Date().getFullYear()} Elyon Labs, LLC. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
      <PrivacyDialog 
        isOpen={isPrivacyOpen}
        onClose={() => setIsPrivacyOpen(false)}
      />
      <TermsDialog
        isOpen={isTermsOpen}
        onClose={() => setIsTermsOpen(false)}
      />
      <DocumentationDialog
        isOpen={isDocumentationOpen}
        onClose={() => setIsDocumentationOpen(false)}
      />
    </>
  );
};

export default Footer;