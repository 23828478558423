import { type FC, type ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import Navbar from '@components/layout/Navbar';
import Footer from '@components/layout/Footer';
import AIChatButton from '@components/chat/AIChatButton';
import LoadingBar from '@components/common/LoadingBar';

interface BlogLayoutProps {
  children: ReactNode;
}

const BlogLayout: FC<BlogLayoutProps> = ({ children }) => (
  <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
    <LoadingBar />
    <AnimatePresence mode="wait">
      <main>
        <Navbar />
        <div className="pt-32">
          {children}
        </div>
        <Footer />
      </main>
    </AnimatePresence>
    <AIChatButton />
  </div>
);

export default BlogLayout;
