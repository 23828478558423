import { supabase } from '@/lib/supabaseClient';
import type { BlogPost, BlogCategory, BlogComment } from '@/types/blog';

// Post Management
export const createPost = async (post: Omit<BlogPost, 'id' | 'created_at' | 'updated_at'>) => {
    try {
        const { data, error } = await supabase
            .from('blog_posts')
            .insert({
                ...post,
                slug: await generateSlug(post.title)
            })
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error creating blog post:', error);
        return { data: null, error };
    }
};

export const updatePost = async (id: string, post: Partial<BlogPost>) => {
    try {
        const { data, error } = await supabase
            .from('blog_posts')
            .update(post)
            .eq('id', id)
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error updating blog post:', error);
        return { data: null, error };
    }
};

export const updatePostStatus = async (id: string, status: 'published' | 'draft' | 'archived'): Promise<void> => {
    const { error } = await supabase
        .from('blog_posts')
        .update({
            status,
            ...(status === 'published' ? { published_at: new Date().toISOString() } : {})
        })
        .eq('id', id);

    if (error) {
        throw error;
    }
}

export const deletePost = async (id: string) => {
    try {
        const { error } = await supabase
            .from('blog_posts')
            .delete()
            .eq('id', id);

        if (error) throw error;
        return { error: null };
    } catch (error) {
        console.error('Error deleting blog post:', error);
        return { error };
    }
};

export const getPost = async (idOrSlug: string) => {
    try {
        console.log('Fetching blog post with id/slug:', idOrSlug);
        
        // Try to parse as UUID to determine if it's an ID
        const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(idOrSlug);
        console.log('Is UUID?', isUUID);
        
        // Get the current session
        const session = await supabase.auth.getSession();
        console.log('Current session:', {
            isAuthenticated: !!session.data.session,
            role: session.data.session?.user?.role
        });
        
        const { data, error } = await supabase
            .from('blog_posts')
            .select(`
                *,
                blog_posts_categories (
                    blog_categories (*)
                ),
                blog_comments (*)
            `)
            .eq(isUUID ? 'id' : 'slug', idOrSlug)
            .single();
        
        console.log('Query result:', { 
            success: !error,
            error: error ? {
                message: error.message,
                code: error.code,
                details: error.details,
                hint: error.hint
            } : null,
            hasData: !!data
        });

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error fetching blog post:', error);
        return { data: null, error };
    }
};

export const getPosts = async (options: {
    page?: number;
    limit?: number;
    status?: string;
    category?: string;
    tag?: string;
} = {}) => {
    try {
        console.log('Fetching posts with options:', options);
        
        // Get the current session
        const session = await supabase.auth.getSession();
        console.log('Current session:', {
            isAuthenticated: !!session.data.session,
            role: session.data.session?.user?.role
        });

        const {
            page = 1,
            limit = 10,
            status,
            category,
            tag
        } = options;

        let query = supabase
            .from('blog_posts')
            .select(`
                *,
                blog_posts_categories (
                    blog_categories (*)
                )
            `, { count: 'exact' });

        // Apply filters
        if (status) {
            query = query.eq('status', status);
            console.log('Added status filter:', status);
        }
        if (tag) {
            query = query.contains('tags', [tag]);
            console.log('Added tag filter:', tag);
        }
        if (category) {
            query = query
                .eq('blog_posts_categories.blog_categories.slug', category);
            console.log('Added category filter:', category);
        }

        // Apply pagination
        const from = (page - 1) * limit;
        const to = from + limit - 1;
        
        console.log('Pagination range:', { from, to });
        
        const { data, error, count } = await query
            .order('published_at', { ascending: false })
            .range(from, to);
            
        console.log('Query result:', { 
            success: !error,
            error: error ? {
                message: error.message,
                code: error.code,
                details: error.details,
                hint: error.hint
            } : null,
            recordCount: count,
            hasData: !!data,
            dataLength: data?.length
        });

        if (error) throw error;
        return { data, count, error: null };
    } catch (error) {
        console.error('Error fetching blog posts:', error);
        return { data: null, count: 0, error };
    }
};

// Category Management
export const createCategory = async (category: Omit<BlogCategory, 'id' | 'created_at' | 'updated_at'>) => {
    try {
        const { data, error } = await supabase
            .from('blog_categories')
            .insert({
                ...category,
                slug: await generateSlug(category.name)
            })
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error creating blog category:', error);
        return { data: null, error };
    }
};

export const updateCategory = async (id: string, category: Partial<BlogCategory>) => {
    try {
        const { data, error } = await supabase
            .from('blog_categories')
            .update(category)
            .eq('id', id)
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error updating blog category:', error);
        return { data: null, error };
    }
};

export const deleteCategory = async (id: string) => {
    try {
        const { error } = await supabase
            .from('blog_categories')
            .delete()
            .eq('id', id);

        if (error) throw error;
        return { error: null };
    } catch (error) {
        console.error('Error deleting blog category:', error);
        return { error };
    }
};

export const getCategories = async () => {
    try {
        const { data, error } = await supabase
            .from('blog_categories')
            .select('*')
            .order('name');

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error fetching blog categories:', error);
        return { data: null, error };
    }
};

// Comment Management
export const createComment = async (comment: Omit<BlogComment, 'id' | 'created_at' | 'updated_at'>) => {
    try {
        const { data, error } = await supabase
            .from('blog_comments')
            .insert(comment)
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error creating blog comment:', error);
        return { data: null, error };
    }
};

export const updateCommentStatus = async (id: string, status: string) => {
    try {
        const { data, error } = await supabase
            .from('blog_comments')
            .update({ status })
            .eq('id', id)
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error updating comment status:', error);
        return { data: null, error };
    }
};

export const deleteComment = async (id: string) => {
    try {
        const { error } = await supabase
            .from('blog_comments')
            .delete()
            .eq('id', id);

        if (error) throw error;
        return { error: null };
    } catch (error) {
        console.error('Error deleting blog comment:', error);
        return { error };
    }
};

// Helper Functions
const generateSlug = async (title: string): Promise<string> => {
    const baseSlug = title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

    let slug = baseSlug;
    let counter = 1;

    while (true) {
        // Check if slug exists
        const { data, error } = await supabase
            .from('blog_posts')
            .select('id')
            .eq('slug', slug);

        if (error) {
            console.error('Error checking slug uniqueness:', error);
            // If there's an error, append timestamp to make it unique
            return `${baseSlug}-${Date.now()}`;
        }

        // If no posts found with this slug, it's unique
        if (!data || data.length === 0) {
            return slug;
        }

        // If slug exists, append counter and try again
        slug = `${baseSlug}-${counter}`;
        counter++;
    }
};
