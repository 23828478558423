import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import Footer from '@/components/layout/Footer';

interface AffiliateLayoutProps {
    children: React.ReactNode;
    showBackLink?: boolean;
}

const AffiliateLayout: React.FC<AffiliateLayoutProps> = ({ children, showBackLink = true }) => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
            <div className="container mx-auto px-4 pt-8">
                {showBackLink && (
                    <Link
                        to="/"
                        className="inline-flex items-center gap-2 text-gray-400 hover:text-primary-400 transition-colors duration-200 mb-8"
                    >
                        <ChevronLeft className="w-4 h-4" />
                        Back to Home
                    </Link>
                )}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    {children}
                </motion.div>
            </div>
            <Footer showNewsletter={false} />
        </div>
    );
};

export default AffiliateLayout;
