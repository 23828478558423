import Stripe from "stripe";
import { supabase } from "@/lib/supabaseClient";
import { PaymentHistory, PayoutRequest } from '@/types/affiliate';

const stripe = new Stripe(import.meta.env.VITE_STRIPE_SECRET_KEY!, {
  apiVersion: '2023-10-16',
});

export const paymentApi = {
  /**
   * Create a Stripe Connect account for an affiliate
   */
  createConnectAccount: async (affiliateId: string, email: string) => {
    const { data: affiliate } = await supabase
      .from('affiliates')
      .select('*')
      .eq('id', affiliateId)
      .single();

    if (!affiliate) {
      throw new Error('Affiliate not found');
    }

    const account = await stripe.accounts.create({
      type: 'express',
      email,
      capabilities: {
        transfers: { requested: true },
      },
    });

    // Store the Stripe account ID in our database
    await supabase
      .from('affiliates')
      .update({
        payout_info: {
          stripe_account_id: account.id,
          setup_complete: false,
        },
      })
      .eq('id', affiliateId);

    return account;
  },

  /**
   * Create an account link for completing the Stripe Connect onboarding
   */
  createAccountLink: async (affiliateId: string) => {
    const { data: affiliate } = await supabase
      .from('affiliates')
      .select('*')
      .eq('id', affiliateId)
      .single();

    if (!affiliate?.payout_info?.stripe_account_id) {
      throw new Error('Stripe account not found for affiliate');
    }

    return stripe.accountLinks.create({
      account: affiliate.payout_info.stripe_account_id,
      refresh_url: `${import.meta.env.VITE_APP_URL}/affiliate/dashboard?setup=failed`,
      return_url: `${import.meta.env.VITE_APP_URL}/affiliate/dashboard?setup=success`,
      type: 'account_onboarding',
    });
  },

  /**
   * Process a payout request using Stripe
   */
  processPayout: async (payoutRequestId: string) => {
    const { data: payoutRequest } = await supabase
      .from('payout_requests')
      .select(`
        *,
        affiliates (
          id,
          payout_info
        )
      `)
      .eq('id', payoutRequestId)
      .single();

    if (!payoutRequest) {
      throw new Error('Payout request not found');
    }

    const stripeAccountId = payoutRequest.affiliates?.payout_info?.stripe_account_id;
    if (!stripeAccountId) {
      throw new Error('Stripe account not found for affiliate');
    }

    // Create a transfer to the affiliate's connected account
    const transfer = await stripe.transfers.create({
      amount: Math.round(payoutRequest.amount * 100), // Convert to cents
      currency: 'usd',
      destination: stripeAccountId,
      metadata: {
        payout_request_id: payoutRequestId,
        affiliate_id: payoutRequest.affiliate_id,
      },
    });

    // Update payout request status
    await supabase
      .from('payout_requests')
      .update({
        status: 'processed',
        processed_at: new Date().toISOString(),
        payout_details: {
          stripe_transfer_id: transfer.id,
          processed_amount: payoutRequest.amount,
        },
      })
      .eq('id', payoutRequestId);

    return transfer;
  },

  /**
   * Check if an affiliate has completed their Stripe Connect onboarding
   */
  checkAccountStatus: async (affiliateId: string) => {
    const { data: affiliate } = await supabase
      .from('affiliates')
      .select('*')
      .eq('id', affiliateId)
      .single();

    if (!affiliate?.payout_info?.stripe_account_id) {
      return { setup_complete: false };
    }

    const account = await stripe.accounts.retrieve(affiliate.payout_info.stripe_account_id);
    const setup_complete = account.details_submitted && account.payouts_enabled;

    // Update the setup status in our database
    if (setup_complete !== affiliate.payout_info.setup_complete) {
      await supabase
        .from('affiliates')
        .update({
          payout_info: {
            ...affiliate.payout_info,
            setup_complete,
          },
        })
        .eq('id', affiliateId);
    }

    return {
      setup_complete,
      account_status: account.payouts_enabled ? 'active' : 'pending',
    };
  },

  /**
   * Get the payment history
   */
  getPaymentHistory: async (): Promise<PaymentHistory[]> => {
    const { data, error } = await supabase
      .from('payment_history')
      .select('*')
      .order('processed_at', { ascending: false });

    if (error) throw error;
    return data;
  },

  /**
   * Schedule a payout
   */
  schedulePayout: async (payoutRequestId: string, scheduledDate: string): Promise<PayoutRequest> => {
    const { data, error } = await supabase
      .from('payout_requests')
      .update({ 
        status: 'scheduled',
        scheduled_date: scheduledDate 
      })
      .eq('id', payoutRequestId)
      .select()
      .single();

    if (error) throw error;
    return data;
  }
};
