import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronRight, DollarSign, Users, BarChart, Shield } from 'lucide-react';
import affiliateHero from '../assets/affiliate-hero.webp';

const AffiliatePage: React.FC = () => {
    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 }
    };

    const tiers = [
        {
            name: 'Basic',
            commission: '10%',
            requirements: 'Open to all content creators',
            features: [
                'Basic promotional materials',
                'Monthly payments',
                'Basic analytics dashboard',
                'Email support'
            ]
        },
        {
            name: 'Advanced',
            commission: '15%',
            requirements: 'Minimum 1000 followers',
            features: [
                'Advanced promotional materials',
                'Bi-weekly payments',
                'Advanced analytics dashboard',
                'Priority email support',
                'Custom landing pages'
            ]
        },
        {
            name: 'Elite',
            commission: '24%',
            requirements: 'Invitation only',
            features: [
                'Custom promotional materials',
                'Weekly payments',
                'Real-time analytics dashboard',
                'Dedicated account manager',
                'Custom landing pages',
                'Early access to new features'
            ]
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
            {/* Hero Section */}
            <section className="relative overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
                <div className="container mx-auto px-4">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="flex flex-wrap items-center -mx-4"
                    >
                        <div className="w-full px-4 lg:w-5/12">
                            <div className="hero-content">
                                <h1 className="mb-5 text-4xl font-bold !leading-[1.208] text-white sm:text-[42px] lg:text-[40px] xl:text-5xl">
                                    Grow Your Income with ScholarTree Affiliate Program
                                </h1>
                                <p className="mb-8 max-w-[480px] text-base text-gray-400">
                                    Join our affiliate program and earn competitive commissions by helping students discover
                                    the perfect educational opportunities. Partner with us to make a difference while growing your income.
                                </p>

                                <Link
                                    to="/affiliate/apply"
                                    className="inline-flex items-center gap-2 rounded-lg bg-primary-500 py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-primary-600"
                                >
                                    Apply Now
                                    <ChevronRight className="w-5 h-5" />
                                </Link>
                            </div>
                        </div>

                        <div className="hidden px-4 lg:block lg:w-7/12">
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                className="lg:ml-4"
                            >
                                <div className="relative z-10 inline-block pt-11 lg:pt-0">
                                    <img
                                        src={affiliateHero}
                                        alt="Affiliate Program Hero"
                                        className="max-w-full lg:ml-auto"
                                    />
                                </div>
                            </motion.div>
                        </div>
                    </motion.div>
                </div>
            </section>

            {/* Benefits Section */}
            <section className="py-20 bg-gray-800/50">
                <div className="container mx-auto px-4">
                    <motion.div
                        variants={container}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true }}
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
                    >
                        <motion.div variants={item} className="p-6 rounded-2xl bg-white/5 backdrop-blur-xl border border-white/10">
                            <DollarSign className="w-12 h-12 text-primary-500 mb-4" />
                            <h3 className="text-xl font-semibold text-white mb-2">Competitive Commission</h3>
                            <p className="text-gray-400">Earn up to 24% commission on qualified referrals</p>
                        </motion.div>

                        <motion.div variants={item} className="p-6 rounded-2xl bg-white/5 backdrop-blur-xl border border-white/10">
                            <Users className="w-12 h-12 text-primary-500 mb-4" />
                            <h3 className="text-xl font-semibold text-white mb-2">Dedicated Support</h3>
                            <p className="text-gray-400">Get personalized support from our affiliate team</p>
                        </motion.div>

                        <motion.div variants={item} className="p-6 rounded-2xl bg-white/5 backdrop-blur-xl border border-white/10">
                            <BarChart className="w-12 h-12 text-primary-500 mb-4" />
                            <h3 className="text-xl font-semibold text-white mb-2">Real-time Analytics</h3>
                            <p className="text-gray-400">Track your performance with detailed analytics</p>
                        </motion.div>

                        <motion.div variants={item} className="p-6 rounded-2xl bg-white/5 backdrop-blur-xl border border-white/10">
                            <Shield className="w-12 h-12 text-primary-500 mb-4" />
                            <h3 className="text-xl font-semibold text-white mb-2">Reliable Payments</h3>
                            <p className="text-gray-400">Regular payouts with multiple payment options</p>
                        </motion.div>
                    </motion.div>
                </div>
            </section>

            {/* Commission Tiers */}
            <section className="py-20">
                <div className="container mx-auto px-4">
                    <div className="text-center mb-16">
                        <h2 className="text-3xl font-bold text-white mb-4">Commission Tiers</h2>
                        <p className="text-gray-400 max-w-2xl mx-auto">
                            Choose the tier that best fits your audience and earning potential. Upgrade your tier as you grow.
                        </p>
                    </div>

                    <motion.div
                        variants={container}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true }}
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                    >
                        {tiers.map((tier, index) => (
                            <motion.div
                                key={tier.name}
                                variants={item}
                                className={`p-8 rounded-2xl backdrop-blur-xl border ${
                                    index === 2
                                        ? 'bg-primary-500/10 border-primary-500/50'
                                        : 'bg-white/5 border-white/10'
                                }`}
                            >
                                <h3 className="text-2xl font-bold text-white mb-4">{tier.name}</h3>
                                <div className="text-4xl font-bold text-primary-500 mb-4">{tier.commission}</div>
                                <p className="text-gray-400 mb-6">{tier.requirements}</p>
                                <ul className="space-y-3">
                                    {tier.features.map((feature, i) => (
                                        <li key={i} className="flex items-center text-gray-300">
                                            <ChevronRight className="w-5 h-5 text-primary-500 mr-2" />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="py-20 bg-gradient-to-r from-primary-500/20 via-primary-500/10 to-primary-500/20">
                <div className="container mx-auto px-4 text-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="max-w-2xl mx-auto"
                    >
                        <h2 className="text-3xl font-bold text-white mb-4">
                            Ready to Start Earning?
                        </h2>
                        <p className="text-gray-400 mb-8">
                            Join our affiliate program today and start earning competitive commissions while helping students
                            find their perfect educational path.
                        </p>
                        <Link
                            to="/affiliate/apply"
                            className="inline-flex items-center gap-2 rounded-lg bg-primary-500 py-4 px-8 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-primary-600"
                        >
                            Apply Now
                            <ChevronRight className="w-5 h-5" />
                        </Link>
                    </motion.div>
                </div>
            </section>
        </div>
    );
};

export default AffiliatePage;
