import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Send, Mail, Loader2 } from 'lucide-react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { toast } from 'react-hot-toast';
import { sendNewsletter } from '@services/api/smtp';

interface NewsletterComposerProps {
    subscriberCount: number;
}

const quillModules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link', 'image'],
        ['clean']
    ]
};

const quillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list',
    'link', 'image',
    'color', 'background'
];

export default function NewsletterComposer({ subscriberCount }: NewsletterComposerProps) {
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [testEmail, setTestEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [showTestEmail, setShowTestEmail] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        // Initial check
        setIsDarkMode(document.documentElement.classList.contains('dark'));

        // Create observer to watch for class changes
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setIsDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        // Start observing
        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        // Cleanup
        return () => observer.disconnect();
    }, []);

    const handleSend = async (isTest: boolean = false) => {
        if (!subject || !content) {
            toast.error('Please fill in both subject and content');
            return;
        }

        if (isTest && !testEmail) {
            toast.error('Please enter a test email address');
            return;
        }

        setSending(true);
        try {
            console.log('Sending newsletter:', {
                subject,
                contentLength: content.length,
                isTest,
                testEmail: isTest ? testEmail : null
            });

            const { error } = await sendNewsletter(
                subject,
                content,
                isTest ? testEmail : null
            );
            
            if (error) throw error;
            
            toast.success(isTest 
                ? 'Test email sent successfully!' 
                : `Newsletter sent to ${subscriberCount} subscribers!`
            );

            if (!isTest) {
                // Clear form after successful send
                setSubject('');
                setContent('');
            }
        } catch (error) {
            console.error('Send error:', error);
            toast.error(isTest 
                ? 'Failed to send test email' 
                : 'Failed to send newsletter'
            );
        } finally {
            setSending(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6"
        >
            <div className="flex items-center gap-2 mb-6">
                <Mail className="w-5 h-5 text-primary-500" />
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Compose Newsletter</h2>
            </div>

            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Subject
                    </label>
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                                 focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
                        placeholder="Newsletter Subject"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Content
                    </label>
                    <div className={`quill-wrapper ${isDarkMode ? 'quill-dark' : ''}`}>
                        <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={setContent}
                            modules={quillModules}
                            formats={quillFormats}
                            className="rounded-lg"
                        />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row justify-between items-center gap-4 pt-10">
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => setShowTestEmail(!showTestEmail)}
                            className="text-sm text-primary-600 hover:text-primary-700 dark:text-primary-500 dark:hover:text-primary-400"
                        >
                            {showTestEmail ? 'Hide Test Email' : 'Send Test Email'}
                        </button>
                        {showTestEmail && (
                            <input
                                type="email"
                                value={testEmail}
                                onChange={(e) => setTestEmail(e.target.value)}
                                className="px-3 py-1 border border-gray-300 dark:border-gray-600 rounded-lg 
                                         focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 text-gray-900 dark:text-white text-sm"
                                placeholder="test@example.com"
                            />
                        )}
                    </div>
                    
                    <div className="flex items-center gap-2">
                        {showTestEmail && (
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={() => handleSend(true)}
                                disabled={sending}
                                className={`flex items-center gap-2 px-4 py-2 
                                          bg-white hover:bg-secondary-50 text-secondary-600 border border-secondary-500
                                          dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white dark:border-gray-600
                                          rounded-lg transition-colors 
                                          ${sending ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {sending ? (
                                    <Loader2 className="w-4 h-4 animate-spin text-secondary-600 dark:text-white" />
                                ) : (
                                    <Send className="w-4 h-4 text-secondary-600 dark:text-white" />
                                )}
                                Send Test
                            </motion.button>
                        )}
                        
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={() => handleSend(false)}
                            disabled={sending}
                            className={`flex items-center gap-2 px-4 py-2 bg-primary-500 hover:bg-primary-600 text-white rounded-lg
                                      transition-colors ${sending ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {sending ? (
                                <Loader2 className="w-4 h-4 animate-spin text-white" />
                            ) : (
                                <Send className="w-4 h-4 text-white" />
                            )}
                            Send to {subscriberCount} Subscribers
                        </motion.button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}
