import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MarkdownMessageProps {
  content: string;
  className?: string;
}

export default function MarkdownMessage({ content, className = '' }: MarkdownMessageProps) {
  const components = {
    code({ node, inline, className, children, ...props }: { node?: any; inline?: boolean; className?: string; children?: React.ReactNode }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={vscDarkPlus}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    a: (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
      <a
        {...props}
        className="text-blue-400 hover:text-blue-300 underline"
        target="_blank"
        rel="noopener noreferrer"
      />
    ),
    ul: (props: React.HTMLAttributes<HTMLUListElement>) => (
      <ul {...props} className="list-disc list-inside my-2" />
    ),
    ol: (props: React.HTMLAttributes<HTMLOListElement>) => (
      <ol {...props} className="list-decimal list-inside my-2" />
    ),
    h1: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
      <h1 {...props} className="text-2xl font-bold my-3" />
    ),
    h2: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
      <h2 {...props} className="text-xl font-bold my-2" />
    ),
    h3: (props: React.HTMLAttributes<HTMLHeadingElement>) => (
      <h3 {...props} className="text-lg font-bold my-2" />
    ),
    p: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
      <p {...props} className="my-2" />
    ),
    blockquote: (props: React.HTMLAttributes<HTMLElement>) => (
      <blockquote
        {...props}
        className="border-l-4 border-gray-500 pl-4 my-2 italic"
      />
    ),
    table: (props: React.HTMLAttributes<HTMLTableElement>) => (
      <table {...props} className="border-collapse table-auto w-full my-2" />
    ),
    th: (props: React.HTMLAttributes<HTMLTableHeaderCellElement>) => (
      <th {...props} className="border border-gray-600 px-4 py-2 bg-gray-800" />
    ),
    td: (props: React.HTMLAttributes<HTMLTableDataCellElement>) => (
      <td {...props} className="border border-gray-600 px-4 py-2" />
    ),
    img: (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
      <img {...props} alt={props.alt || 'Image'} className="max-w-full h-auto" />
    ),
  };

  return (
    <ReactMarkdown
      className={`prose prose-invert max-w-none ${className}`}
      remarkPlugins={[remarkGfm]}
      components={components}
    >
      {content}
    </ReactMarkdown>
  );
}
