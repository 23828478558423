import { supabase } from '@/lib/supabaseClient';

export interface EmailTemplate {
    id: string;
    name: string;
    subject: string;
    html_content: string;
    template_type: string;
    is_active: boolean;
    created_at: string;
    updated_at: string;
}

export const getTemplates = async () => {
    try {
        const { data, error } = await supabase
            .from('email_templates')
            .select('*')
            .order('name');

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error fetching email templates:', error);
        return { data: null, error };
    }
};

export const getTemplate = async (id: string) => {
    try {
        const { data, error } = await supabase
            .from('email_templates')
            .select('*')
            .eq('id', id)
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error fetching email template:', error);
        return { data: null, error };
    }
};

export const createTemplate = async (template: Omit<EmailTemplate, 'id' | 'created_at' | 'updated_at'>) => {
    try {
        const { data, error } = await supabase
            .from('email_templates')
            .insert(template)
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error creating email template:', error);
        return { data: null, error };
    }
};

export const updateTemplate = async (id: string, template: Partial<EmailTemplate>) => {
    try {
        const { data, error } = await supabase
            .from('email_templates')
            .update(template)
            .eq('id', id)
            .select()
            .single();

        if (error) throw error;
        return { data, error: null };
    } catch (error) {
        console.error('Error updating email template:', error);
        return { data: null, error };
    }
};

export const deleteTemplate = async (id: string) => {
    try {
        const { error } = await supabase
            .from('email_templates')
            .delete()
            .eq('id', id);

        if (error) throw error;
        return { error: null };
    } catch (error) {
        console.error('Error deleting email template:', error);
        return { error };
    }
};
