import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { supabase } from '@/lib/supabaseClient';

export default function AdminDashboard() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const umamiUrl = import.meta.env.VITE_UMAMI_DASHBOARD_URL;

    useEffect(() => {
        const checkAuth = async () => {
            const { data: { session }, error: sessionError } = await supabase.auth.getSession();
            
            if (sessionError || !session) {
                navigate('/admin/login');
                return;
            }

            // Check if user has admin role
            const { data: roleData, error: roleError } = await supabase
                .from('user_roles')
                .select('role')
                .eq('user_id', session.user.id)
                .eq('role', 'admin')
                .maybeSingle();

            if (roleError || !roleData) {
                await supabase.auth.signOut();
                navigate('/admin/login');
                return;
            }

            setIsLoading(false);
        };
        checkAuth();
    }, [navigate]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-white">
            <div className="container mx-auto px-4 py-8 space-y-8">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold">Welcome to Admin Dashboard</h1>
                </div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6"
                >
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Site Analytics</h2>
                    <div className="w-full h-[54rem] bg-white">
                        <iframe
                            src={umamiUrl}
                            className="w-full h-full border-0 rounded-lg"
                            title="Umami Analytics Dashboard"
                        />
                    </div>
                </motion.div>
            </div>
        </div>
    );
}
