import { type FC, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Loader2, Download, Calendar } from 'lucide-react';
import { affiliateApi } from '@/services/api/affiliate';
import { paymentApi } from '@/services/api/payments';
import { formatCurrency } from '@/lib/utils';
import { format } from 'date-fns';
import type { PayoutRequest, AffiliateDetails, PaymentHistory } from '@/types/affiliate';
import { supabase } from '@/lib/supabaseClient';

interface AffiliateWithApplication {
  id: string;
  user_id: string;
  affiliate_applications: {
    first_name: string;
    last_name: string;
    email: string;
  }[];
}

const PayoutManagement: FC = () => {
  const queryClient = useQueryClient();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [processingId, setProcessingId] = useState<string | null>(null);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [scheduleDate, setScheduleDate] = useState('');
  const [activeTab, setActiveTab] = useState('pending');
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch payout requests
  const { data: payoutRequests, isLoading } = useQuery<PayoutRequest[]>({
    queryKey: ['payout-requests'],
    queryFn: affiliateApi.getPayoutRequests,
  });

  // Fetch payment history
  const { data: paymentHistory } = useQuery<PaymentHistory[]>({
    queryKey: ['payment-history'],
    queryFn: () => paymentApi.getPaymentHistory(),
    enabled: activeTab === 'history'
  });

  // Fetch affiliate details for each unique affiliate
  const affiliateIds = [...new Set([
    ...(payoutRequests?.map(req => req.affiliate_id) || []),
    ...(paymentHistory?.map(payment => payment.affiliate_id) || [])
  ])];

  const { data: affiliateDetails } = useQuery<Record<string, AffiliateDetails>>({
    queryKey: ['affiliate-details', affiliateIds],
    queryFn: async () => {
      if (!affiliateIds.length) return {};

      const { data } = await supabase
        .from('affiliates')
        .select(`
          id,
          user_id,
          affiliate_applications (
            first_name,
            last_name,
            email
          )
        `)
        .in('id', affiliateIds);

      return (data || []).reduce<Record<string, AffiliateDetails>>((acc, affiliate: AffiliateWithApplication) => {
        if (affiliate.affiliate_applications?.[0]) {
          acc[affiliate.id] = {
            id: affiliate.id,
            user_id: affiliate.user_id,
            first_name: affiliate.affiliate_applications[0].first_name,
            last_name: affiliate.affiliate_applications[0].last_name,
            email: affiliate.affiliate_applications[0].email,
          };
        }
        return acc;
      }, {});
    },
    enabled: !!affiliateIds.length,
  });

  const batchProcessMutation = useMutation({
    mutationFn: async (ids: string[]) => {
      const results = await Promise.allSettled(
        ids.map(id => paymentApi.processPayout(id))
      );
      
      const succeeded = results.filter(r => r.status === 'fulfilled').length;
      const failed = results.filter(r => r.status === 'rejected').length;
      
      return { succeeded, failed };
    },
    onSuccess: (result) => {
      toast.success(`Processed ${result.succeeded} payouts successfully${result.failed ? `. ${result.failed} failed` : ''}`);
      queryClient.invalidateQueries({ queryKey: ['payout-requests'] });
      queryClient.invalidateQueries({ queryKey: ['payment-history'] });
      setSelectedIds([]);
    },
    onError: (error) => {
      console.error('Error processing batch payouts:', error);
      toast.error('Failed to process batch payouts');
    },
  });

  const schedulePayoutsMutation = useMutation({
    mutationFn: async (data: { ids: string[], scheduleDate: string }) => {
      return Promise.all(
        data.ids.map(id => paymentApi.schedulePayout(id, data.scheduleDate))
      );
    },
    onSuccess: () => {
      toast.success('Payouts scheduled successfully');
      queryClient.invalidateQueries({ queryKey: ['payout-requests'] });
      setSelectedIds([]);
      setShowScheduleDialog(false);
    },
    onError: (error) => {
      console.error('Error scheduling payouts:', error);
      toast.error('Failed to schedule payouts');
    },
  });

  const handleExport = (data: any[]) => {
    const csvContent = [
      ['Affiliate', 'Email', 'Amount', 'Status', 'Date'].join(','),
      ...data.map(item => [
        affiliateDetails?.[item.affiliate_id]?.first_name || 'Unknown',
        affiliateDetails?.[item.affiliate_id]?.last_name || 'Unknown',
        affiliateDetails?.[item.affiliate_id]?.email || 'Unknown',
        item.amount,
        item.status,
        format(new Date(item.created_at), 'yyyy-MM-dd HH:mm:ss')
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `payout-${activeTab}-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const filteredRequests = payoutRequests?.filter(req => {
    const affiliate = affiliateDetails?.[req.affiliate_id];
    return !searchTerm || 
      affiliate?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.email.toLowerCase().includes(searchTerm.toLowerCase());
  }) || [];

  const pendingRequests = filteredRequests.filter(req => req.status === 'pending');
  const scheduledRequests = filteredRequests.filter(req => req.status === 'scheduled');

  const filteredHistory = paymentHistory?.filter(payment => {
    const affiliate = affiliateDetails?.[payment.affiliate_id];
    return !searchTerm || 
      affiliate?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.email.toLowerCase().includes(searchTerm.toLowerCase());
  }) || [];

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedIds(pendingRequests.map(req => req.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelectPayout = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedIds(prev => [...prev, id]);
    } else {
      setSelectedIds(prev => prev.filter(payoutId => payoutId !== id));
    }
  };

  return (
    <div className="p-8">
      <div className="flex flex-col gap-8">
        <div>
          <h1 className="text-3xl font-semibold mb-2">Payout Management</h1>
          <p className="text-muted-foreground">Manage and process affiliate payout requests</p>
        </div>
        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-4">
            {selectedIds.length > 0 && (
              <>
                <Button
                  onClick={() => batchProcessMutation.mutate(selectedIds)}
                  disabled={batchProcessMutation.isPending}
                >
                  {batchProcessMutation.isPending ? (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  ) : null}
                  Process Selected ({selectedIds.length})
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setShowScheduleDialog(true)}
                >
                  <Calendar className="w-4 h-4 mr-2" />
                  Schedule Selected
                </Button>
              </>
            )}
            <Button
              variant="outline"
              onClick={() => handleExport(activeTab === 'history' ? filteredHistory : filteredRequests)}
            >
              <Download className="w-4 h-4 mr-2" />
              Export {activeTab === 'history' ? 'History' : 'Requests'}
            </Button>
          </div>
        </div>

        <div className="mb-6">
          <Label htmlFor="search">Search</Label>
          <Input
            id="search"
            placeholder="Search by affiliate name or email..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger value="pending">
              Pending ({pendingRequests.length})
            </TabsTrigger>
            <TabsTrigger value="scheduled">
              Scheduled ({scheduledRequests.length})
            </TabsTrigger>
            <TabsTrigger value="history">
              Payment History
            </TabsTrigger>
          </TabsList>

          <TabsContent value="pending">
            <Card>
              <CardHeader>
                <CardTitle>Pending Payout Requests</CardTitle>
                <CardDescription>
                  Review and process pending payout requests
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[40px]">
                        <Checkbox
                          checked={Boolean(selectedIds.length === pendingRequests.length && pendingRequests.length > 0)}
                          onCheckedChange={handleSelectAll}
                        />
                      </TableHead>
                      <TableHead>Affiliate</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead>Requested</TableHead>
                      <TableHead>Payment Method</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {pendingRequests.map((request) => {
                      const affiliate = affiliateDetails?.[request.affiliate_id];
                      return (
                        <TableRow key={request.id}>
                          <TableCell>
                            <Checkbox
                              checked={Boolean(selectedIds.includes(request.id))}
                              onCheckedChange={(checked) => handleSelectPayout(Boolean(checked), request.id)}
                            />
                          </TableCell>
                          <TableCell>
                            <div>
                              <div className="font-medium">{affiliate ? `${affiliate.first_name} ${affiliate.last_name}` : 'Unknown Affiliate'}</div>
                              <div className="text-sm text-gray-400">{affiliate?.email}</div>
                            </div>
                          </TableCell>
                          <TableCell>{formatCurrency(request.amount)}</TableCell>
                          <TableCell>{format(new Date(request.created_at), 'MMM d, yyyy')}</TableCell>
                          <TableCell className="capitalize">{request.payment_method}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => {
                                setProcessingId(request.id);
                                batchProcessMutation.mutate([request.id]);
                              }}
                              disabled={processingId === request.id}
                              size="sm"
                            >
                              {processingId === request.id ? 'Processing...' : 'Process'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="scheduled">
            <Card>
              <CardHeader>
                <CardTitle>Scheduled Payouts</CardTitle>
                <CardDescription>
                  View and manage scheduled payouts
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Affiliate</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead>Scheduled For</TableHead>
                      <TableHead>Payment Method</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {scheduledRequests.map((request) => {
                      const affiliate = affiliateDetails?.[request.affiliate_id];
                      const scheduledDate = request.scheduled_date ? new Date(request.scheduled_date) : new Date();
                      return (
                        <TableRow key={request.id}>
                          <TableCell>
                            <div>
                              <div className="font-medium">{affiliate ? `${affiliate.first_name} ${affiliate.last_name}` : 'Unknown Affiliate'}</div>
                              <div className="text-sm text-gray-400">{affiliate?.email}</div>
                            </div>
                          </TableCell>
                          <TableCell>{formatCurrency(request.amount)}</TableCell>
                          <TableCell>{format(scheduledDate, 'MMM d, yyyy')}</TableCell>
                          <TableCell className="capitalize">{request.payment_method}</TableCell>
                          <TableCell>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                // Implement cancel scheduling
                              }}
                            >
                              Cancel
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="history">
            <Card>
              <CardHeader>
                <CardTitle>Payment History</CardTitle>
                <CardDescription>
                  View all processed payments
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Affiliate</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead>Processed</TableHead>
                      <TableHead>Payment Method</TableHead>
                      <TableHead>Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredHistory.map((payment) => {
                      const affiliate = affiliateDetails?.[payment.affiliate_id];
                      return (
                        <TableRow key={payment.id}>
                          <TableCell>
                            <div>
                              <div className="font-medium">{affiliate ? `${affiliate.first_name} ${affiliate.last_name}` : 'Unknown Affiliate'}</div>
                              <div className="text-sm text-gray-400">{affiliate?.email}</div>
                            </div>
                          </TableCell>
                          <TableCell>{formatCurrency(payment.amount)}</TableCell>
                          <TableCell>{format(new Date(payment.processed_at), 'MMM d, yyyy')}</TableCell>
                          <TableCell className="capitalize">{payment.payment_method}</TableCell>
                          <TableCell>
                            <span className={`px-2 py-1 rounded-full text-xs ${
                              payment.status === 'completed'
                                ? 'bg-green-500/20 text-green-400'
                                : payment.status === 'failed'
                                ? 'bg-red-500/20 text-red-400'
                                : 'bg-yellow-500/20 text-yellow-400'
                            }`}>
                              {payment.status}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        <Dialog open={showScheduleDialog} onOpenChange={setShowScheduleDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Schedule Payouts</DialogTitle>
              <DialogDescription>
                Select a date to process the selected payouts
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid gap-2">
                <Label htmlFor="date">Processing Date</Label>
                <Input
                  id="date"
                  type="date"
                  value={scheduleDate}
                  onChange={(e) => setScheduleDate(e.target.value)}
                  min={format(new Date(), 'yyyy-MM-dd')}
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => setShowScheduleDialog(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => schedulePayoutsMutation.mutate({ ids: selectedIds, scheduleDate })}
                disabled={!scheduleDate || schedulePayoutsMutation.isPending}
              >
                {schedulePayoutsMutation.isPending ? 'Scheduling...' : 'Schedule'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default PayoutManagement;
