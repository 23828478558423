import React from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.bubble.css';

interface RichTextContentProps {
    content: string;
    className?: string;
}

const RichTextContent: React.FC<RichTextContentProps> = ({ content, className = '' }) => {
    return (
        <div className={`rich-text-content ${className}`}>
            <ReactQuill
                value={content}
                readOnly={true}
                theme="bubble"
                modules={{ toolbar: false }}
            />
        </div>
    );
};

export default RichTextContent;
