import React from 'react';
import { motion } from 'framer-motion';
import ChatAnalytics from '@components/analytics/ChatAnalytics';

const AnalyticsPage: React.FC = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6"
            >
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">Chat Analytics</h2>
                <ChatAnalytics />
            </motion.div>
        </div>
    );
};

export default AnalyticsPage;
