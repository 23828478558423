import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail } from 'lucide-react';
import PrivacyDialog from '@components/dialogs/PrivacyDialog';
import { subscribeToNewsletter } from '@/services/api/newsletter';

export const NewsletterSubscribe: React.FC = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(() => {
        return sessionStorage.getItem('newsletter_subscribed') === 'true';
    });

    const handleSubscriptionSuccess = () => {
        setIsSubscribed(true);
        sessionStorage.setItem('newsletter_subscribed', 'true');
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            const { data, error } = await subscribeToNewsletter(email);
            
            if (error) {
                if (error.type === 'success') {
                    toast.success(error.message);
                    handleSubscriptionSuccess();
                } else if (error.type === 'info') {
                    toast(error.message, {
                        icon: '📧',
                    });
                } else {
                    toast.error(error.message || 'Failed to subscribe. Please try again later.');
                }
            } else if (data?.status === 'active') {
                toast.success('Successfully subscribed to the newsletter!');
                setEmail('');
                handleSubscriptionSuccess();
            }
        } catch (err) {
            console.error('Newsletter subscription error:', err);
            toast.error('An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-900 py-16 sm:py-24">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <AnimatePresence mode="wait">
                    {isSubscribed ? (
                        <motion.div
                            key="success"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="relative isolate overflow-hidden bg-gradient-to-r from-accent-500 to-primary-500 px-6 py-12 shadow-2xl rounded-2xl sm:rounded-3xl sm:px-24 xl:py-16">
                                <div className="absolute inset-0 bg-gradient-to-r from-accent-500/95 to-primary-500/95 backdrop-blur-sm rounded-2xl sm:rounded-3xl" />
                                <div className="relative z-10 text-center">
                                    <motion.div
                                        initial={{ scale: 0.9, opacity: 0 }}
                                        animate={{ scale: 1, opacity: 1 }}
                                        transition={{ duration: 0.5, delay: 0.2 }}
                                    >
                                        <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl mb-4">
                                            Thank you for subscribing!
                                        </h2>
                                        <div className="w-full px-6 sm:px-8">
                                            <p className="mt-2 text-lg leading-8 text-white/90 break-normal text-center">
                                                We're excited to keep you updated with our latest features and educational content.
                                            </p>
                                            <p className="mt-6 text-lg leading-8 text-white/90 break-normal text-center">
                                                Please note that we take spam seriously and monitor for abuse.
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="form"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className="relative isolate overflow-hidden bg-gradient-to-r from-primary-500 to-accent-500 px-6 py-12 shadow-2xl rounded-2xl sm:rounded-3xl sm:px-24 xl:py-16">
                                <div className="absolute inset-0 bg-gradient-to-r from-primary-500/95 to-accent-500/95 backdrop-blur-sm rounded-2xl sm:rounded-3xl" />
                                <div className="relative z-10">
                                    <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                        Get notified about updates.
                                    </h2>
                                    <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-white/90">
                                        Sign up for our newsletter to stay up to date with feature releases and educational content.
                                    </p>
                                    <form onSubmit={handleSubmit} className="mx-auto mt-10 flex max-w-md gap-x-4">
                                        <label htmlFor="email-address" className="sr-only">
                                            Email address
                                        </label>
                                        <motion.input
                                            id="email-address"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="min-w-0 flex-auto rounded-xl border-0 bg-white/10 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6 placeholder:text-white/60"
                                            placeholder="Enter your email"
                                            whileFocus={{ scale: 1.02 }}
                                            transition={{ type: "spring", stiffness: 300 }}
                                        />
                                        <motion.button
                                            type="submit"
                                            disabled={loading}
                                            className="flex-none rounded-xl bg-gradient-to-r from-accent-500 to-accent-600 hover:from-accent-400 hover:to-accent-500 
                                            px-3.5 py-2.5 text-sm font-semibold text-white shadow-lg shadow-accent-500/20 
                                            hover:shadow-accent-500/40 hover:-translate-y-0.5 transition-all duration-200
                                            disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:translate-y-0"
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            {loading ? (
                                                <Mail className="h-5 w-5 animate-spin" />
                                            ) : (
                                                "Subscribe"
                                            )}
                                        </motion.button>
                                    </form>
                                    <div className="mt-4 text-center">
                                        <button
                                            onClick={() => setIsPrivacyOpen(true)}
                                            className="text-sm text-white/80 hover:text-white underline transition-colors duration-200"
                                        >
                                            View Privacy Policy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <PrivacyDialog isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} />
        </div>
    );
};
