import React, { useEffect, useState } from 'react';
import type { AnalyticsSummary } from '@/types/analytics';
import { getAnalyticsSummary, analyzeQueriesWithAI, deleteOldAnalytics, deleteQuestion } from '@/services/api/analytics';
import { Tooltip } from '@components/common/Tooltip';
import { toast } from 'react-hot-toast';
import { Trash2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

// Helper functions for formatting
const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    }).format(date);
};

const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0
    }).format(num);
};

const ChatAnalytics: React.FC = () => {
    const [analytics, setAnalytics] = useState<AnalyticsSummary | null>(null);
    const [loading, setLoading] = useState(true);
    const [analyzing, setAnalyzing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const loadAnalytics = async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await getAnalyticsSummary(true); // Skip AI analysis
            setAnalytics(data);
        } catch (err) {
            setError('Failed to load analytics data');
            console.error('Error loading analytics:', err);
        } finally {
            setLoading(false);
        }
    };

    const runAIAnalysis = async () => {
        if (!analytics?.recentQuestions.length) return;

        setAnalyzing(true);
        try {
            const questions = analytics.recentQuestions.map(q => q.question);
            const groups = await analyzeQueriesWithAI(questions);
            setAnalytics(prev => prev ? { ...prev, queryGroups: groups } : prev);
        } catch (error) {
            console.error('Error analyzing questions:', error);
            toast.error('Failed to analyze questions');
        } finally {
            setAnalyzing(false);
        }
    };

    const handleDeleteOldRecords = async () => {
        if (!window.confirm('Are you sure you want to delete all chat records older than 7 days? This action cannot be undone.')) {
            return;
        }

        setIsDeleting(true);
        try {
            const result = await deleteOldAnalytics();
            if (result.success) {
                toast.success(`Deleted ${result.deletedCount} old records`);
                loadAnalytics(); // Refresh the data
            } else {
                toast.error(result.error || 'Failed to delete old records');
            }
        } catch (error) {
            toast.error('An error occurred while deleting old records');
        } finally {
            setIsDeleting(false);
        }
    };

    const handleDeleteQuestion = async (questionId: string) => {
        if (!window.confirm('Are you sure you want to delete this question? This action cannot be undone.')) {
            return;
        }

        try {
            const result = await deleteQuestion(questionId);
            if (result.success) {
                toast.success('Question deleted successfully');
                loadAnalytics(); // Refresh the data
            } else {
                toast.error(result.error || 'Failed to delete question');
            }
        } catch (error) {
            toast.error('An error occurred while deleting the question');
        }
    };

    useEffect(() => {
        loadAnalytics();
    }, []);

    if (loading) {
        return <div className="p-4">Loading analytics...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-600">{error}</div>;
    }

    if (!analytics) {
        return <div className="p-4">No analytics data available</div>;
    }

    return (
        <div className="p-4 space-y-6">
            {/* Summary Statistics */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Total Interactions</h3>
                    <div className="space-y-2">
                        <p className="text-gray-600 dark:text-gray-300">Last 24h: {analytics.interactionsLast24h}</p>
                        <p className="text-gray-600 dark:text-gray-300">Last Week: {analytics.interactionsLastWeek}</p>
                        <p className="text-gray-600 dark:text-gray-300">Last Month: {analytics.interactionsLastMonth}</p>
                        <p className="text-gray-600 dark:text-gray-300">Total: {analytics.totalInteractions}</p>
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Response Times</h3>
                    <div className="space-y-2">
                        <p className="text-gray-600 dark:text-gray-300">Last 24h Average: {formatNumber(analytics.averageResponseTimes.last24h)}ms</p>
                        <p className="text-gray-600 dark:text-gray-300">Last Week Average: {formatNumber(analytics.averageResponseTimes.lastWeek)}ms</p>
                        <p className="text-gray-600 dark:text-gray-300">Last Month Average: {formatNumber(analytics.averageResponseTimes.lastMonth)}ms</p>
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Token Usage</h3>
                    <div className="space-y-2">
                        <p className="text-gray-600 dark:text-gray-300">Last 24h: {formatNumber(analytics.tokenUsage.last24h)}</p>
                        <p className="text-gray-600 dark:text-gray-300">Last Week: {formatNumber(analytics.tokenUsage.lastWeek)}</p>
                        <p className="text-gray-600 dark:text-gray-300">Last Month: {formatNumber(analytics.tokenUsage.lastMonth)}</p>
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Usage by Provider</h3>
                    <div className="space-y-2">
                        {Object.entries(analytics.providerUsage).map(([provider, count]) => (
                            <p key={provider} className="text-gray-600 dark:text-gray-300">
                                {provider}: {count} queries
                            </p>
                        ))}
                    </div>
                </div>
            </div>

            {/* Recent Questions Table */}
            <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Recent Questions</h3>
                    <div className="flex items-center gap-3">
                        <button
                            onClick={handleDeleteOldRecords}
                            disabled={isDeleting}
                            className="flex items-center gap-2 px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            <Trash2 className="w-4 h-4" />
                            {isDeleting ? 'Deleting...' : 'Delete Old Records'}
                        </button>
                        <button
                            onClick={runAIAnalysis}
                            disabled={analyzing || !analytics?.recentQuestions.length}
                            className="flex items-center gap-2 px-3 py-1 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M12 6V2m0 4v4m0 4v4m0 4v4M4 12h4m8 0h4m-8 0h-4" strokeLinecap="round"/>
                            </svg>
                            {analyzing ? 'Analyzing...' : 'Analyze Questions'}
                        </button>
                    </div>
                </div>
                <div className="overflow-x-auto bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 shadow-md rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
                        <thead className="bg-gray-200 dark:bg-gray-800">
                            <tr>
                                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 dark:text-gray-200 uppercase tracking-wider">Question</th>
                                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 dark:text-gray-200 uppercase tracking-wider">Time</th>
                                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 dark:text-gray-200 uppercase tracking-wider">Response Time</th>
                                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 dark:text-gray-200 uppercase tracking-wider">Model</th>
                                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 dark:text-gray-200 uppercase tracking-wider">Tokens</th>
                                <th className="px-6 py-4 text-left text-xs font-semibold text-gray-700 dark:text-gray-200 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-300 dark:divide-gray-700">
                            {analytics.recentQuestions.map((q) => (
                                <tr key={q.id} className="hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                                        <Tooltip
                                            content={q.answer ? (
                                                <div className="max-w-lg">
                                                    <div className="font-semibold mb-2">AI Response:</div>
                                                    <ReactMarkdown className="whitespace-pre-wrap leading-tight">{q.answer}</ReactMarkdown>
                                                </div>
                                            ) : 'No AI response available'}
                                            disabled={!q.answer}
                                        >
                                            <span className="cursor-pointer hover:underline">{q.question}</span>
                                        </Tooltip>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{formatDate(q.timestamp)}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{q.responseTime}ms</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{q.modelUsed}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{q.tokensUsed || '-'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() => handleDeleteQuestion(q.id)}
                                            className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-600"
                                        >
                                            <Trash2 className="w-5 h-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Query Groups */}
            {analytics.queryGroups.length > 0 && (
                <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg p-6">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Question Groups</h3>
                    <div className="space-y-6">
                        {analytics.queryGroups.map((group, i) => (
                            <div key={i} className="space-y-2">
                                <h4 className="font-medium text-gray-800 dark:text-gray-200">
                                    {group.topic} ({group.totalCount} questions)
                                </h4>
                                <ul className="list-disc list-inside space-y-1 text-sm">
                                    {group.questions.map((q, j) => (
                                        <li key={j} className="text-gray-600 dark:text-gray-300">
                                            {q.question} ({q.count} times)
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatAnalytics;
