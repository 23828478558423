import { type FC, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { affiliateApi } from '@/services/api/affiliate';
import { AffiliateApplication } from '@/types/affiliate';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { MoreHorizontal, Download, Filter, Search, Loader2, Mail, Trash2 } from 'lucide-react';
import { format } from 'date-fns';

const AffiliateManagement: FC = () => {
  const queryClient = useQueryClient();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [selectedAffiliate, setSelectedAffiliate] = useState<AffiliateApplication | null>(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const { data: applications, isLoading, error } = useQuery<AffiliateApplication[]>({
    queryKey: ['affiliate-applications'],
    queryFn: () => affiliateApi.getApplications(),
  });

  const bulkApproveMutation = useMutation({
    mutationFn: (ids: string[]) => Promise.all(ids.map(id => affiliateApi.approveApplication(id))),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success(`${selectedIds.length} applications approved`);
      setSelectedIds([]);
    },
    onError: (error) => {
      console.error('Error approving applications:', error);
      toast.error('Failed to approve applications');
    },
  });

  const bulkRejectMutation = useMutation({
    mutationFn: (ids: string[]) => Promise.all(ids.map(id => affiliateApi.rejectApplication(id))),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success(`${selectedIds.length} applications rejected`);
      setSelectedIds([]);
    },
    onError: (error) => {
      console.error('Error rejecting applications:', error);
      toast.error('Failed to reject applications');
    },
  });

  const toggleStatusMutation = useMutation({
    mutationFn: ({ id, status }: { id: string; status: 'active' | 'inactive' }) => 
      affiliateApi.updateApplicationStatus(id, status),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success('Affiliate status updated successfully');
    },
    onError: (error) => {
      console.error('Error updating affiliate status:', error);
      toast.error('Failed to update affiliate status');
    },
  });

  const deleteAffiliateMutation = useMutation({
    mutationFn: async (affiliateId: string) => {
      await affiliateApi.deleteAffiliate(affiliateId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] });
      toast.success('Affiliate deleted successfully');
    },
    onError: (error) => {
      console.error('Error deleting affiliate:', error);
      toast.error('Failed to delete affiliate: ' + (error instanceof Error ? error.message : 'Unknown error'));
    },
  });

  const resendWelcomeEmailMutation = useMutation({
    mutationFn: async (affiliateId: string) => {
      await affiliateApi.resendWelcomeEmail(affiliateId);
    },
    onSuccess: () => {
      toast.success('Welcome email sent successfully');
    },
    onError: (error) => {
      console.error('Error sending welcome email:', error);
      toast.error('Failed to send welcome email');
    },
  });

  const handleExport = () => {
    const filteredApps = getFilteredApplications();
    const csvContent = [
      ['First Name', 'Last Name', 'Email', 'Website', 'Preferred Tier', 'Status', 'Applied At'].join(','),
      ...filteredApps.map(app => [
        app.first_name,
        app.last_name,
        app.email,
        app.website || 'N/A',
        app.preferred_tier,
        app.status,
        format(new Date(app.created_at), 'yyyy-MM-dd HH:mm:ss')
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `affiliate-applications-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const getFilteredApplications = () => {
    if (!applications) return [];
    
    return applications.filter(app => {
      const matchesSearch = searchTerm === '' || 
        `${app.first_name} ${app.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        app.email.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = !statusFilter || app.status === statusFilter;
      
      return matchesSearch && matchesStatus;
    });
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const filteredIds = getFilteredApplications()
        .filter(app => app.status === 'pending')
        .map(app => app.id);
      setSelectedIds(filteredIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleViewDetails = (affiliate: AffiliateApplication) => {
    setSelectedAffiliate(affiliate);
    setShowDetailsDialog(true);
  };

  const handleToggleStatus = (affiliate: AffiliateApplication) => {
    const newStatus = affiliate.status === 'active' ? 'inactive' : 'active';
    toggleStatusMutation.mutate({ 
      id: affiliate.id, 
      status: newStatus 
    });
  };

  const handleDeleteAffiliate = async (affiliateId: string) => {
    if (window.confirm('Are you sure you want to delete this affiliate? This action cannot be undone. This will delete all related records including notifications, referrals, and payouts.')) {
      await deleteAffiliateMutation.mutateAsync(affiliateId);
    }
  };

  const handleResendWelcomeEmail = async (app: AffiliateApplication) => {
    if (window.confirm(`Are you sure you want to resend the welcome email to ${app.first_name} ${app.last_name}?`)) {
      await resendWelcomeEmailMutation.mutateAsync(app.id);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center">
        <p className="text-red-500">Error loading affiliate applications</p>
        <Button 
          onClick={() => queryClient.invalidateQueries({ queryKey: ['affiliate-applications'] })}
          className="mt-4"
        >
          Retry
        </Button>
      </div>
    );
  }

  const filteredApplications = getFilteredApplications();
  const pendingApplications = filteredApplications.filter(app => app.status === 'pending');

  return (
    <div className="container mx-auto py-8 px-4 space-y-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold">Affiliate Management</h1>
          <p className="text-muted-foreground mt-1">Manage and track affiliate applications</p>
        </div>
        
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
          {selectedIds.length > 0 && (
            <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
              <Button
                onClick={() => bulkApproveMutation.mutate(selectedIds)}
                variant="default"
                disabled={bulkApproveMutation.isPending}
                className="w-full sm:w-auto"
              >
                {bulkApproveMutation.isPending && (
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                )}
                Approve Selected ({selectedIds.length})
              </Button>
              <Button
                onClick={() => bulkRejectMutation.mutate(selectedIds)}
                variant="destructive"
                disabled={bulkRejectMutation.isPending}
                className="w-full sm:w-auto"
              >
                {bulkRejectMutation.isPending && (
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                )}
                Reject Selected
              </Button>
            </div>
          )}
          
          <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="w-full sm:w-auto">
                  <Filter className="w-4 h-4 mr-2" />
                  Filter: {statusFilter || 'All'}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => setStatusFilter(null)}>
                  All Status
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setStatusFilter('pending')}>
                  Pending
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setStatusFilter('active')}>
                  Active
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setStatusFilter('inactive')}>
                  Inactive
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <Button onClick={handleExport} variant="outline" className="w-full sm:w-auto">
              <Download className="w-4 h-4 mr-2" />
              Export
            </Button>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground w-4 h-4" />
          <Input
            placeholder="Search by name or email..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10"
          />
        </div>
      </div>
      
      <div className="rounded-lg border bg-card">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[40px]">
                  <Checkbox
                    checked={selectedIds.length === pendingApplications.length && pendingApplications.length > 0}
                    onCheckedChange={handleSelectAll}
                  />
                </TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Website</TableHead>
                <TableHead>Preferred Tier</TableHead>
                <TableHead>Applied</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredApplications.map((app) => (
                <TableRow key={app.id} className="hover:bg-muted/50">
                  <TableCell>
                    {app.status === 'pending' && (
                      <Checkbox
                        checked={selectedIds.includes(app.id)}
                        onCheckedChange={(checked) => {
                          setSelectedIds(prev => 
                            checked 
                              ? [...prev, app.id]
                              : prev.filter(id => id !== app.id)
                          );
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell className="font-medium">
                    {app.first_name} {app.last_name}
                  </TableCell>
                  <TableCell>{app.email}</TableCell>
                  <TableCell>
                    {app.website ? (
                      <a 
                        href={app.website} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-primary hover:underline"
                      >
                        {app.website}
                      </a>
                    ) : (
                      <span className="text-muted-foreground">N/A</span>
                    )}
                  </TableCell>
                  <TableCell className="capitalize">{app.preferred_tier}</TableCell>
                  <TableCell>{format(new Date(app.created_at), 'MMM d, yyyy')}</TableCell>
                  <TableCell>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      app.status === 'pending'
                        ? 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200'
                        : app.status === 'active'
                        ? 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200'
                        : app.status === 'inactive'
                        ? 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200'
                        : 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200'
                    }`}>
                      {app.status}
                    </span>
                  </TableCell>
                  <TableCell className="text-right">
                    <div className="flex justify-end gap-2">
                      <Button
                        onClick={() => handleResendWelcomeEmail(app)}
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0"
                      >
                        <span className="sr-only">Resend welcome email</span>
                        <Mail className="h-4 w-4" />
                      </Button>
                      <Button
                        onClick={() => handleDeleteAffiliate(app.id)}
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0 hover:text-destructive"
                      >
                        <span className="sr-only">Delete affiliate</span>
                        <Trash2 className="h-4 w-4" />
                      </Button>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem onClick={() => handleViewDetails(app)}>
                            View Details
                          </DropdownMenuItem>
                          <DropdownMenuItem 
                            onClick={() => handleToggleStatus(app)}
                            disabled={toggleStatusMutation.isPending}
                          >
                            {toggleStatusMutation.isPending && (
                              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                            )}
                            {app.status === 'active' ? 'Suspend' : 'Reactivate'}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      <Dialog open={showDetailsDialog} onOpenChange={setShowDetailsDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Affiliate Details</DialogTitle>
            <DialogDescription>
              View detailed information about this affiliate.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {selectedAffiliate && (
              <>
                <div className="grid grid-cols-4 items-center gap-4">
                  <span className="font-medium text-muted-foreground">First Name:</span>
                  <span className="col-span-3">{selectedAffiliate.first_name}</span>
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <span className="font-medium text-muted-foreground">Last Name:</span>
                  <span className="col-span-3">{selectedAffiliate.last_name}</span>
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <span className="font-medium text-muted-foreground">Email:</span>
                  <span className="col-span-3">{selectedAffiliate.email}</span>
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <span className="font-medium text-muted-foreground">Website:</span>
                  <span className="col-span-3">
                    {selectedAffiliate.website ? (
                      <a 
                        href={selectedAffiliate.website} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-primary hover:underline"
                      >
                        {selectedAffiliate.website}
                      </a>
                    ) : (
                      <span className="text-muted-foreground">N/A</span>
                    )}
                  </span>
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <span className="font-medium text-muted-foreground">Tier:</span>
                  <span className="col-span-3 capitalize">{selectedAffiliate.preferred_tier}</span>
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <span className="font-medium text-muted-foreground">Status:</span>
                  <span className="col-span-3 capitalize">{selectedAffiliate.status}</span>
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <span className="font-medium text-muted-foreground">Applied:</span>
                  <span className="col-span-3">
                    {format(new Date(selectedAffiliate.created_at), 'PPP')}
                  </span>
                </div>
                {(selectedAffiliate.notes || selectedAffiliate.admin_notes) && (
                  <div className="grid grid-cols-4 items-center gap-4">
                    <span className="font-medium text-muted-foreground">Notes:</span>
                    <span className="col-span-3">
                      {selectedAffiliate.notes || selectedAffiliate.admin_notes}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AffiliateManagement;
