import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import AdminLayout from '@admin/layouts/AdminLayout';
import Dashboard from '@admin/pages/Dashboard';
import Login from '@admin/pages/Login';
import Newsletter from '@admin/pages/Newsletter';
import AISettings from '@admin/pages/AISettings';
import Analytics from '@admin/pages/Analytics';
import Blog from '@admin/pages/Blog';
import BlogEditor from '@admin/pages/BlogEditor';
import EmailTemplates from '@admin/pages/EmailTemplates';
import EmailTemplateEditor from '@admin/pages/EmailTemplateEditor';
import AffiliateManagement from './pages/AffiliateManagement';
import PayoutManagement from './pages/PayoutManagement';
import AffiliateAnalytics from './pages/AffiliateAnalytics';
import { useRoles } from '@/hooks/useRoles';
import RoleProtectedRoute from '@/components/auth/RoleProtectedRoute';

export default function AdminRoutes() {
    const { loading } = useRoles();

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route
                element={
                    <RoleProtectedRoute requiredRoles="admin">
                        <AdminLayout>
                            <Outlet />
                        </AdminLayout>
                    </RoleProtectedRoute>
                }
            >
                <Route path="/" element={<Dashboard />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/new" element={<BlogEditor />} />
                <Route path="/blog/edit/:id" element={<BlogEditor />} />
                <Route path="/newsletter" element={<Newsletter />} />
                <Route path="/email-templates" element={<EmailTemplates />} />
                <Route path="/email-templates/new" element={<EmailTemplateEditor />} />
                <Route path="/email-templates/:id" element={<EmailTemplateEditor />} />
                <Route path="/ai-settings" element={<AISettings />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/affiliate/*">
                    <Route path="management" element={<AffiliateManagement />} />
                    <Route path="payouts" element={<PayoutManagement />} />
                    <Route path="analytics" element={<AffiliateAnalytics />} />
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}
