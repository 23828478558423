import { supabase } from '@/lib/supabaseClient';
import { aiConfig } from '@/services/config/ai';

interface GenerateImageOptions {
    prompt: string;
    size?: string;
    style?: string;
    n?: number;
}

export async function generateImage({ prompt, size = '1024x1024', style, n = 1 }: GenerateImageOptions) {
    const provider = aiConfig.imageProvider;
    const model = aiConfig.selectedImageModel[provider];
    const endpoint = aiConfig.imageEndpoints[provider];

    try {
        // Get API key from environment variables
        let apiKey = '';
        if (provider === 'openai') {
            apiKey = import.meta.env.VITE_OPENAI_API_KEY || '';
        } else if (provider === 'stability') {
            apiKey = import.meta.env.VITE_STABILITY_API_KEY || '';
        }

        if (!apiKey) {
            throw new Error(`No API key found for ${provider}`);
        }

        let response;
        if (provider === 'openai') {
            // DALL-E 3 only supports n=1
            const requestN = model === 'dall-e-3' ? 1 : n;
            
            response = await fetch('/api/openai/v1/images/generations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'OpenAI-Organization': import.meta.env.VITE_OPENAI_ORG_ID || ''
                },
                body: JSON.stringify({
                    model,
                    prompt,
                    n: requestN,
                    size,
                    style,
                    response_format: 'b64_json'  // Request base64 instead of URL
                })
            });
        } else if (provider === 'stability') {
            response = await fetch(`${endpoint}/${model}/text-to-image`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify({
                    text_prompts: [{ text: prompt }],
                    samples: n,
                    height: parseInt(size.split('x')[1]),
                    width: parseInt(size.split('x')[0]),
                    style_preset: style
                })
            });
        }

        if (!response?.ok) {
            const error = await response?.text();
            throw new Error(`Failed to generate image: ${error}`);
        }

        const result = await response.json();

        // Parse response based on provider
        let imageData: string[] = [];
        if (provider === 'openai') {
            imageData = result.data.map((item: any) => item.b64_json);
        } else if (provider === 'stability') {
            imageData = result.artifacts.map((item: any) => item.base64);
        }

        return { data: imageData, error: null };
    } catch (error) {
        console.error('Error generating image:', error);
        return { data: null, error: error instanceof Error ? error.message : 'Failed to generate image' };
    }
}

export async function uploadGeneratedImage(imageData: string, path: string) {
    try {
        // Convert base64 to blob directly
        const byteCharacters = atob(imageData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        if (blob.size === 0) {
            console.error('Empty image blob received');
            throw new Error('Empty image blob received');
        }

        console.log('Image blob received:', {
            size: blob.size,
            type: blob.type
        });

        const file = new File([blob], 'generated-image.png', { type: 'image/png' });

        // Upload to Supabase Storage
        const { data, error } = await supabase.storage
            .from('blog-images')
            .upload(path, file);

        if (error) {
            console.error('Supabase upload error:', error);
            throw error;
        }

        // Get public URL
        const { data: { publicUrl } } = supabase.storage
            .from('blog-images')
            .getPublicUrl(data.path);

        console.log('Image uploaded successfully:', publicUrl);
        return { url: publicUrl, error: null };
    } catch (error) {
        console.error('Error uploading generated image:', error);
        return { url: null, error: error instanceof Error ? error.message : 'Failed to upload image' };
    }
}
