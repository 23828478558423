import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabaseClient';
import { useAuth } from './useAuth';

export type UserRole = 'user' | 'affiliate' | 'admin';

export interface RoleHook {
    roles: UserRole[];
    loading: boolean;
    hasRole: (role: UserRole) => boolean;
    hasAnyRole: (roles: UserRole[]) => boolean;
    hasAllRoles: (roles: UserRole[]) => boolean;
}

export const useRoles = (): RoleHook => {
    const { user, loading: authLoading } = useAuth();
    const [roles, setRoles] = useState<UserRole[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const fetchRoles = async () => {
            // Don't fetch roles until auth is done loading
            if (authLoading) {
                return;
            }

            if (!user) {
                if (isMounted) {
                    setRoles([]);
                    setLoading(false);
                }
                return;
            }

            try {
                const { data, error } = await supabase
                    .from('user_roles')
                    .select('role')
                    .eq('user_id', user.id);

                if (error) throw error;

                if (isMounted) {
                    setRoles(data?.map(r => r.role as UserRole) || ['user']);
                }
            } catch (error) {
                console.error('Error fetching user roles:', error);
                if (isMounted) {
                    setRoles(['user']); // Default to user role
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchRoles();

        return () => {
            isMounted = false;
        };
    }, [user, authLoading]);

    const hasRole = (role: UserRole): boolean => roles.includes(role);
    
    const hasAnyRole = (checkRoles: UserRole[]): boolean => 
        checkRoles.some(role => roles.includes(role));

    const hasAllRoles = (checkRoles: UserRole[]): boolean => 
        checkRoles.every(role => roles.includes(role));

    return {
        roles,
        loading: loading || authLoading, // Consider loading if either auth or roles are loading
        hasRole,
        hasAnyRole,
        hasAllRoles,
    };
};
