import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Plus } from 'lucide-react';
import { getTemplates, EmailTemplate } from '@/services/api/emailTemplates';
import { Button } from '@/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';

const EmailTemplates: FC = () => {
    const navigate = useNavigate();
    const [templates, setTemplates] = useState<EmailTemplate[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadTemplates();
    }, []);

    const loadTemplates = async () => {
        try {
            const { data, error } = await getTemplates();
            if (error) throw error;
            setTemplates(data || []);
        } catch (error) {
            console.error('Error loading templates:', error);
            toast.error('Failed to load email templates');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Email Templates</h1>
                <Button
                    onClick={() => navigate('/admin/email-templates/new')}
                    className="flex items-center gap-2"
                >
                    <Plus className="w-4 h-4" />
                    New Template
                </Button>
            </div>

            <div className="bg-card rounded-lg shadow">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Name</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Subject</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Last Updated</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {templates.map((template) => (
                            <TableRow
                                key={template.id}
                                className="cursor-pointer hover:bg-muted/50"
                                onClick={() => navigate(`/admin/email-templates/${template.id}`)}
                            >
                                <TableCell>{template.name}</TableCell>
                                <TableCell>{template.template_type}</TableCell>
                                <TableCell>{template.subject}</TableCell>
                                <TableCell>
                                    <Badge
                                        variant={template.is_active ? "success" : "secondary"}
                                    >
                                        {template.is_active ? 'Active' : 'Inactive'}
                                    </Badge>
                                </TableCell>
                                <TableCell>
                                    {new Date(template.updated_at).toLocaleDateString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default EmailTemplates;
