import { type FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRoles } from '@/hooks/useRoles';
import { useAuth } from '@/hooks/useAuth';

interface RoleProtectedRouteProps {
    children: React.ReactNode;
    requiredRoles: UserRole | UserRole[];
    requireAll?: boolean;
}

const RoleProtectedRoute: FC<RoleProtectedRouteProps> = ({ 
    children, 
    requiredRoles,
    requireAll = false 
}) => {
    const location = useLocation();
    const { loading: rolesLoading, hasAllRoles, hasAnyRole } = useRoles();
    const { user, loading: authLoading } = useAuth();

    // Show loading state while checking auth
    if (authLoading || rolesLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
        );
    }

    // If not logged in, redirect to login
    if (!user) {
        return <Navigate to="/admin/login" state={{ from: location }} replace />;
    }

    const roles = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles];
    const hasAccess = requireAll 
        ? hasAllRoles(roles)
        : hasAnyRole(roles);

    // If logged in but doesn't have required roles, show 403
    if (!hasAccess) {
        return <Navigate to="/403" state={{ from: location }} replace />;
    }

    return <>{children}</>;
};

export default RoleProtectedRoute;
