import { ReactNode, useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
    ChevronLeft, 
    ChevronRight, 
    LogOut, 
    Moon, 
    Sun, 
    Search
} from 'lucide-react';
import { adminNavigation } from '@admin/config/navigation';
import { useTheme } from '@/hooks/useTheme';
import { supabase } from '@/lib/supabaseClient';
import { toast } from 'react-hot-toast';
import { useRoles } from '@/hooks/useRoles';
import { useAuth } from '@/hooks/useAuth';

interface AdminLayoutProps {
    children: ReactNode;
}

export default function AdminLayout({ children }: AdminLayoutProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const { toggleTheme } = useTheme();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { hasRole, loading: rolesLoading } = useRoles();
    const { user, loading: authLoading } = useAuth();

    useEffect(() => {
        // Only redirect if we're done loading
        if (!authLoading && !rolesLoading) {
            if (!user || !hasRole('admin')) {
                navigate('/admin/login', { replace: true });
            }
        }
    }, [user, hasRole, authLoading, rolesLoading, navigate]);

    // Show loading state while checking auth
    if (authLoading || rolesLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
        );
    }

    // Don't render anything if not authenticated or not admin
    if (!user || !hasRole('admin')) {
        return null;
    }

    const handleLogout = async () => {
        try {
            // First navigate to login page
            navigate('/admin/login', { replace: true });
            
            // Then sign out
            const { error } = await supabase.auth.signOut();
            if (error) throw error;
            
            toast.success('Logged out successfully');
        } catch (error) {
            console.error('Logout error:', error);
            toast.error('Failed to log out');
        }
    };

    const filteredNavigation = adminNavigation.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
            {/* Sidebar */}
            <motion.div 
                className="bg-white dark:bg-gray-800 shadow-md flex flex-col relative"
                initial={false}
                animate={{ 
                    width: isCollapsed ? 80 : 256,
                    transition: { duration: 0.3 }
                }}
            >
                {/* Header */}
                <div className={`p-4 flex ${isCollapsed ? 'justify-center' : 'justify-between'} items-center border-b border-gray-200 dark:border-gray-700`}>
                    {!isCollapsed && (
                        <motion.h2 
                            className="text-xl font-semibold text-gray-800 dark:text-white"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            Admin Menu
                        </motion.h2>
                    )}
                    <motion.button
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        className="w-8 h-8 rounded-lg flex items-center justify-center
                            bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 
                            hover:bg-gray-200 dark:hover:bg-gray-600"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        title={isCollapsed ? "Expand menu" : "Collapse menu"}
                    >
                        {isCollapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
                    </motion.button>
                </div>

                {/* Search Bar */}
                <AnimatePresence>
                    {!isCollapsed && (
                        <motion.div 
                            className="px-4 py-2"
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full pl-8 pr-3 py-1 rounded-lg text-sm
                                        bg-gray-100 dark:bg-gray-700 
                                        text-gray-900 dark:text-white 
                                        placeholder-gray-500 dark:placeholder-gray-400
                                        focus:outline-none focus:ring-2 focus:ring-primary-500"
                                />
                                <Search className="absolute left-2 top-2 w-4 h-4 text-gray-500 dark:text-gray-400" />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Navigation */}
                <nav className="flex-1 overflow-y-auto py-4">
                    {filteredNavigation.map((item, index) => (
                        <div key={index}>
                            {item.items ? (
                                <div className="mb-2">
                                    {!isCollapsed && (
                                        <div className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                            {item.name}
                                        </div>
                                    )}
                                    <div className={isCollapsed ? '' : 'pl-4'}>
                                        {item.items.map((subItem, subIndex) => (
                                            <Link
                                                key={subIndex}
                                                to={subItem.href}
                                                className={`flex items-center px-4 py-2 text-sm
                                                    ${location.pathname === subItem.href
                                                        ? 'text-primary-600 dark:text-primary-400 bg-primary-50 dark:bg-primary-900/20'
                                                        : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                                                    }`}
                                            >
                                                {subItem.icon && (
                                                    <subItem.icon
                                                        size={20}
                                                        className={`${isCollapsed ? 'mx-auto' : 'mr-3'}`}
                                                    />
                                                )}
                                                {!isCollapsed && <span>{subItem.name}</span>}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <Link
                                    to={item.href}
                                    className={`flex items-center px-4 py-2 text-sm
                                        ${location.pathname === item.href
                                            ? 'text-primary-600 dark:text-primary-400 bg-primary-50 dark:bg-primary-900/20'
                                            : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                                        }`}
                                >
                                    {item.icon && (
                                        <item.icon
                                            size={20}
                                            className={`${isCollapsed ? 'mx-auto' : 'mr-3'}`}
                                        />
                                    )}
                                    {!isCollapsed && <span>{item.name}</span>}
                                </Link>
                            )}
                        </div>
                    ))}
                </nav>

                {/* Footer */}
                <div className="border-t border-gray-200 dark:border-gray-700 p-4">
                    <div className="flex flex-col space-y-2">
                        <button
                            onClick={toggleTheme}
                            className={`flex items-center ${isCollapsed ? 'justify-center' : ''} px-2 py-1.5 rounded-lg
                                text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700
                                hover:text-gray-900 dark:hover:text-white transition-colors`}
                        >
                            <Sun className="w-5 h-5 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                            <Moon className="absolute w-5 h-5 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                            {!isCollapsed && (
                                <span className="ml-3">Toggle theme</span>
                            )}
                        </button>
                        <button
                            onClick={handleLogout}
                            className={`flex items-center ${isCollapsed ? 'justify-center' : ''} px-2 py-1.5 rounded-lg
                                text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20
                                hover:text-red-700 dark:hover:text-red-300 transition-colors`}
                        >
                            <LogOut className="w-5 h-5" />
                            {!isCollapsed && (
                                <span className="ml-3">Logout</span>
                            )}
                        </button>
                    </div>
                </div>
            </motion.div>

            {/* Main Content */}
            <div className="flex-1 overflow-auto">
                {children}
            </div>
        </div>
    );
}
