import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { RefreshCw, Home } from 'lucide-react';

export default function ServerErrorPage() {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-2xl w-full"
      >
        <div className="text-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2 }}
            className="inline-block"
          >
            <div className="relative">
              <div className="text-9xl font-bold text-gray-700/20">500</div>
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-xl font-medium text-gray-400">Server Error</div>
              </div>
            </div>
          </motion.div>

          <p className="mt-6 text-gray-400 max-w-lg mx-auto">
            Something went wrong on our end. Our team has been notified and is working
            to fix the issue. Please try again in a few moments.
          </p>

          <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
            <button
              onClick={handleRefresh}
              className="inline-flex items-center gap-2 px-6 py-3 rounded-lg bg-primary-600 text-white hover:bg-primary-700 transition-colors"
            >
              <RefreshCw className="w-5 h-5" />
              Try Again
            </button>
            <Link
              to="/"
              className="inline-flex items-center gap-2 px-6 py-3 rounded-lg bg-gray-700/50 text-gray-300 hover:bg-gray-700 transition-colors"
            >
              <Home className="w-5 h-5" />
              Back to Home
            </Link>
          </div>

          <div className="mt-8 p-4 bg-gray-800/50 rounded-lg">
            <p className="text-sm text-gray-400">
              If this error persists, please contact our support team at{' '}
              <a
                href="mailto:support@scholartree.app"
                className="text-primary-400 hover:text-primary-300 transition-colors"
              >
                support@scholartree.app
              </a>
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
