import { supabase } from '@/lib/supabaseClient';

export const uploadFile = async (path: string, file: File) => {
  try {
    // Determine the bucket based on the path
    const bucket = path.startsWith('blog-images/') ? 'blog-images' : 'blog-audio';

    const { data, error } = await supabase.storage
      .from(bucket)
      .upload(path, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (error) {
      throw error;
    }

    // Get the public URL for the uploaded file
    const { data: { publicUrl } } = supabase.storage
      .from(bucket)
      .getPublicUrl(data.path);

    return { url: publicUrl, error: null };
  } catch (error) {
    console.error('Error uploading file:', error);
    return { url: null, error };
  }
};

export const deleteFile = async (path: string) => {
  try {
    const { error: supabaseError } = await supabase.storage
      .from('blog-audio')
      .remove([path]);

    if (supabaseError) {
      throw supabaseError;
    }

    return { error: null };
  } catch (error) {
    console.error('Error deleting file:', error);
    return { error };
  }
};

// Promotional asset management
export const uploadPromotionalAsset = async (
  file: File,
  category: 'banner' | 'logo' | 'social',
  metadata: { dimensions?: string; platform?: string }
) => {
  try {
    const fileExt = file.name.split('.').pop();
    const fileName = `promotional-assets/${category}/${Date.now()}.${fileExt}`;
    
    const { data, error } = await supabase.storage
      .from('affiliate-assets')
      .upload(fileName, file, {
        upsert: false,
        contentType: file.type,
        cacheControl: '3600',
        metadata
      });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error uploading promotional asset:', error);
    throw error;
  }
};

export const getPromotionalAssets = async (category?: string) => {
  try {
    const path = category ? `promotional-assets/${category}` : 'promotional-assets';
    const { data, error } = await supabase.storage
      .from('affiliate-assets')
      .list(path);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching promotional assets:', error);
    throw error;
  }
};
