import { supabase } from '@/lib/supabaseClient';

export interface SMTPSettings {
    id?: string;
    host: string;
    port: number;
    username: string;
    password: string;
    from_email: string;
    from_name: string;
}

interface NewsletterSubscription {
    id: string;
    email: string;
    status: string;
    subscribed_at: string;
}

interface EmailOptions {
    to: string;
    from: string;
    subject: string;
    html: string;
}

export const getSMTPSettings = async () => {
    const result = await supabase
        .from('smtp_settings')
        .select('*')
        .single();
    
    console.log('SMTP Settings Query Result:', {
        data: result.data ? {
            ...result.data,
            password: result.data.password ? '[REDACTED]' : null
        } : null,
        error: result.error
    });
    
    return result;
};

export const updateSMTPSettings = async (settings: SMTPSettings) => {
    return await supabase
        .from('smtp_settings')
        .upsert(settings)
        .single();
};

export const sendNewsletter = async (subject: string, content: string, testEmail?: string | null) => {
    try {
        if (!subject || !content) {
            throw new Error('Subject and content are required');
        }

        // Only require testEmail when sending a test email
        if (testEmail === '') {
            throw new Error('Email address is required for sending test email');
        }

        // First, get the SMTP settings
        const { data: smtpSettings, error: settingsError } = await getSMTPSettings();
        
        if (settingsError) {
            console.error('SMTP Settings Error:', settingsError);
            throw new Error('Failed to get SMTP settings: ' + settingsError.message);
        }

        if (!smtpSettings) {
            console.error('No SMTP settings found');
            throw new Error('No SMTP settings found in database');
        }

        // Get subscribers if not sending a test email
        let subscribers: NewsletterSubscription[] = [];
        if (!testEmail) {
            const { data: subscriberData, error: subscriberError } = await supabase
                .from('newsletter_subscriptions')
                .select('*')
                .eq('status', 'active');

            if (subscriberError) {
                console.error('Error fetching subscribers:', subscriberError);
                throw new Error('Failed to fetch subscribers: ' + subscriberError.message);
            }

            if (!subscriberData || subscriberData.length === 0) {
                throw new Error('No active subscribers found');
            }

            subscribers = subscriberData;
        }

        // Get the current session
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
            throw new Error('No authenticated session found');
        }

        console.log('Got SMTP settings:', {
            host: smtpSettings.host,
            port: smtpSettings.port,
            username: smtpSettings.username,
            from_email: smtpSettings.from_email,
            from_name: smtpSettings.from_name
        });

        const requestBody = { 
            subject, 
            content, 
            email: testEmail,
            smtp: {
                host: smtpSettings.host,
                port: smtpSettings.port,
                username: smtpSettings.username,
                password: smtpSettings.password,
                from_email: smtpSettings.from_email,
                from_name: smtpSettings.from_name
            },
            subscribers: testEmail ? [] : subscribers
        };

        console.log('Sending request with body:', {
            ...requestBody,
            smtp: {
                ...requestBody.smtp,
                password: '[REDACTED]'
            }
        });

        // Then invoke the function with both content and SMTP settings
        const response = await supabase
            .functions.invoke('send-newsletter', {
                body: requestBody
            });

        console.log('Function response:', response);

        if (response.error) {
            console.error('Function Invocation Error:', response.error);
            throw response.error;
        }

        return response;
    } catch (error) {
        console.error('Newsletter Send Error:', error);
        throw error;
    }
};

export const sendEmail = async (options: EmailOptions) => {
    try {
        // Get SMTP settings
        const { data: smtpSettings, error: settingsError } = await getSMTPSettings();
        
        if (settingsError) {
            console.error('SMTP Settings Error:', settingsError);
            throw new Error('Failed to get SMTP settings: ' + settingsError.message);
        }

        if (!smtpSettings) {
            console.error('No SMTP settings found');
            throw new Error('No SMTP settings found in database');
        }

        console.log('Sending email with settings:', {
            ...smtpSettings,
            password: '[REDACTED]'
        });

        // Call our email edge function with SMTP settings and email content
        const { data, error } = await supabase.functions.invoke('send-email', {
            body: {
                smtp: {
                    host: smtpSettings.host,
                    port: smtpSettings.port,
                    username: smtpSettings.username,
                    password: smtpSettings.password,
                },
                email: options
            }
        });

        if (error) {
            console.error('Function Error:', error);
            throw error;
        }

        console.log('Email sent successfully:', data);
        return { error: null, data };
    } catch (error) {
        console.error('Send Email Error:', error);
        return { 
            error: error instanceof Error ? error : new Error('Unknown error occurred'),
            data: null 
        };
    }
};
