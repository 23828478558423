import { supabase } from '@/lib/supabaseClient';
import { getSMTPSettings } from './smtp';
import type {
  AffiliateApplication,
  Affiliate,
  AffiliateMetrics,
  PayoutRequest,
  ReferralConversion
} from '@/types/affiliate';
import { SUPABASE_URL } from '@/config/supabase';

// Helper function to generate a unique referral code
const generateReferralCode = (firstName: string, lastName: string): string => {
  const base = `${firstName}${lastName}`.toLowerCase()
    .replace(/[^a-z0-9]/g, '')
    .slice(0, 10);
  const random = Math.random().toString(36).substring(2, 6);
  return `${base}-${random}`;
};

export const affiliateApi = {
  submitApplication: async (application: Omit<AffiliateApplication, 'id' | 'created_at' | 'updated_at' | 'status'>): Promise<AffiliateApplication> => {
    const { data, error } = await supabase
      .from('affiliate_applications')
      .insert({
        ...application,
        status: 'pending'
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  getApplications: async (): Promise<AffiliateApplication[]> => {
    const { data, error } = await supabase
      .from('affiliate_applications')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  },

  approveApplication: async (applicationId: string): Promise<void> => {
    await updateAffiliateStatus(applicationId, 'active');
  },

  rejectApplication: async (applicationId: string): Promise<void> => {
    await updateAffiliateStatus(applicationId, 'inactive');
  },

  getProfile: async (userId: string): Promise<Affiliate | null> => {
    const { data, error } = await supabase
      .from('affiliates')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) throw error;
    return data;
  },

  getMetrics: async (userId: string): Promise<AffiliateMetrics> => {
    // Get affiliate record
    const { data: affiliate } = await supabase
      .from('affiliates')
      .select('id')
      .eq('user_id', userId)
      .single();

    if (!affiliate) throw new Error('Affiliate not found');

    // Get clicks count
    const { count: clicksCount } = await supabase
      .from('referral_clicks')
      .select('*', { count: 'exact', head: true })
      .eq('affiliate_id', affiliate.id);

    // Get conversions and earnings
    const { data: conversions } = await supabase
      .from('referral_conversions')
      .select('*')
      .eq('affiliate_id', affiliate.id);

    const totalConversions = conversions?.length || 0;
    const conversionRate = clicksCount ? totalConversions / clicksCount : 0;
  
    // Calculate earnings
    const totalEarnings = conversions?.reduce((sum, conv) => sum + (conv.amount || 0), 0) || 0;
    const pendingEarnings = conversions
      ?.filter(conv => conv.status === 'pending')
      .reduce((sum, conv) => sum + (conv.amount || 0), 0) || 0;
    const paidEarnings = conversions
      ?.filter(conv => conv.status === 'paid')
      .reduce((sum, conv) => sum + (conv.amount || 0), 0) || 0;

    return {
      total_clicks: clicksCount || 0,
      total_conversions: totalConversions,
      conversion_rate: conversionRate,
      total_earnings: totalEarnings,
      pending_earnings: pendingEarnings,
      paid_earnings: paidEarnings
    };
  },

  requestPayout: async (request: Omit<PayoutRequest, 'id' | 'status' | 'created_at' | 'processed_at'>): Promise<PayoutRequest> => {
    const { data, error } = await supabase
      .from('payout_requests')
      .insert({
        ...request,
        status: 'pending'
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  getPayoutRequests: async (): Promise<PayoutRequest[]> => {
    const { data, error } = await supabase
      .from('payout_requests')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  },

  approvePayoutRequest: async (requestId: string): Promise<void> => {
    await updatePayoutStatus(requestId, 'approved');
  },

  rejectPayoutRequest: async (requestId: string): Promise<void> => {
    await updatePayoutStatus(requestId, 'rejected');
  },

  flagPayoutForReview: async (requestId: string, fraudSignals: Array<{ type: string; details: string }>): Promise<void> => {
    const { error } = await supabase
      .from('payout_requests')
      .update({ 
        status: 'flagged_for_review',
        fraud_signals: fraudSignals,
        processed_at: new Date().toISOString()
      })
      .eq('id', requestId);

    if (error) throw error;
  },

  getAffiliateDetails: async (userId: string): Promise<Affiliate | null> => {
    const { data, error } = await supabase
      .from('affiliates')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) throw error;
    return data;
  },

  updateApplicationStatus: async (id: string, status: 'active' | 'inactive') => {
    const { data, error } = await supabase
      .from('affiliate_applications')
      .update({ status })
      .eq('id', id)
      .select('*')
      .single();

    if (error) throw error;
    return data;
  },

  async deleteAffiliate(applicationId: string): Promise<void> {
    // First get the affiliate ID from the application
    const { data: affiliate } = await supabase
      .from('affiliates')
      .select('id')
      .eq('application_id', applicationId)
      .single();

    if (affiliate) {
      // Delete affiliate notifications first
      const { error: notificationsError } = await supabase
        .from('affiliate_notifications')
        .delete()
        .eq('affiliate_id', affiliate.id);

      if (notificationsError) {
        console.error('Error deleting notifications:', notificationsError);
        throw notificationsError;
      }

      // Then delete the affiliate
      const { error: deleteAffiliateError } = await supabase
        .from('affiliates')
        .delete()
        .eq('id', affiliate.id);

      if (deleteAffiliateError) {
        console.error('Error deleting affiliate:', deleteAffiliateError);
        throw deleteAffiliateError;
      }
    }

    // Finally delete the application
    const { error: applicationError } = await supabase
      .from('affiliate_applications')
      .delete()
      .eq('id', applicationId);

    if (applicationError) {
      console.error('Error deleting application:', applicationError);
      throw applicationError;
    }
  },

  async resendWelcomeEmail(applicationId: string): Promise<void> {
    // Get the application details
    const { data: application, error } = await supabase
      .from('affiliate_applications')
      .select('*')
      .eq('id', applicationId)
      .single();

    if (error) {
      console.error('Error getting application:', error);
      throw error;
    }

    if (!application) {
      throw new Error('Application not found');
    }

    // Get SMTP settings
    const { data: smtpSettings, error: smtpError } = await getSMTPSettings();

    if (smtpError) {
      console.error('Error getting SMTP settings:', smtpError);
      throw new Error(`Failed to get SMTP settings: ${smtpError.message}`);
    }

    if (!smtpSettings) {
      throw new Error('No SMTP settings found');
    }

    // Send the welcome email using the Edge Function
    const { data, error: functionError } = await supabase.functions.invoke('send-affiliate-welcome', {
      body: {
        to: application.email,
        firstName: application.first_name,
        lastName: application.last_name,
        smtp: {
          host: smtpSettings.host,
          port: smtpSettings.port,
          username: smtpSettings.username,
          password: smtpSettings.password,
          from_email: smtpSettings.from_email,
          from_name: smtpSettings.from_name
        }
      }
    });

    if (functionError) {
      console.error('Error sending welcome email:', functionError);
      throw new Error(`Failed to send welcome email: ${functionError.message}`);
    }

    if (!data?.success) {
      throw new Error('Failed to send welcome email: No success response from Edge Function');
    }
  },

  // ... rest of the code remains the same ...
};

// Private helper functions
async function updateAffiliateStatus(applicationId: string, status: 'pending' | 'active' | 'inactive' | 'suspended'): Promise<void> {
  console.log('Starting updateAffiliateStatus:', { applicationId, status });
  
  // First update the application status
  const { error: updateError } = await supabase
    .from('affiliate_applications')
    .update({ status })
    .eq('id', applicationId);

  if (updateError) throw updateError;
  console.log('Updated application status successfully');

  if (status === 'active') {
    // Get the application details
    const { data: application, error: appError } = await supabase
      .from('affiliate_applications')
      .select('*')
      .eq('id', applicationId)
      .single();

    if (appError) throw appError;
    if (!application) throw new Error('Application not found');
    console.log('Found application:', application);

    // Create affiliate record
    const { error: affiliateError } = await supabase
      .from('affiliates')
      .insert({
        user_id: application.user_id,
        application_id: applicationId,
        status: 'active',
        tier: application.preferred_tier || 'basic',
        referral_code: generateReferralCode(application.first_name, application.last_name),
        total_earnings: 0
      });

    if (affiliateError) throw affiliateError;
    console.log('Created affiliate record');

    // Get the created affiliate record
    const { data: affiliate, error: getAffError } = await supabase
      .from('affiliates')
      .select('id')
      .eq('application_id', applicationId)
      .single();

    if (getAffError) throw getAffError;
    if (!affiliate) throw new Error('Failed to create affiliate record');
    console.log('Got affiliate record:', affiliate);

    // Add affiliate role to user_roles
    const { error: roleError } = await supabase
      .from('user_roles')
      .insert({
        user_id: application.user_id,
        role: 'affiliate'
      })
      .single();

    if (roleError) {
      console.error('Error adding affiliate role:', roleError);
      // Don't throw error if it's just a duplicate role
      if (!roleError.message.includes('duplicate key value')) {
        throw roleError;
      }
    }
    console.log('Added affiliate role to user');

    // Send approval notification
    const { error: notifError } = await supabase
      .from('affiliate_notifications')
      .insert({
        affiliate_id: affiliate.id,
        type: 'application_approved',
        title: 'Application Approved',
        message: 'Your affiliate application has been approved! You can now log in to your dashboard.'
      });

    if (notifError) throw notifError;
    console.log('Created notification record');

    // Send welcome email using Edge Function
    try {
      console.log('Getting SMTP settings...');
      const { data: smtpSettings, error: smtpError } = await getSMTPSettings();
      
      if (smtpError) {
        console.error('Error getting SMTP settings:', smtpError);
        throw new Error(`Failed to get SMTP settings: ${smtpError.message}`);
      }

      if (!smtpSettings) {
        throw new Error('No SMTP settings found');
      }

      console.log('Sending welcome email via Edge Function...');
      const { data, error: functionError } = await supabase.functions.invoke('send-affiliate-welcome', {
        body: {
          to: application.email,
          firstName: application.first_name,
          lastName: application.last_name,
          smtp: {
            host: smtpSettings.host,
            port: smtpSettings.port,
            username: smtpSettings.username,
            password: smtpSettings.password,
            from_email: smtpSettings.from_email,
            from_name: smtpSettings.from_name
          }
        }
      });

      if (functionError) {
        console.error('Error from Edge Function:', functionError);
        throw new Error(`Failed to send welcome email: ${functionError.message}`);
      }

      if (!data?.success) {
        throw new Error('Failed to send welcome email: No success response from Edge Function');
      }

      console.log('Welcome email sent successfully');
    } catch (error) {
      console.error('Failed to send welcome email:', error);
      // Don't throw error here as the approval process should continue even if email fails
    }
  }
}

async function updatePayoutStatus(
  requestId: string,
  status: 'pending' | 'approved' | 'rejected' | 'flagged_for_review'
): Promise<void> {
  const { error } = await supabase
    .from('payout_requests')
    .update({ 
      status,
      processed_at: status !== 'pending' ? new Date().toISOString() : null
    })
    .eq('id', requestId);

  if (error) throw error;
}

export function calculateMetrics(conversions: ReferralConversion[]): AffiliateMetrics {
  const initialMetrics: AffiliateMetrics = {
    total_clicks: 0,
    total_conversions: conversions.length,
    conversion_rate: 0,
    total_earnings: 0,
    pending_earnings: 0,
    paid_earnings: 0
  };

  const metrics = conversions.reduce((sum: AffiliateMetrics, conv: ReferralConversion) => {
    if (conv.status === 'completed') {
      sum.total_earnings += conv.commission_amount;
      sum.paid_earnings += conv.commission_amount;
    }
    if (conv.status === 'pending') {
      sum.pending_earnings += conv.commission_amount;
    }
    return sum;
  }, initialMetrics);

  // Calculate conversion rate if click count is available
  if (metrics.total_clicks > 0) {
    metrics.conversion_rate = (conversions.length / metrics.total_clicks) * 100;
  }

  return metrics;
};
