import { type FC } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Check, X, Sparkles } from 'lucide-react';

const plans = [
  {
    name: 'Individual',
    price: '$2.99',
    description: 'Perfect for individual learners',
    features: [
      'Unlimited AI tutoring sessions',
      'Advanced study materials',
      'Voice & text interactions',
      'Enhanced content filtering',
      'Detailed progress tracking',
      'Offline access to materials',
      'Custom learning paths'
    ],
    notIncluded: [
      'Parent dashboard',
      'Multiple student profiles',
      'Family progress reports'
    ],
    color: 'from-primary-400/80 to-primary-600/80'
  },
  {
    name: 'Parent + Child',
    price: '$3.99',
    description: 'Ideal for one parent and one child',
    features: [
      'Everything in Individual plan',
      'Parent dashboard access',
      'Dual user profiles',
      'Parent-child progress tracking',
      'Content supervision tools',
      'Learning path customization',
      'Priority support',
      'Scheduled study reminders'
    ],
    notIncluded: [
      'Multiple child profiles',
      'Advanced family analytics'
    ],
    color: 'from-accent-400/80 to-accent-600/80',
    popular: true
  },
  {
    name: 'Parent + Children',
    price: '$9.99',
    description: 'Perfect for families with multiple children',
    features: [
      'Everything in Parent + Child plan',
      'Up to 5 child profiles',
      'Advanced family dashboard',
      'Family-wide analytics',
      'Custom content filters per child',
      'Individual learning paths',
      'Family progress reports',
      'Premium support'
    ],
    notIncluded: [],
    color: 'from-green-400/80 to-emerald-600/80'
  }
];

const PricingCard = ({ plan, index }: { plan: typeof plans[0], index: number }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.21, 1.11, 0.81, 0.99],
        delay: index * 0.2
      }
    }
  };

  const checkVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: (i: number) => ({
      scale: 1,
      opacity: 1,
      transition: {
        delay: (index * 0.2) + (i * 0.1),
        type: "spring",
        stiffness: 200,
        damping: 10
      }
    })
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
      className="relative h-full"
    >
      {plan.popular && (
        <div className="absolute -top-5 inset-x-0 flex justify-center z-10">
          <div className="inline-flex items-center gap-1.5 py-2 px-4 rounded-full bg-accent-500 text-white text-sm font-medium shadow-lg">
            <Sparkles className="w-4 h-4" />
            Most Popular
          </div>
        </div>
      )}
      <div className={`relative h-full rounded-2xl bg-gradient-to-br ${plan.color} p-0.5 shadow-xl transition-all duration-300 hover:shadow-2xl hover:scale-[1.02]`}>
        <div className="relative h-full rounded-[15px] backdrop-blur-xl bg-gray-900/90 p-6">
          <div className="flex flex-col h-full">
            <div>
              <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent">{plan.name}</h2>
              <p className="mt-4 text-sm text-white/80">{plan.description}</p>
              <p className="mt-8 flex items-baseline">
                <span className="text-5xl font-bold bg-gradient-to-r from-white via-white/90 to-white/80 bg-clip-text text-transparent">{plan.price}</span>
                {plan.price !== 'Free' && (
                  <span className="ml-2 text-base font-medium text-white/60">/month</span>
                )}
              </p>
              <motion.div className="mt-8">
                <motion.a
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  href="https://testflight.apple.com/join/aGnXvabx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`w-full py-3 px-4 rounded-xl font-semibold text-white
                    bg-gradient-to-r ${plan.color.replace('/80', '')} shadow-lg 
                    hover:shadow-xl transition-all duration-300 flex items-center justify-center gap-2`}
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.71 19.5c-.83 1.24-1.71 2.45-3.05 2.47-1.34.03-1.77-.79-3.29-.79-1.53 0-2 .77-3.27.82-1.31.05-2.3-1.32-3.14-2.53C4.25 17 2.94 12.45 4.7 9.39c.87-1.52 2.43-2.48 4.12-2.51 1.28-.02 2.5.87 3.29.87.78 0 2.26-1.07 3.81-.91.65.03 2.47.26 3.64 1.98-.09.06-2.17 1.28-2.15 3.81.03 3.02 2.65 4.03 2.68 4.04-.03.07-.42 1.44-1.38 2.83M13 3.5c.73-.83 1.94-1.46 2.94-1.5.13 1.17-.34 2.35-1.04 3.19-.69.85-1.83 1.51-2.95 1.42-.15-1.15.41-2.35 1.05-3.11z"/>
                  </svg>
                  Join the prelaunch Beta
                </motion.a>
              </motion.div>
            </div>
            
            <div className="mt-8 flex-grow">
              <h3 className="text-sm font-medium text-white/90 tracking-wide uppercase">Features</h3>
              <ul className="mt-6 space-y-4">
                {plan.features.map((feature, i) => (
                  <motion.li 
                    key={feature}
                    variants={checkVariants}
                    custom={i}
                    className="flex items-start space-x-3"
                  >
                    <div className="flex-shrink-0 w-5">
                      <motion.div
                        initial={{ rotate: -90 }}
                        animate={{ rotate: 0 }}
                        transition={{ delay: (index * 0.2) + (i * 0.1), type: "spring" }}
                      >
                        <Check className="h-5 w-5 text-emerald-400" aria-hidden="true" />
                      </motion.div>
                    </div>
                    <span className="text-sm text-white/80">{feature}</span>
                  </motion.li>
                ))}
                {plan.notIncluded.map((feature, i) => (
                  <motion.li 
                    key={feature}
                    variants={checkVariants}
                    custom={i + plan.features.length}
                    className="flex items-start space-x-3"
                  >
                    <div className="flex-shrink-0 w-5">
                      <motion.div
                        initial={{ rotate: 90 }}
                        animate={{ rotate: 0 }}
                        transition={{ delay: (index * 0.2) + ((i + plan.features.length) * 0.1), type: "spring" }}
                      >
                        <X className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </motion.div>
                    </div>
                    <span className="text-sm text-white/60">{feature}</span>
                  </motion.li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Pricing: FC = () => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], [0, -50]);

  return (
    <div id="pricing" className="relative py-24 overflow-hidden">
      <motion.div
        style={{ y }}
        className="absolute inset-0 bg-gradient-radial from-primary-500/20 via-transparent to-transparent opacity-50"
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: [0.21, 1.11, 0.81, 0.99] }}
          className="text-center"
        >
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-4xl font-bold bg-gradient-to-r from-white via-white/90 to-white/80 bg-clip-text text-transparent"
          >
            Simple, transparent pricing
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-4 text-xl text-white/80"
          >
            Choose the perfect plan for your learning needs
          </motion.p>
        </motion.div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
          {plans.map((plan, index) => (
            <PricingCard key={plan.name} plan={plan} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;