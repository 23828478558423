import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, Clock, Tag, ChevronLeft } from 'lucide-react';
import { getPost } from '@/services/api/blog';
import type { BlogPost } from '@/types/blog';
import BlogLayout from '@/layouts/BlogLayout';
import RichTextContent from '@/components/blog/RichTextContent';
import BlogSEO from '@/components/seo/BlogSEO';
import AudioPlayer from '@/components/blog/AudioPlayer';

const BlogPostPage: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [post, setPost] = useState<BlogPost | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadPost();
    }, [slug]);

    const loadPost = async () => {
        if (!slug) return;
        setLoading(true);
        try {
            const { data, error } = await getPost(slug);
            if (error) throw error;
            setPost(data);
        } catch (error) {
            console.error('Error loading post:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const estimateReadTime = (content: string) => {
        const wordsPerMinute = 200;
        const words = content.trim().split(/\s+/).length;
        const minutes = Math.ceil(words / wordsPerMinute);
        return `${minutes} min read`;
    };

    if (loading) {
        return (
            <BlogLayout>
                <div className="flex justify-center items-center h-96">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
                </div>
            </BlogLayout>
        );
    }

    if (!post) {
        return (
            <BlogLayout>
                <div className="container mx-auto px-4 py-12">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold mb-4">Post Not Found</h1>
                        <p className="text-gray-400 mb-8">The blog post you're looking for doesn't exist.</p>
                        <Link
                            to="/blog"
                            className="inline-flex items-center gap-2 px-6 py-3 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors duration-200"
                        >
                            <ChevronLeft className="w-4 h-4" />
                            Back to Blog
                        </Link>
                    </div>
                </div>
            </BlogLayout>
        );
    }

    return (
        <BlogLayout>
            <BlogSEO post={post} />
            <article className="container mx-auto px-4 py-12">
                <div className="max-w-4xl mx-auto">
                    <Link
                        to="/blog"
                        className="inline-flex items-center gap-2 text-gray-400 hover:text-primary-400 transition-colors duration-200 mb-8"
                    >
                        <ChevronLeft className="w-4 h-4" />
                        Back to Blog
                    </Link>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="mb-8"
                    >
                        {post.featured_image && (
                            <div className="relative w-full max-w-2xl mx-auto mb-12">
                                <div className="relative rounded-lg overflow-hidden bg-gray-800 shadow-lg">
                                    <div className="aspect-[2/1]">
                                        <img
                                            src={post.featured_image}
                                            alt={post.title}
                                            className="w-full h-full object-cover opacity-90"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {post.audio_url && (
                            <AudioPlayer
                                url={post.audio_url}
                                duration={post.audio_duration}
                            />
                        )}

                        <div className="flex flex-wrap gap-2 mb-4">
                            {post.categories?.map(category => (
                                <span
                                    key={category.id}
                                    className="px-3 py-1 bg-primary-500/20 text-primary-300 rounded-full text-sm"
                                >
                                    {category.name}
                                </span>
                            ))}
                        </div>

                        <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                            {post.title}
                        </h1>

                        <div className="flex items-center gap-6 text-sm text-gray-400 mb-8">
                            <span className="flex items-center gap-1">
                                <Calendar className="w-4 h-4" />
                                {formatDate(post.published_at || post.created_at)}
                            </span>
                            <span className="flex items-center gap-1">
                                <Clock className="w-4 h-4" />
                                {estimateReadTime(post.content)}
                            </span>
                            {post.tags && post.tags.length > 0 && (
                                <span className="flex items-center gap-1">
                                    <Tag className="w-4 h-4" />
                                    {post.tags.join(', ')}
                                </span>
                            )}
                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        className="prose prose-lg dark:prose-invert max-w-none"
                    >
                        <RichTextContent content={post.content} />
                    </motion.div>

                    {post.author && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.4 }}
                            className="mt-12 pt-8 border-t border-white/10"
                        >
                            <div className="flex items-center gap-4">
                                {post.author.avatar && (
                                    <img
                                        src={post.author.avatar}
                                        alt={post.author.name}
                                        className="w-12 h-12 rounded-full"
                                    />
                                )}
                                <div>
                                    <h3 className="font-semibold text-white">
                                        {post.author.name}
                                    </h3>
                                    {post.author.bio && (
                                        <p className="text-gray-400 text-sm">
                                            {post.author.bio}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    )}
                </div>
            </article>
        </BlogLayout>
    );
};

export default BlogPostPage;
