import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, Eye, Archive, Send, Undo } from 'lucide-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getPosts, deletePost, updatePostStatus } from '@/services/api/blog';
import type { BlogPost } from '@/types/blog';

const BlogAdmin: React.FC = () => {
    const [posts, setPosts] = useState<BlogPost[]>([]);
    const [loading, setLoading] = useState(true);
    const [totalPosts, setTotalPosts] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState<'all' | 'published' | 'draft' | 'archived'>('all');

    const loadPosts = async () => {
        setLoading(true);
        try {
            const { data, count, error } = await getPosts({
                page: currentPage,
                limit: 10,
                status: filter === 'all' ? undefined : filter
            });

            if (error) throw error;
            setPosts(data || []);
            setTotalPosts(count || 0);
        } catch (error) {
            console.error('Error loading posts:', error);
            toast.error('Failed to load blog posts');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadPosts();
    }, [currentPage, filter]);

    const handleDelete = async (id: string) => {
        if (!window.confirm('Are you sure you want to delete this post? This action cannot be undone.')) {
            return;
        }

        try {
            const { error } = await deletePost(id);
            if (error) throw error;
            toast.success('Post deleted successfully');
            loadPosts();
        } catch (error) {
            console.error('Error deleting post:', error);
            toast.error('Failed to delete post');
        }
    };

    const handleStatusChange = async (id: string, newStatus: 'published' | 'draft' | 'archived') => {
        try {
            await updatePostStatus(id, newStatus);
            toast.success(`Post ${newStatus === 'published' ? 'published' : newStatus === 'archived' ? 'archived' : 'moved to drafts'} successfully`);
            loadPosts();
        } catch (error) {
            console.error('Error updating post status:', error);
            toast.error('Failed to update post status');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Blog Posts</h1>
                <Link
                    to="/admin/blog/new"
                    className="bg-primary-500 hover:bg-primary-600 text-white px-4 py-2 rounded-lg flex items-center gap-2"
                >
                    <Plus className="w-4 h-4" />
                    New Post
                </Link>
            </div>

            <div className="mb-6">
                <div className="flex gap-4">
                    {(['all', 'published', 'draft', 'archived'] as const).map((status) => (
                        <button
                            key={status}
                            onClick={() => setFilter(status)}
                            className={`px-4 py-2 rounded-lg ${
                                filter === status
                                    ? 'bg-primary-500 text-white'
                                    : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'
                            }`}
                        >
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </button>
                    ))}
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
                </div>
            ) : (
                <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
                    <table className="w-full">
                        <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Title</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                            {posts.map((post) => (
                                <tr key={post.id}>
                                    <td className="px-6 py-4">
                                        <div className="text-sm font-medium text-gray-900 dark:text-white">{post.title}</div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                            post.status === 'published' ? 'bg-green-100 text-green-800' :
                                            post.status === 'draft' ? 'bg-yellow-100 text-yellow-800' :
                                            'bg-gray-100 text-gray-800'
                                        }`}>
                                            {post.status.charAt(0).toUpperCase() + post.status.slice(1)}
                                            {post.status === 'published' && post.published_at && 
                                                ` on ${new Date(post.published_at).toLocaleDateString()}`
                                            }
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 text-sm font-medium">
                                        <div className="flex items-center space-x-2">
                                            {/* Status Change Actions */}
                                            <div className="border-r pr-2 mr-2">
                                                {post.status === 'draft' && (
                                                    <button
                                                        onClick={() => handleStatusChange(post.id, 'published')}
                                                        className="p-1.5 text-primary-600 hover:text-primary-900 hover:bg-primary-50 rounded-full transition-colors dark:text-primary-400 dark:hover:text-primary-300 dark:hover:bg-primary-900/20"
                                                        title="Publish"
                                                    >
                                                        <Send className="w-4 h-4" />
                                                    </button>
                                                )}
                                                {post.status === 'published' && (
                                                    <button
                                                        onClick={() => handleStatusChange(post.id, 'archived')}
                                                        className="p-1.5 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-full transition-colors dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-700"
                                                        title="Archive"
                                                    >
                                                        <Archive className="w-4 h-4" />
                                                    </button>
                                                )}
                                                {post.status === 'archived' && (
                                                    <button
                                                        onClick={() => handleStatusChange(post.id, 'draft')}
                                                        className="p-1.5 text-yellow-600 hover:text-yellow-900 hover:bg-yellow-50 rounded-full transition-colors dark:text-yellow-400 dark:hover:text-yellow-300 dark:hover:bg-yellow-900/20"
                                                        title="Restore to Draft"
                                                    >
                                                        <Undo className="w-4 h-4" />
                                                    </button>
                                                )}
                                            </div>

                                            {/* Standard Actions */}
                                            <div className="flex items-center space-x-2">
                                                <Link
                                                    to={`/admin/blog/edit/${post.id}`}
                                                    className="p-1.5 text-indigo-600 hover:text-indigo-900 hover:bg-indigo-50 rounded-full transition-colors dark:text-indigo-400 dark:hover:text-indigo-300 dark:hover:bg-indigo-900/20"
                                                    title="Edit"
                                                >
                                                    <Edit2 className="w-4 h-4" />
                                                </Link>
                                                <button
                                                    onClick={() => handleDelete(post.id)}
                                                    className="p-1.5 text-red-600 hover:text-red-900 hover:bg-red-50 rounded-full transition-colors dark:text-red-400 dark:hover:text-red-300 dark:hover:bg-red-900/20"
                                                    title="Delete"
                                                >
                                                    <Trash2 className="w-4 h-4" />
                                                </button>
                                                <Link
                                                    to={`/blog/${post.slug}`}
                                                    target="_blank"
                                                    className="p-1.5 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-full transition-colors dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-700"
                                                    title="View"
                                                >
                                                    <Eye className="w-4 h-4" />
                                                </Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {posts.length === 0 && (
                        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                            No posts found
                        </div>
                    )}

                    {totalPosts > 10 && (
                        <div className="px-6 py-4 bg-gray-50 dark:bg-gray-700 border-t dark:border-gray-600">
                            <div className="flex justify-between items-center">
                                <button
                                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                                    disabled={currentPage === 1}
                                    className="px-4 py-2 border rounded-lg disabled:opacity-50"
                                >
                                    Previous
                                </button>
                                <span className="text-sm text-gray-700 dark:text-gray-300">
                                    Page {currentPage} of {Math.ceil(totalPosts / 10)}
                                </span>
                                <button
                                    onClick={() => setCurrentPage(p => p + 1)}
                                    disabled={currentPage >= Math.ceil(totalPosts / 10)}
                                    className="px-4 py-2 border rounded-lg disabled:opacity-50"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default BlogAdmin;
