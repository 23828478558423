import { aiConfig } from '@services/config/ai';
import { aiSettingsApi } from '@/services/api/aiSettings';

const SYSTEM_PROMPT = `You are an AI tutor for Scholar Tree, an educational platform. Here's what you need to know:

About Scholar Tree:
- A comprehensive educational platform developed by Elyon Labs, LLC
- Designed to connect students with educational opportunities and resources
- Features both parent and student dashboards

Pricing Plans:
1. Individual Plan - $2.99/month
   - Unlimited AI tutoring sessions
   - Advanced study materials
   - Voice & text interactions
   - Enhanced content filtering
   - Detailed progress tracking
   - Offline access to materials
   - Custom learning paths

2. Parent + Child Plan - $3.99/month
   - Everything in Individual plan
   - Parent dashboard access
   - Dual user profiles
   - Parent-child progress tracking
   - Content supervision tools
   - Learning path customization
   - Priority support
   - Scheduled study reminders

3. Parent + Children Plan - $9.99/month
   - Everything in Parent + Child plan
   - Up to 5 child profiles
   - Advanced family dashboard
   - Family-wide analytics
   - Custom content filters per child
   - Individual learning paths
   - Family progress reports
   - Premium support

All plans include:
- Monthly billing
- Cancel anytime

Key Features:
1. Parent Dashboard (Parent + Child and Parent + Children Plans):
   - Monitor student progress
   - Manage student study materials
   - Customize tutor personality and student learning style
   - Customize notification preferences
   - View student activity history

2. Answer questions about the platform's functionality
3. Provide guidance on educational resources
4. Explain pricing plans and features
5. Explain privacy and data handling practices
6. Direct all support inquiries to support@scholartree.app

Always be helpful, clear, and encouraging. For any technical issues, account-specific questions, or support needs, direct users to contact support@scholartree.app.`;

export interface ChatResponse {
    content: string;
    tokensUsed: number;
}

export const sendMessage = async (message: string): Promise<ChatResponse> => {
    try {
        // Get the latest AI settings
        const settings = await aiSettingsApi.getSettings();
        if (!settings) {
            throw new Error('Failed to load AI settings');
        }

        const apiKey = settings.provider === 'openai'
            ? import.meta.env.VITE_OPENAI_API_KEY 
            : import.meta.env.VITE_DEEPSEEK_API_KEY;

        if (!apiKey) {
            throw new Error(`API key not found for ${settings.provider}`);
        }

        const requestBody = {
            model: settings.selectedModel[settings.provider],
            messages: [
                {
                    role: 'system',
                    content: SYSTEM_PROMPT
                },
                {
                    role: 'user',
                    content: message
                }
            ],
            temperature: 0.7,
            max_tokens: 500
        };

        const getAuthHeader = (provider: string, apiKey: string) => {
            if (provider === 'deepseek') {
                return `Bearer ${apiKey}`;  // Deepseek also uses Bearer prefix
            }
            return `Bearer ${apiKey}`;  // OpenAI requires Bearer prefix
        };

        const response = await fetch(settings.endpoints[settings.provider], {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getAuthHeader(settings.provider, apiKey)
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            
            if (response.status === 429) {
                throw new Error('API quota exceeded. Please check your usage limits or try again later.');
            }
            
            if (response.status === 401) {
                throw new Error('Invalid API key. Please check your API key configuration.');
            }
            
            const errorMessage = errorData.error?.message || `API request failed with status ${response.status}`;
            throw new Error(errorMessage);
        }

        const responseData = await response.json();
        
        if (!responseData.choices || !responseData.choices[0]?.message?.content) {
            console.error('Invalid response format:', responseData);
            throw new Error(`Invalid response format from ${settings.provider}`);
        }

        // Calculate total tokens used (prompt + completion)
        const tokensUsed = responseData.usage?.total_tokens || 0;

        return {
            content: responseData.choices[0].message.content,
            tokensUsed
        };
    } catch (error) {
        console.error('Detailed Error:', error);
        if (error instanceof Error) {
            throw new Error(`API Error: ${error.message}`);
        }
        throw new Error('An unexpected error occurred');
    }
};
