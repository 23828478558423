import { supabase } from '@/lib/supabaseClient';

export interface NewsletterSubscription {
    id: string;
    email: string;
    subscribed_at: string;
    status: 'active' | 'unsubscribed';
}

export async function subscribeToNewsletter(email: string): Promise<{ data: NewsletterSubscription | null; error: any }> {
    try {
        // First, check if the email already exists
        const { data: existingSubscription } = await supabase
            .from('newsletter_subscriptions')
            .select('*')
            .eq('email', email)
            .maybeSingle();

        // If subscription exists and is active
        if (existingSubscription?.status === 'active') {
            return {
                data: existingSubscription,
                error: {
                    message: 'You are already subscribed to our newsletter!',
                    type: 'info'
                }
            };
        }

        // If subscription exists but is unsubscribed
        if (existingSubscription?.status === 'unsubscribed') {
            const { data: reactivated, error: updateError } = await supabase
                .from('newsletter_subscriptions')
                .update({ status: 'active' })
                .eq('id', existingSubscription.id)
                .select()
                .single();

            if (updateError) {
                return { data: null, error: updateError };
            }

            return {
                data: reactivated,
                error: {
                    message: 'Welcome back! Your subscription has been reactivated.',
                    type: 'success'
                }
            };
        }

        // If no subscription exists, create a new one
        const { data: newSubscription, error: insertError } = await supabase
            .from('newsletter_subscriptions')
            .insert([{ email, status: 'active' }])
            .select()
            .single();

        if (insertError) {
            return {
                data: null,
                error: {
                    message: 'Failed to subscribe. Please try again later.',
                    type: 'error'
                }
            };
        }

        return {
            data: newSubscription,
            error: {
                message: 'Successfully subscribed to our newsletter!',
                type: 'success'
            }
        };
    } catch (error) {
        return {
            data: null,
            error: {
                message: 'An unexpected error occurred. Please try again later.',
                type: 'error'
            }
        };
    }
}

export async function unsubscribeFromNewsletter(id: string): Promise<{ error: any }> {
    const { error } = await supabase
        .from('newsletter_subscriptions')
        .update({ status: 'unsubscribed' })
        .eq('id', id);

    return { error };
}

export async function getAllSubscriptions(): Promise<{ data: NewsletterSubscription[] | null; error: any }> {
    try {
        const { data, error } = await supabase
            .from('newsletter_subscriptions')
            .select('*')
            .order('subscribed_at', { ascending: false });

        if (error) {
            return { data: null, error };
        }

        return { data, error: null };
    } catch (error) {
        return {
            data: null,
            error: {
                message: 'Failed to fetch subscriptions',
                type: 'error'
            }
        };
    }
}

export async function deleteSubscription(id: string): Promise<{ error: any }> {
    const { error } = await supabase
        .from('newsletter_subscriptions')
        .delete()
        .eq('id', id);

    return { error };
}
