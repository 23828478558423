import { type FC } from 'react';
import useAuth from '@/hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import PerformanceDashboard from '@/components/affiliate/PerformanceDashboard';
import { affiliateApi } from '@/services/api/affiliate';

const AffiliateDashboard: FC = () => {
    const { user } = useAuth();
    const { data: affiliate } = useQuery({
        queryKey: ['affiliate', user?.id],
        queryFn: () => affiliateApi.getAffiliateDetails(user!.id),
        enabled: !!user
    });

    if (!affiliate) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"
        >
            <div className="px-4 sm:px-0">
                <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
                    Affiliate Dashboard
                </h1>
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    Track your performance and manage your affiliate account
                </p>
            </div>

            <div className="mt-8">
                <PerformanceDashboard
                    affiliateId={affiliate.id}
                    tierId={affiliate.tier}
                />
            </div>
        </motion.div>
    );
};

export default AffiliateDashboard;
