import { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const LoadingBar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleStart = () => {
      setIsLoading(true);
      controls.start({
        width: '90%',
        transition: { duration: 1, ease: 'easeInOut' },
      });
    };

    const handleComplete = () => {
      controls.start({
        width: '100%',
        transition: { duration: 0.2, ease: 'easeInOut' },
      }).then(() => {
        setTimeout(() => {
          setIsLoading(false);
          controls.set({ width: '0%' });
        }, 200);
      });
    };

    // Listen for page transitions or loading states
    window.addEventListener('load', handleComplete);
    document.addEventListener('routeChangeStart', handleStart);
    document.addEventListener('routeChangeComplete', handleComplete);
    document.addEventListener('routeChangeError', handleComplete);

    return () => {
      window.removeEventListener('load', handleComplete);
      document.removeEventListener('routeChangeStart', handleStart);
      document.removeEventListener('routeChangeComplete', handleComplete);
      document.removeEventListener('routeChangeError', handleComplete);
    };
  }, [controls]);

  if (!isLoading) return null;

  return (
    <div className="fixed top-0 left-0 right-0 z-50">
      <motion.div
        initial={{ width: '0%' }}
        animate={controls}
        className="h-1 bg-gradient-to-r from-primary-500 to-primary-600"
      />
    </div>
  );
};

export default LoadingBar;
