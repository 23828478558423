import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Lock, LogIn, Home } from 'lucide-react';

export default function ForbiddenPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-2xl w-full"
      >
        <div className="text-center">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2 }}
            className="inline-block"
          >
            <div className="relative">
              <div className="text-9xl font-bold text-gray-700/20">403</div>
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="text-xl font-medium text-gray-400">Access Denied</div>
              </div>
            </div>
          </motion.div>

          <div className="mt-6 inline-block p-4 rounded-full bg-red-500/10">
            <Lock className="w-12 h-12 text-red-400" />
          </div>

          <p className="mt-6 text-gray-400 max-w-lg mx-auto">
            Sorry, but you don't have permission to access this page. 
            Please sign in with an authorized account or contact your administrator.
          </p>

          <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link
              to="/login"
              className="inline-flex items-center gap-2 px-6 py-3 rounded-lg bg-primary-600 text-white hover:bg-primary-700 transition-colors"
            >
              <LogIn className="w-5 h-5" />
              Sign In
            </Link>
            <Link
              to="/"
              className="inline-flex items-center gap-2 px-6 py-3 rounded-lg bg-gray-700/50 text-gray-300 hover:bg-gray-700 transition-colors"
            >
              <Home className="w-5 h-5" />
              Back to Home
            </Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
