import { type FC } from 'react';
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { AuthProvider } from '@/components/auth/AuthProvider';
import RoleProtectedRoute from '@/components/auth/RoleProtectedRoute';
import Navbar from '@/components/layout/Navbar';
import Hero from '@/components/home/Hero';
import Features from '@/components/home/Features';
import Pricing from '@/components/home/Pricing';
import Footer from '@/components/layout/Footer';
import AIChatButton from '@/components/chat/AIChatButton';
import LoadingBar from '@/components/common/LoadingBar';
import UmamiAnalytics from '@/components/analytics/UmamiAnalytics';
import AdminRoutes from '@/admin/routes';
import ScrollToTop from '@/components/common/ScrollToTop';
import AffiliateLayout from '@/layouts/AffiliateLayout';
import Blog from '@/pages/Blog';
import BlogPost from '@/pages/BlogPost';
import Affiliate from '@/pages/Affiliate';
import AffiliateLogin from '@/pages/affiliate/Login';
import AffiliateSignup from '@/pages/affiliate/Signup';
import AffiliateApplication from '@/pages/AffiliateApplication';
import AffiliateThankYou from '@/pages/AffiliateThankYou';
import AffiliateDashboard from '@/pages/AffiliateDashboard';
import ResetPassword from '@/pages/affiliate/ResetPassword';
import UpdatePassword from '@/pages/affiliate/UpdatePassword';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import NotFoundPage from '@/pages/error/404';
import ServerErrorPage from '@/pages/error/500';
import ForbiddenPage from '@/pages/error/403';
import AdminLogin from '@/admin/pages/Login';

// Create the main layout component
const MainLayout: FC = () => (
  <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
    <LoadingBar />
    <AnimatePresence mode="wait">
      <main>
        <Navbar />
        <Hero />
        <Features />
        <Pricing />
        <Footer />
      </main>
    </AnimatePresence>
    <AIChatButton />
  </div>
);

// Create router configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <Outlet />
      </>
    ),
    errorElement: <ErrorBoundary><ServerErrorPage /></ErrorBoundary>,
    children: [
      {
        path: "/",
        element: <MainLayout />
      },
      {
        path: "/blog",
        element: <Blog />
      },
      {
        path: "/blog/:slug",
        element: <BlogPost />
      },
      {
        path: "/affiliate",
        element: <AffiliateLayout><Affiliate /></AffiliateLayout>
      },
      {
        path: "/affiliate/login",
        element: <AffiliateLayout><AffiliateLogin /></AffiliateLayout>
      },
      {
        path: "/affiliate/signup",
        element: <AffiliateLayout><AffiliateSignup /></AffiliateLayout>
      },
      {
        path: "/affiliate/reset-password",
        element: <AffiliateLayout><ResetPassword /></AffiliateLayout>
      },
      {
        path: "/affiliate/update-password",
        element: <AffiliateLayout><UpdatePassword /></AffiliateLayout>
      },
      {
        path: "/affiliate/apply",
        element: <AffiliateLayout><AffiliateApplication /></AffiliateLayout>
      },
      {
        path: "/affiliate/thank-you",
        element: <AffiliateLayout><AffiliateThankYou /></AffiliateLayout>
      },
      {
        path: "/affiliate/dashboard",
        element: (
          <RoleProtectedRoute requiredRoles="affiliate">
            <AffiliateLayout>
              <AffiliateDashboard />
            </AffiliateLayout>
          </RoleProtectedRoute>
        )
      },
      {
        path: "/admin/login",
        element: <AdminLogin />
      },
      {
        path: "/admin/*",
        element: (
          <RoleProtectedRoute requiredRoles="admin">
            <AdminRoutes />
          </RoleProtectedRoute>
        )
      },
      {
        path: "/404",
        element: <NotFoundPage />
      },
      {
        path: "/500",
        element: <ServerErrorPage />
      },
      {
        path: "/403",
        element: <ForbiddenPage />
      },
      {
        path: "*",
        element: <Navigate to="/404" replace />
      }
    ]
  }
]);

const App: FC = () => {
  const queryClient = new QueryClient();

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router} />
          <Toaster position="top-right" />
          <UmamiAnalytics />
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;