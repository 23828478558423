import { useEffect, useState } from 'react';
import { Card } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabaseClient';
import { formatCurrency } from '@/lib/utils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Download, TrendingUp, TrendingDown } from 'lucide-react';
import { format, subDays, parseISO } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
} from 'recharts';

interface AffiliateWithTier {
  id: string;
  application_id: string;
  tier_id: string;
  affiliate_application: {
    first_name: string;
    last_name: string;
  };
  affiliate_tier: {
    id: string;
    name: string;
    commission_rate: number;
  };
}

interface Conversion {
  id: string;
  affiliate_id: string;
  revenue: number;
  created_at: string;
  affiliates: AffiliateWithTier;
}

interface TopAffiliate {
  id: string;
  name: string;
  revenue: number;
  conversions: number;
  conversionRate: number;
  trend: 'up' | 'down' | 'stable';
}

interface AnalyticsData {
  totalRevenue: number;
  totalConversions: number;
  totalClicks: number;
  averageConversionRate: number;
  revenueByTier: Record<string, number>;
  topAffiliates: TopAffiliate[];
  dailyStats: Array<{
    date: string;
    revenue: number;
    conversions: number;
    clicks: number;
  }>;
}

export default function AffiliateAnalytics() {
  const { toast } = useToast();
  const [dateRange, setDateRange] = useState<'7d' | '30d' | '90d' | 'custom'>('30d');
  const [startDate, setStartDate] = useState(format(subDays(new Date(), 30), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedMetric, setSelectedMetric] = useState<'revenue' | 'conversions' | 'clicks'>('revenue');

  const fetchAnalytics = async () => {
    try {
      setLoading(true);

      // Calculate date range
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0);

      // Previous period for trend analysis
      const periodLength = end.getTime() - start.getTime();
      const prevStart = new Date(start.getTime() - periodLength);

      // Get all conversions in date range with affiliate and tier info
      const { data: currentConversions } = await supabase
        .from('referral_conversions')
        .select(`
          id,
          affiliate_id,
          revenue,
          created_at,
          affiliates (
            id,
            application_id,
            tier_id,
            affiliate_applications (
              first_name,
              last_name
            ),
            affiliate_tiers (
              id,
              name,
              commission_rate
            )
          )
        `)
        .gte('created_at', start.toISOString())
        .lte('created_at', end.toISOString());

      const conversions = (currentConversions || []).map(conv => ({
        id: conv.id,
        affiliate_id: conv.affiliate_id,
        revenue: conv.revenue,
        created_at: conv.created_at,
        affiliates: {
          id: conv.affiliates?.id,
          application_id: conv.affiliates?.application_id,
          tier_id: conv.affiliates?.tier_id,
          affiliate_application: {
            first_name: conv.affiliates?.affiliate_applications?.first_name,
            last_name: conv.affiliates?.affiliate_applications?.last_name
          },
          affiliate_tier: {
            id: conv.affiliates?.affiliate_tiers?.id,
            name: conv.affiliates?.affiliate_tiers?.name,
            commission_rate: conv.affiliates?.affiliate_tiers?.commission_rate
          }
        }
      })) satisfies Conversion[];

      // Get previous period conversions for trend analysis
      const { data: previousConversions } = await supabase
        .from('referral_conversions')
        .select('affiliate_id, revenue')
        .gte('created_at', prevStart.toISOString())
        .lt('created_at', start.toISOString());

      const prevPeriodData = previousConversions || [];

      // Get clicks in date range
      const { data: clicks } = await supabase
        .from('referral_clicks')
        .select('*')
        .gte('created_at', start.toISOString())
        .lte('created_at', end.toISOString());

      // Calculate metrics
      const totalRevenue = conversions.reduce((sum, conv) => sum + (conv.revenue || 0), 0);
      const totalConversions = conversions.length;
      const totalClicks = clicks?.length || 0;
      const averageConversionRate = totalClicks ? (totalConversions / totalClicks) * 100 : 0;

      // Calculate revenue by tier
      const revenueByTier = conversions.reduce((acc, conv) => {
        const tierName = conv.affiliates?.affiliate_tier?.name || 'Unknown';
        acc[tierName] = (acc[tierName] || 0) + (Number(conv.revenue) || 0);
        return acc;
      }, {} as Record<string, number>);

      // Calculate top affiliates with trends
      const affiliateStats = conversions.reduce((acc, conv) => {
        const affId = conv.affiliate_id;
        if (!acc[affId]) {
          acc[affId] = {
            id: affId,
            name: conv.affiliates?.affiliate_application?.first_name && conv.affiliates?.affiliate_application?.last_name
              ? `${conv.affiliates.affiliate_application.first_name} ${conv.affiliates.affiliate_application.last_name}`
              : 'Unknown',
            revenue: 0,
            conversions: 0,
            clicks: 0,
            prevRevenue: 0
          };
        }
        acc[affId].revenue += Number(conv.revenue) || 0;
        acc[affId].conversions += 1;
        return acc;
      }, {} as Record<string, {
        id: string;
        name: string;
        revenue: number;
        conversions: number;
        clicks: number;
        prevRevenue: number;
      }>);

      // Add previous period revenue for trend calculation
      prevPeriodData.forEach(conv => {
        const affId = conv.affiliate_id;
        if (affiliateStats[affId]) {
          affiliateStats[affId].prevRevenue += Number(conv.revenue) || 0;
        }
      });

      // Add click counts and calculate trends
      clicks?.forEach(click => {
        if (affiliateStats[click.affiliate_id]) {
          affiliateStats[click.affiliate_id].clicks += 1;
        }
      });

      const topAffiliates: TopAffiliate[] = Object.values(affiliateStats)
        .map(aff => ({
          id: aff.id,
          name: aff.name,
          revenue: aff.revenue,
          conversions: aff.conversions,
          conversionRate: aff.clicks ? (aff.conversions / aff.clicks) * 100 : 0,
          trend: aff.revenue > aff.prevRevenue ? 'up' as const : 
                 aff.revenue < aff.prevRevenue ? 'down' as const : 
                 'stable' as const
        }))
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 10);

      // Calculate daily stats
      const dailyStats = conversions.reduce((acc, conv) => {
        const date = format(parseISO(conv.created_at), 'yyyy-MM-dd');
        if (!acc[date]) {
          acc[date] = {
            date,
            revenue: 0,
            conversions: 0,
            clicks: 0
          };
        }
        acc[date].revenue += Number(conv.revenue) || 0;
        acc[date].conversions += 1;
        return acc;
      }, {} as Record<string, any>);

      // Add click data to daily stats
      clicks?.forEach(click => {
        const date = format(parseISO(click.created_at), 'yyyy-MM-dd');
        if (dailyStats[date]) {
          dailyStats[date].clicks += 1;
        }
      });

      setAnalytics({
        totalRevenue,
        totalConversions,
        totalClicks,
        averageConversionRate,
        revenueByTier,
        topAffiliates,
        dailyStats: Object.values(dailyStats).sort((a, b) => 
          new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load analytics data',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange !== 'custom') {
      const end = new Date();
      const start = new Date();
      start.setDate(end.getDate() - (dateRange === '7d' ? 7 : dateRange === '30d' ? 30 : 90));
      setStartDate(format(start, 'yyyy-MM-dd'));
      setEndDate(format(end, 'yyyy-MM-dd'));
    }
  }, [dateRange]);

  useEffect(() => {
    fetchAnalytics();
  }, [startDate, endDate, toast]);

  const handleExport = () => {
    if (!analytics) return;

    // Prepare CSV data
    const csvData = [
      // Summary
      ['Summary'],
      ['Metric', 'Value'],
      ['Total Revenue', analytics.totalRevenue],
      ['Total Conversions', analytics.totalConversions],
      ['Total Clicks', analytics.totalClicks],
      ['Average Conversion Rate', `${analytics.averageConversionRate.toFixed(2)}%`],
      [''],
      
      // Revenue by Tier
      ['Revenue by Tier'],
      ['Tier', 'Revenue'],
      ...Object.entries(analytics.revenueByTier).map(([tier, revenue]) => [tier, revenue]),
      [''],
      
      // Top Affiliates
      ['Top Affiliates'],
      ['Name', 'Revenue', 'Conversions', 'Conversion Rate', 'Trend'],
      ...analytics.topAffiliates.map(aff => [
        aff.name,
        aff.revenue,
        aff.conversions,
        `${aff.conversionRate.toFixed(2)}%`,
        aff.trend
      ]),
      [''],
      
      // Daily Stats
      ['Daily Statistics'],
      ['Date', 'Revenue', 'Conversions', 'Clicks'],
      ...analytics.dailyStats.map(stat => [
        stat.date,
        stat.revenue,
        stat.conversions,
        stat.clicks
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `affiliate-analytics-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleStartDateChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEndDate(e.target.value);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center gap-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          <p className="text-muted-foreground">Loading analytics data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8 px-4 space-y-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold">Affiliate Analytics</h1>
          <p className="text-muted-foreground mt-1">Track and analyze affiliate performance</p>
        </div>
        
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
            <Select value={dateRange} onValueChange={(value: any) => setDateRange(value)}>
              <SelectTrigger className="w-full sm:w-[180px]">
                <SelectValue placeholder="Select timeframe" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="7d">Last 7 days</SelectItem>
                <SelectItem value="30d">Last 30 days</SelectItem>
                <SelectItem value="90d">Last 90 days</SelectItem>
                <SelectItem value="custom">Custom Range</SelectItem>
              </SelectContent>
            </Select>

            {dateRange === 'custom' && (
              <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
                <div className="w-full sm:w-auto">
                  <Label htmlFor="startDate" className="text-sm">Start Date</Label>
                  <Input
                    id="startDate"
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="mt-1"
                  />
                </div>
                <div className="w-full sm:w-auto">
                  <Label htmlFor="endDate" className="text-sm">End Date</Label>
                  <Input
                    id="endDate"
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="mt-1"
                  />
                </div>
              </div>
            )}
          </div>

          <Button 
            variant="outline" 
            onClick={handleExport}
            className="w-full sm:w-auto"
          >
            <Download className="w-4 h-4 mr-2" />
            Export Report
          </Button>
        </div>
      </div>

      {analytics && (
        <div className="space-y-8">
          {/* Summary Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <Card className="p-6 hover:shadow-lg transition-shadow">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-muted-foreground">Total Revenue</span>
                <span className="text-3xl font-bold mt-2">{formatCurrency(analytics.totalRevenue)}</span>
                <span className="text-sm text-muted-foreground mt-1">
                  {dateRange === '7d' ? 'Past 7 days' : dateRange === '30d' ? 'Past 30 days' : dateRange === '90d' ? 'Past 90 days' : 'Custom range'}
                </span>
              </div>
            </Card>
            <Card className="p-6 hover:shadow-lg transition-shadow">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-muted-foreground">Total Conversions</span>
                <span className="text-3xl font-bold mt-2">{analytics.totalConversions.toLocaleString()}</span>
                <span className="text-sm text-muted-foreground mt-1">
                  {analytics.totalConversions > 0 ? `${formatCurrency(analytics.totalRevenue / analytics.totalConversions)} avg. value` : 'No conversions'}
                </span>
              </div>
            </Card>
            <Card className="p-6 hover:shadow-lg transition-shadow">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-muted-foreground">Total Clicks</span>
                <span className="text-3xl font-bold mt-2">{analytics.totalClicks.toLocaleString()}</span>
                <span className="text-sm text-muted-foreground mt-1">
                  {analytics.totalClicks > 0 ? `${(analytics.totalConversions / analytics.totalClicks * 100).toFixed(1)}% conversion rate` : 'No clicks'}
                </span>
              </div>
            </Card>
            <Card className="p-6 hover:shadow-lg transition-shadow">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-muted-foreground">Conversion Rate</span>
                <span className="text-3xl font-bold mt-2">{analytics.averageConversionRate.toFixed(1)}%</span>
                <span className="text-sm text-muted-foreground mt-1">
                  {analytics.totalClicks} total clicks
                </span>
              </div>
            </Card>
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <Card className="p-6">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
                <h3 className="text-lg font-semibold">Performance Over Time</h3>
                <Select
                  value={selectedMetric}
                  onValueChange={(value: any) => setSelectedMetric(value)}
                  className="mt-2 sm:mt-0"
                >
                  <SelectTrigger className="w-[140px]">
                    <SelectValue placeholder="Select metric" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="revenue">Revenue</SelectItem>
                    <SelectItem value="conversions">Conversions</SelectItem>
                    <SelectItem value="clicks">Clicks</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={analytics.dailyStats}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(date) => format(parseISO(date), 'MMM d')}
                      stroke="#6B7280"
                    />
                    <YAxis stroke="#6B7280" />
                    <Tooltip
                      labelFormatter={(date) => format(parseISO(date as string), 'MMM d, yyyy')}
                      contentStyle={{
                        backgroundColor: '#1F2937',
                        border: 'none',
                        borderRadius: '0.375rem',
                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                      }}
                      itemStyle={{ color: '#E5E7EB' }}
                      labelStyle={{ color: '#E5E7EB' }}
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey={selectedMetric}
                      stroke="#3B82F6"
                      strokeWidth={2}
                      dot={false}
                      activeDot={{ r: 6 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Card>

            <Card className="p-6">
              <h3 className="text-lg font-semibold mb-6">Revenue by Tier</h3>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={Object.entries(analytics.revenueByTier).map(([name, value]) => ({
                    name,
                    value
                  }))}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                    <XAxis dataKey="name" stroke="#6B7280" />
                    <YAxis stroke="#6B7280" />
                    <Tooltip
                      formatter={(value: any) => formatCurrency(value)}
                      contentStyle={{
                        backgroundColor: '#1F2937',
                        border: 'none',
                        borderRadius: '0.375rem',
                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                      }}
                      itemStyle={{ color: '#E5E7EB' }}
                      labelStyle={{ color: '#E5E7EB' }}
                    />
                    <Bar 
                      dataKey="value" 
                      fill="#3B82F6"
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </div>

          {/* Top Affiliates Table */}
          <Card>
            <div className="p-6">
              <h3 className="text-lg font-semibold mb-6">Top Performing Affiliates</h3>
              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Affiliate</TableHead>
                      <TableHead>Revenue</TableHead>
                      <TableHead>Conversions</TableHead>
                      <TableHead>Conversion Rate</TableHead>
                      <TableHead>Trend</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {analytics.topAffiliates.map((affiliate: TopAffiliate) => (
                      <TableRow key={affiliate.id} className="hover:bg-muted/50">
                        <TableCell className="font-medium">{affiliate.name}</TableCell>
                        <TableCell>{formatCurrency(affiliate.revenue)}</TableCell>
                        <TableCell>{affiliate.conversions.toLocaleString()}</TableCell>
                        <TableCell>{affiliate.conversionRate.toFixed(1)}%</TableCell>
                        <TableCell>
                          {affiliate.trend === 'up' ? (
                            <div className="flex items-center gap-1 text-green-500">
                              <TrendingUp className="w-4 h-4" />
                              <span className="text-xs">Increasing</span>
                            </div>
                          ) : affiliate.trend === 'down' ? (
                            <div className="flex items-center gap-1 text-red-500">
                              <TrendingDown className="w-4 h-4" />
                              <span className="text-xs">Decreasing</span>
                            </div>
                          ) : (
                            <span className="text-muted-foreground">Stable</span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}
