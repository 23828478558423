import type { ChatAnalytics, AnalyticsSummary, QueryGroup } from '@/types/analytics';
import { aiConfig } from '@services/config/ai';
import { sendMessage } from '@services/core/api';
import { supabase } from '@/lib/supabaseClient';

// Constants for data management
const ANALYTICS_STORAGE_KEY = 'chat_analytics';
const MAX_STORED_MESSAGES = 5000; // Limit total stored messages
const RETENTION_DAYS = 30; // Keep only last 30 days of data

interface StoredAnalyticsItem extends Omit<ChatAnalytics, 'timestamp'> {
    timestamp: string;
}

// Force clear analytics data
export const resetAnalytics = () => {
    try {
        console.log('Clearing analytics data...');
        localStorage.removeItem(ANALYTICS_STORAGE_KEY);
        console.log('Analytics data cleared');
    } catch (error) {
        console.error('Error resetting analytics:', error);
    }
};

const getStoredAnalytics = (): ChatAnalytics[] => {
    const stored = localStorage.getItem(ANALYTICS_STORAGE_KEY);
    if (!stored) {
        console.log('No stored analytics found');
        return [];
    }
    try {
        const data = JSON.parse(stored) as StoredAnalyticsItem[];
        console.log('Parsed stored analytics:', data);
        
        // Convert stored ISO strings back to Date objects
        const parsedData = data.map((item: StoredAnalyticsItem) => ({
            ...item,
            timestamp: new Date(item.timestamp)
        }));

        // Filter out old data
        const cutoffDate = new Date();
        cutoffDate.setDate(cutoffDate.getDate() - RETENTION_DAYS);
        const filteredData = parsedData.filter(item => item.timestamp >= cutoffDate);
        console.log('Filtered analytics data:', filteredData);
        return filteredData;
    } catch (error) {
        console.error('Error parsing stored analytics:', error);
        return [];
    }
};

const saveAnalytics = (data: ChatAnalytics[]) => {
    try {
        // Convert Date objects to ISO strings for storage
        const storageData = data.map(item => ({
            ...item,
            timestamp: item.timestamp.toISOString()
        }));

        // Keep only the most recent messages if we exceed the limit
        const trimmedData = storageData.slice(-MAX_STORED_MESSAGES);
        
        // Calculate approximate size
        const dataSize = JSON.stringify(trimmedData).length;
        const sizeInMB = dataSize / (1024 * 1024);
        
        if (sizeInMB > 4.5) { // Leave some buffer below 5MB limit
            console.warn('Analytics storage nearing capacity. Consider implementing database storage.');
            // Keep only half of the messages to free up space
            const reducedData = trimmedData.slice(-Math.floor(MAX_STORED_MESSAGES / 2));
            localStorage.setItem(ANALYTICS_STORAGE_KEY, JSON.stringify(reducedData));
            console.log('Saved reduced analytics data:', reducedData);
        } else {
            localStorage.setItem(ANALYTICS_STORAGE_KEY, JSON.stringify(trimmedData));
            console.log('Saved analytics data:', trimmedData);
        }
    } catch (error: unknown) {
        if (error instanceof Error && error.name === 'QuotaExceededError') {
            console.error('Storage quota exceeded. Implementing emergency cleanup...');
            // Emergency cleanup - keep only last 1000 messages
            const emergencyData = data.slice(-1000).map(item => ({
                ...item,
                timestamp: item.timestamp.toISOString()
            }));
            try {
                localStorage.setItem(ANALYTICS_STORAGE_KEY, JSON.stringify(emergencyData));
                console.log('Saved emergency analytics data:', emergencyData);
            } catch (e) {
                console.error('Emergency cleanup failed:', e);
            }
        }
        console.error('Error saving analytics:', error);
    }
};

// Record chat analytics in Supabase
export const recordChatAnalytics = async (data: Omit<ChatAnalytics, 'id'>) => {
    console.log('Recording chat analytics with data:', {
        question: data.question,
        modelUsed: data.modelUsed,
        providerUsed: aiConfig.provider,
        timestamp: data.timestamp
    });

    try {
        const { error } = await supabase
            .from('chat_analytics')
            .insert({
                question: data.question,
                answer: data.answer,
                session_id: data.sessionId,
                response_time: data.responseTime,
                provider_used: data.providerUsed,
                model_used: data.modelUsed,
                tokens_used: data.tokensUsed,
                error: data.error
            });

        if (error) {
            console.error('Error recording chat analytics:', error);
            // Fallback to localStorage if Supabase insert fails
            const analytics: ChatAnalytics = {
                ...data,
                id: Math.random().toString(36).substring(7)
            };
            const currentData = getStoredAnalytics();
            currentData.push(analytics);
            saveAnalytics(currentData);
        }
    } catch (error) {
        console.error('Error recording chat analytics:', error);
        // Fallback to localStorage
        const analytics: ChatAnalytics = {
            ...data,
            id: Math.random().toString(36).substring(7)
        };
        const currentData = getStoredAnalytics();
        currentData.push(analytics);
        saveAnalytics(currentData);
    }
};

// Get analytics summary from Supabase
export const getAnalyticsSummary = async (skipAIAnalysis: boolean = false): Promise<AnalyticsSummary> => {
    try {
        // Calculate time ranges
        const now = new Date();
        const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

        // Get all analytics data
        const { data: analyticsData, error } = await supabase
            .from('chat_analytics')
            .select('*')
            .order('created_at', { ascending: false });

        if (error) {
            console.error('Error fetching analytics from Supabase:', error);
            // Fallback to localStorage
            return getLocalAnalyticsSummary(skipAIAnalysis);
        }

        // Convert timestamps to Date objects
        const parsedData = analyticsData.map(item => ({
            ...item,
            timestamp: new Date(item.created_at)
        }));

        // Calculate time-based metrics
        const last24Hours = parsedData.filter(item => item.timestamp >= oneDayAgo);
        const lastWeek = parsedData.filter(item => item.timestamp >= oneWeekAgo);
        const lastMonth = parsedData.filter(item => item.timestamp >= oneMonthAgo);

        // Calculate average response times
        const avgResponseTime24h = last24Hours.length > 0
            ? last24Hours.reduce((sum, item) => sum + (item.response_time || 0), 0) / last24Hours.length
            : 0;
        
        const avgResponseTimeWeek = lastWeek.length > 0
            ? lastWeek.reduce((sum, item) => sum + (item.response_time || 0), 0) / lastWeek.length
            : 0;
        
        const avgResponseTimeMonth = lastMonth.length > 0
            ? lastMonth.reduce((sum, item) => sum + (item.response_time || 0), 0) / lastMonth.length
            : 0;

        // Calculate total tokens used
        const tokensUsed24h = last24Hours.reduce((sum, item) => sum + (item.tokens_used || 0), 0);
        const tokensUsedWeek = lastWeek.reduce((sum, item) => sum + (item.tokens_used || 0), 0);
        const tokensUsedMonth = lastMonth.reduce((sum, item) => sum + (item.tokens_used || 0), 0);

        // Get provider usage statistics
        const providerStats = parsedData.reduce((acc, item) => {
            acc[item.provider_used] = (acc[item.provider_used] || 0) + 1;
            return acc;
        }, {} as Record<string, number>);

        // Get model usage statistics
        const modelStats = parsedData.reduce((acc, item) => {
            acc[item.model_used] = (acc[item.model_used] || 0) + 1;
            return acc;
        }, {} as Record<string, number>);

        // Get recent questions with provider/model info
        const recentQuestions = parsedData
            .slice(0, 10)
            .map(item => ({
                id: item.id,
                question: item.question,
                answer: item.answer,
                timestamp: item.timestamp,
                responseTime: item.response_time || 0,
                providerUsed: item.provider_used,
                modelUsed: item.model_used,
                tokensUsed: item.tokens_used
            }));

        // Only run AI analysis if not skipped
        const queryGroups = skipAIAnalysis 
            ? [] 
            : await analyzeQueriesWithAI(parsedData.map(item => item.question));

        return {
            totalInteractions: parsedData.length,
            interactionsLast24h: last24Hours.length,
            interactionsLastWeek: lastWeek.length,
            interactionsLastMonth: lastMonth.length,
            averageResponseTimes: {
                last24h: avgResponseTime24h,
                lastWeek: avgResponseTimeWeek,
                lastMonth: avgResponseTimeMonth
            },
            tokenUsage: {
                last24h: tokensUsed24h,
                lastWeek: tokensUsedWeek,
                lastMonth: tokensUsedMonth
            },
            providerUsage: providerStats,
            modelUsage: modelStats,
            recentQuestions,
            queryGroups
        };
    } catch (error) {
        console.error('Error getting analytics summary:', error);
        // Fallback to localStorage
        return getLocalAnalyticsSummary(skipAIAnalysis);
    }
};

// Delete old analytics from Supabase
export const deleteOldAnalytics = async () => {
    try {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const { data, error } = await supabase
            .from('chat_analytics')
            .delete()
            .lt('created_at', sevenDaysAgo.toISOString())
            .select('id');

        if (error) {
            console.error('Error deleting old analytics:', error);
            return { success: false, error: 'Failed to delete old analytics' };
        }

        return { success: true, deletedCount: data?.length || 0 };
    } catch (error) {
        console.error('Error deleting old analytics:', error);
        return { success: false, error: 'Failed to delete old analytics' };
    }
};

// Delete a specific question from Supabase
export const deleteQuestion = async (questionId: string) => {
    try {
        const { error } = await supabase
            .from('chat_analytics')
            .delete()
            .eq('id', questionId);

        if (error) {
            console.error('Error deleting question:', error);
            return { success: false, error: 'Failed to delete question' };
        }

        return { success: true };
    } catch (error) {
        console.error('Error deleting question:', error);
        return { success: false, error: 'Failed to delete question' };
    }
};

// Rename the old function to serve as a fallback
const getLocalAnalyticsSummary = async (skipAIAnalysis: boolean = false): Promise<AnalyticsSummary> => {
    const analyticsData = getStoredAnalytics();
    
    // Calculate time-based metrics
    const now = new Date();
    const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

    const last24Hours = analyticsData.filter(item => item.timestamp >= oneDayAgo);
    const lastWeek = analyticsData.filter(item => item.timestamp >= oneWeekAgo);
    const lastMonth = analyticsData.filter(item => item.timestamp >= oneMonthAgo);

    // Calculate average response times
    const avgResponseTime24h = last24Hours.length > 0
        ? last24Hours.reduce((sum, item) => sum + (item.responseTime || 0), 0) / last24Hours.length
        : 0;
    
    const avgResponseTimeWeek = lastWeek.length > 0
        ? lastWeek.reduce((sum, item) => sum + (item.responseTime || 0), 0) / lastWeek.length
        : 0;
    
    const avgResponseTimeMonth = lastMonth.length > 0
        ? lastMonth.reduce((sum, item) => sum + (item.responseTime || 0), 0) / lastMonth.length
        : 0;

    // Calculate total tokens used
    const tokensUsed24h = last24Hours.reduce((sum, item) => sum + (item.tokensUsed || 0), 0);
    const tokensUsedWeek = lastWeek.reduce((sum, item) => sum + (item.tokensUsed || 0), 0);
    const tokensUsedMonth = lastMonth.reduce((sum, item) => sum + (item.tokensUsed || 0), 0);

    // Get provider usage statistics
    const providerStats = analyticsData.reduce((acc, item) => {
        acc[item.providerUsed] = (acc[item.providerUsed] || 0) + 1;
        return acc;
    }, {} as Record<string, number>);

    // Get model usage statistics
    const modelStats = analyticsData.reduce((acc, item) => {
        acc[item.modelUsed] = (acc[item.modelUsed] || 0) + 1;
        return acc;
    }, {} as Record<string, number>);

    // Get recent questions with provider/model info
    const recentQuestions = analyticsData
        .slice(-10)
        .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
        .map(item => ({
            id: item.id,
            question: item.question,
            answer: item.answer,
            timestamp: item.timestamp,
            responseTime: item.responseTime || 0,
            providerUsed: item.providerUsed,
            modelUsed: item.modelUsed,
            tokensUsed: item.tokensUsed
        }));

    // Only run AI analysis if not skipped
    const queryGroups = skipAIAnalysis 
        ? [] 
        : await analyzeQueriesWithAI(analyticsData.map(item => item.question));

    return {
        totalInteractions: analyticsData.length,
        interactionsLast24h: last24Hours.length,
        interactionsLastWeek: lastWeek.length,
        interactionsLastMonth: lastMonth.length,
        averageResponseTimes: {
            last24h: avgResponseTime24h,
            lastWeek: avgResponseTimeWeek,
            lastMonth: avgResponseTimeMonth
        },
        tokenUsage: {
            last24h: tokensUsed24h,
            lastWeek: tokensUsedWeek,
            lastMonth: tokensUsedMonth
        },
        providerUsage: providerStats,
        modelUsage: modelStats,
        recentQuestions,
        queryGroups
    };
};

// Export the analyze function so it can be called directly
export const analyzeQueriesWithAI = async (questions: string[]): Promise<QueryGroup[]> => {
    if (questions.length === 0) {
        return [];
    }

    try {
        const prompt = `Analyze and these questions/comments and group similar questions together, filtering out comments that are not questions and displaying a single question represents the "alike" questions. This information will be used to compile an FAQs list. Return a valid JSON array of topic groups.
Format: [{"topic": "string", "questions": [{"question": "string", "count": number}], "totalCount": number}]

Example output:
[{"topic": "Pricing", "questions": [{"question": "How much does it cost?", "count": 1}], "totalCount": 1}]

Questions:
${questions.join('\n')}

Remember: Return ONLY the JSON array, no other text or formatting.`;

        const response = await sendMessage(prompt);
        
        // Clean and format the response
        let cleanedResponse = response.content
            .replace(/```json\s*/g, '')
            .replace(/```\s*/g, '')
            .trim();

        // Extract JSON array if wrapped in other text
        const jsonMatch = cleanedResponse.match(/\[[\s\S]*\]/);
        if (jsonMatch) {
            cleanedResponse = jsonMatch[0];
        }

        try {
            const groups = JSON.parse(cleanedResponse) as QueryGroup[];
            
            // Validate and sanitize the structure
            const validGroups = groups
                .filter(group => {
                    try {
                        return (
                            typeof group === 'object' &&
                            group !== null &&
                            typeof group.topic === 'string' &&
                            Array.isArray(group.questions) &&
                            group.questions.every(q => 
                                typeof q === 'object' &&
                                q !== null &&
                                typeof q.question === 'string' &&
                                typeof q.count === 'number' &&
                                q.count > 0
                            ) &&
                            typeof group.totalCount === 'number' &&
                            group.totalCount > 0
                        );
                    } catch {
                        return false;
                    }
                })
                .map(group => ({
                    topic: group.topic.trim(),
                    questions: group.questions.map(q => ({
                        question: q.question.trim(),
                        count: Math.max(1, Math.round(q.count))
                    })),
                    totalCount: Math.max(1, Math.round(group.totalCount))
                }));

            if (validGroups.length > 0) {
                console.log('AI analysis results:', validGroups);
                return validGroups;
            } else {
                console.warn('No valid groups found after parsing');
                return createDefaultGroup(questions);
            }
        } catch (error) {
            console.error('Error parsing AI response:', error);
            console.log('Raw response:', response);
            console.log('Cleaned response:', cleanedResponse);
            
            // Attempt to fix common JSON issues
            cleanedResponse = cleanedResponse
                .replace(/([{,])\s*(\w+)\s*:/g, '$1 "$2":') // Add quotes around property names
                .replace(/'/g, '"') // Replace single quotes with double quotes
                .replace(/,\s*([}\]])/g, '$1') // Remove trailing commas
                .replace(/\s+/g, ' ') // Normalize whitespace
                .trim();

            try {
                const groups = JSON.parse(cleanedResponse) as QueryGroup[];
                if (groups.length > 0) {
                    console.log('AI analysis results after retry:', groups);
                    return groups;
                }
            } catch (retryError) {
                console.error('Retry parsing failed:', retryError);
            }
            return createDefaultGroup(questions);
        }
    } catch (error) {
        console.error('Error analyzing queries with AI:', error);
        return createDefaultGroup(questions);
    }
};

// Fallback function to create a default group if AI analysis fails
const createDefaultGroup = (questions: string[]): QueryGroup[] => {
    const questionCounts = questions.reduce((acc, q) => {
        acc[q] = (acc[q] || 0) + 1;
        return acc;
    }, {} as Record<string, number>);

    const uniqueQuestions = Object.entries(questionCounts).map(([question, count]) => ({
        question,
        count
    }));

    return [{
        topic: 'All Questions',
        questions: uniqueQuestions,
        totalCount: questions.length
    }];
};
