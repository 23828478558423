import { FC, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Save, ArrowLeft } from 'lucide-react';
import { getTemplate, createTemplate, updateTemplate, EmailTemplate } from '@/services/api/emailTemplates';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

const quillModules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link'],
        ['clean']
    ]
};

const templateTypes = [
    'welcome',
    'notification',
    'marketing',
    'verification',
    'password_reset',
    'custom'
];

const EmailTemplateEditor: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [template, setTemplate] = useState<Partial<EmailTemplate>>({
        name: '',
        subject: '',
        html_content: '',
        template_type: 'custom',
        is_active: true
    });

    useEffect(() => {
        if (id && id !== 'new') {
            loadTemplate();
        }
    }, [id]);

    const loadTemplate = async () => {
        if (!id) return;
        setLoading(true);
        try {
            const { data, error } = await getTemplate(id);
            if (error) throw error;
            if (data) {
                setTemplate(data);
            }
        } catch (error) {
            console.error('Error loading template:', error);
            toast.error('Failed to load template');
            navigate('/admin/email-templates');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        if (!template.name || !template.subject || !template.html_content || !template.template_type) {
            toast.error('Please fill in all required fields');
            return;
        }

        setSaving(true);
        try {
            const { error } = id && id !== 'new'
                ? await updateTemplate(id, template)
                : await createTemplate(template as Omit<EmailTemplate, 'id' | 'created_at' | 'updated_at'>);

            if (error) throw error;

            toast.success(`Template ${id && id !== 'new' ? 'updated' : 'created'} successfully`);
            navigate('/admin/email-templates');
        } catch (error) {
            console.error('Error saving template:', error);
            toast.error('Failed to save template');
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="mb-6 flex justify-between items-center">
                <button
                    onClick={() => navigate('/admin/email-templates')}
                    className="flex items-center gap-2 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                >
                    <ArrowLeft className="w-4 h-4" />
                    Back to Templates
                </button>
                <Button
                    onClick={handleSave}
                    disabled={saving}
                    className="flex items-center gap-2"
                >
                    <Save className="w-4 h-4" />
                    {saving ? 'Saving...' : 'Save Template'}
                </Button>
            </div>

            <div className="space-y-6">
                <div className="grid grid-cols-2 gap-6">
                    <div className="space-y-2">
                        <Label htmlFor="name">Template Name</Label>
                        <Input
                            id="name"
                            value={template.name}
                            onChange={(e) => setTemplate(prev => ({ ...prev, name: e.target.value }))}
                            placeholder="Enter template name"
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="type">Template Type</Label>
                        <Select
                            value={template.template_type}
                            onValueChange={(value) => setTemplate(prev => ({ ...prev, template_type: value }))}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select template type" />
                            </SelectTrigger>
                            <SelectContent>
                                {templateTypes.map((type) => (
                                    <SelectItem key={type} value={type}>
                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div className="space-y-2">
                    <Label htmlFor="subject">Email Subject</Label>
                    <Input
                        id="subject"
                        value={template.subject}
                        onChange={(e) => setTemplate(prev => ({ ...prev, subject: e.target.value }))}
                        placeholder="Enter email subject"
                    />
                </div>

                <div className="space-y-2">
                    <Label>Template Content</Label>
                    <div className="min-h-[400px] border rounded-md">
                        <ReactQuill
                            value={template.html_content}
                            onChange={(content) => setTemplate(prev => ({ ...prev, html_content: content }))}
                            modules={quillModules}
                            className="h-[350px]"
                        />
                    </div>
                </div>

                <div className="flex items-center gap-2">
                    <Switch
                        id="active"
                        checked={template.is_active}
                        onCheckedChange={(checked) => setTemplate(prev => ({ ...prev, is_active: checked }))}
                        className="bg-gray-300 dark:bg-gray-600 data-[state=checked]:bg-primary-500 dark:data-[state=checked]:bg-primary-400 border-2 border-gray-400 dark:border-gray-500"
                    />
                    <Label htmlFor="active" className="text-foreground dark:text-gray-200">Active</Label>
                </div>
            </div>
        </div>
    );
};

export default EmailTemplateEditor;
