import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface TooltipProps {
    content: ReactNode;
    children: ReactNode;
    disabled?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, children, disabled = false }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const tooltipRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isVisible && tooltipRef.current && triggerRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            let x = triggerRect.left;
            let y = triggerRect.bottom + window.scrollY + 8;

            // Adjust horizontal position if tooltip would overflow viewport
            if (x + tooltipRect.width > viewportWidth) {
                x = viewportWidth - tooltipRect.width - 16;
            }

            setPosition({ x, y });
        }
    }, [isVisible]);

    // Close tooltip when clicking outside
    useEffect(() => {
        if (!isVisible) return;

        const handleClickOutside = (event: MouseEvent) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node) &&
                triggerRef.current && !triggerRef.current.contains(event.target as Node)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isVisible]);

    if (disabled || !content) {
        return <>{children}</>;
    }

    return (
        <div
            ref={triggerRef}
            className="relative inline-block"
            onClick={() => setIsVisible(!isVisible)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    setIsVisible(!isVisible);
                }
            }}
        >
            {children}
            <AnimatePresence>
                {isVisible && (
                    <motion.div
                        ref={tooltipRef}
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -5 }}
                        transition={{ duration: 0.15 }}
                        style={{
                            position: 'fixed',
                            left: position.x,
                            top: position.y,
                            zIndex: 9999,
                        }}
                        className="bg-gray-900 dark:bg-gray-800 text-white p-4 rounded-lg shadow-xl max-w-lg border border-gray-700 dark:border-gray-600"
                    >
                        {content}
                        <div className="absolute -top-2 left-4 w-4 h-4 bg-gray-900 dark:bg-gray-800 border-t border-l border-gray-700 dark:border-gray-600 transform rotate-45" />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
