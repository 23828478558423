import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Save, Mail } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { getSMTPSettings, updateSMTPSettings, type SMTPSettings as SMTPSettingsType } from '@services/api/smtp';

interface SMTPConfig extends Omit<SMTPSettingsType, 'id'> {
    id?: string;
}

export default function SMTPSettings() {
    const [config, setConfig] = useState<SMTPConfig>({
        host: '',
        port: 587, // Default SMTP port
        username: '',
        password: '',
        from_email: '',
        from_name: 'ScholarTree'
    });
    const [loading, setLoading] = useState(false);
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            loadSMTPSettings();
        }
    }, []);

    const loadSMTPSettings = async () => {
        try {
            const { data, error } = await getSMTPSettings();
            if (error) throw error;
            if (data) {
                setConfig(data);
            }
        } catch (error) {
            toast.error('Failed to load SMTP settings');
            console.error('Load error:', error);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { error } = await updateSMTPSettings(config);
            if (error) throw error;
            toast.success('SMTP settings updated successfully');
        } catch (error) {
            toast.error('Failed to update SMTP settings');
            console.error('Update error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6"
        >
            <div className="flex items-center gap-2 mb-6">
                <Mail className="w-5 h-5 text-primary-500" />
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">SMTP Settings</h2>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            SMTP Host
                        </label>
                        <input
                            type="text"
                            value={config.host}
                            onChange={(e) => setConfig({ ...config, host: e.target.value })}
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                                     focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
                            placeholder="smtp.example.com"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            SMTP Port
                        </label>
                        <input
                            type="number"
                            value={config.port}
                            onChange={(e) => setConfig({ ...config, port: parseInt(e.target.value) || 587 })}
                            className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                                     focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
                            placeholder="587"
                            min="1"
                            max="65535"
                            required
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        From Email
                    </label>
                    <input
                        type="email"
                        value={config.from_email}
                        onChange={(e) => setConfig({ ...config, from_email: e.target.value })}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                                 focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
                        placeholder="noreply@scholartree.app"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        From Name
                    </label>
                    <input
                        type="text"
                        value={config.from_name}
                        onChange={(e) => setConfig({ ...config, from_name: e.target.value })}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                                 focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        SMTP Username
                    </label>
                    <input
                        type="text"
                        value={config.username}
                        onChange={(e) => setConfig({ ...config, username: e.target.value })}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                                 focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        SMTP Password
                    </label>
                    <input
                        type="password"
                        value={config.password}
                        onChange={(e) => setConfig({ ...config, password: e.target.value })}
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg 
                                 focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:text-white"
                        required
                    />
                </div>

                <div className="flex justify-end items-center pt-4">
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        type="submit"
                        disabled={loading}
                        className={`flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg
                                  hover:bg-primary-600 transition-colors ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        <Save className="w-4 h-4" />
                        {loading ? 'Saving...' : 'Save Settings'}
                    </motion.button>
                </div>
            </form>
        </motion.div>
    );
}
