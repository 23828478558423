import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

interface PrivacyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyDialog = ({ isOpen, onClose }: PrivacyDialogProps) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/70"
          />

          {/* Dialog */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative max-h-[90vh] w-full max-w-4xl overflow-y-auto rounded-2xl bg-gray-900 p-6 shadow-xl"
          >
            <button
              onClick={onClose}
              className="absolute right-4 top-4 rounded-lg p-2 text-gray-400 hover:bg-gray-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>

            <div className="mt-2">
              <h1 className="text-3xl font-bold text-white mb-8">Privacy Policy</h1>
              <p className="text-gray-400 mb-6">Effective Date: 9/15/2024</p>
              
              <div className="prose prose-invert max-w-none">
                <p className="text-gray-300 mb-8">
                  Elyon Labs, LLC is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our iOS app.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">1. Information We Collect</h2>
                <p className="text-gray-300 mb-4">When you use our app, we may collect the following types of information:</p>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li className="mb-2"><span className="font-semibold">Personal Information:</span> If you choose to create an account, we may collect your name, email address, and other personal information you voluntarily provide.</li>
                  <li className="mb-2"><span className="font-semibold">Usage Data:</span> We collect data on how you use the app, including app features you access, time spent in the app, and crash reports, to improve the app experience.</li>
                  <li className="mb-2"><span className="font-semibold">Device Information:</span> We may collect information about your device, including the device model, operating system, and unique device identifiers.</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">2. How We Use Your Information</h2>
                <p className="text-gray-300 mb-4">We use the information we collect to:</p>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li className="mb-2">Provide and improve the functionality of our app.</li>
                  <li className="mb-2">Customize your user experience.</li>
                  <li className="mb-2">Respond to your inquiries and provide support.</li>
                  <li className="mb-2">Analyze app usage and performance to make improvements.</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">3. Data Security</h2>
                <p className="text-gray-300 mb-6">
                  We take the security of your personal information seriously. We use industry-standard encryption protocols and safeguard measures to ensure your data is protected against unauthorized access, disclosure, or modification.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">4. No Sharing with Third Parties</h2>
                <p className="text-gray-300 mb-6">
                  Your personal information will not be shared with or sold to any third-party services, companies, or advertisers. All data collected is used solely for the purpose of improving the app and enhancing your experience within our app.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">5. Your Rights</h2>
                <p className="text-gray-300 mb-4">As a user, you have the right to:</p>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li className="mb-2"><span className="font-semibold">Access and Update:</span> Access or update your personal information stored in the app.</li>
                  <li className="mb-2"><span className="font-semibold">Deletion:</span> Request the deletion of your personal data.</li>
                  <li className="mb-2"><span className="font-semibold">Opt-Out:</span> If you wish to stop sharing any usage data, you may opt out by uninstalling the app or contacting us for further assistance.</li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">6. Changes to the Privacy Policy</h2>
                <p className="text-gray-300 mb-6">
                  We may update this Privacy Policy from time to time. Any changes will be posted within the app, and the "Effective Date" will be updated. By continuing to use our app, you agree to the updated policy.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">7. Contact Us</h2>
                <p className="text-gray-300 mb-4">
                  If you have any questions or concerns about this Privacy Policy, feel free to contact us at:
                </p>
                <p className="text-primary-400 mb-8">
                  support@scholartree.app
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Owner and Data Controller</h2>
                <p className="text-gray-300 mb-4">
                  Elyon Labs, LLC<br />
                  Texas, USA<br />
                  Owner contact email: support@scholartree.app
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default PrivacyDialog;
