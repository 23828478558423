import { Mail, Settings, BarChart, Home, BookOpen, Users, DollarSign, LineChart, Mails } from 'lucide-react';

export const adminNavigation = [
    {
        name: 'Dashboard',
        href: '/admin',
        icon: Home,
    },
    {
        name: 'Blog',
        href: '/admin/blog',
        icon: BookOpen,
    },
    {
        name: 'Newsletter',
        href: '/admin/newsletter',
        icon: Mail,
    },
    {
        name: 'Email Templates',
        href: '/admin/email-templates',
        icon: Mails,
    },
    {
        name: 'AI Settings',
        href: '/admin/ai-settings',
        icon: Settings,
    },
    {
        name: 'Chat Analytics',
        href: '/admin/analytics',
        icon: BarChart,
    },
    {
        name: 'Affiliate Management',
        items: [
            {
                name: 'Affiliates',
                href: '/admin/affiliate/management',
                icon: Users,
            },
            {
                name: 'Analytics',
                href: '/admin/affiliate/analytics',
                icon: LineChart,
            },
            {
                name: 'Payouts',
                href: '/admin/affiliate/payouts',
                icon: DollarSign,
            }
        ]
    }
];
