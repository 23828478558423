import { useEffect } from 'react';

export default function UmamiAnalytics() {
    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.setAttribute('data-website-id', import.meta.env.VITE_UMAMI_WEBSITE_ID || '');
        script.src = import.meta.env.VITE_UMAMI_SCRIPT_URL || '';
        
        document.head.appendChild(script);
        
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
}
