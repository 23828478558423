import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

interface TermsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsDialog = ({ isOpen, onClose }: TermsDialogProps) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/70"
          />

          {/* Dialog */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="relative max-h-[90vh] w-full max-w-4xl overflow-y-auto rounded-2xl bg-gray-900 p-6 shadow-xl"
          >
            <button
              onClick={onClose}
              className="absolute right-4 top-4 rounded-lg p-2 text-gray-400 hover:bg-gray-800 hover:text-white"
            >
              <X className="h-5 w-5" />
            </button>

            <div className="mt-2">
              <h1 className="text-3xl font-bold text-white mb-8">Terms of Service</h1>
              <p className="text-gray-400 mb-6">Last updated: November 24, 2024</p>
              
              <div className="prose prose-invert max-w-none">
                <p className="text-gray-300 mb-8">
                  These Terms govern the use of Scholar Tree ("the Service"). By using the Service, you agree to be bound by these Terms. 
                  If you do not agree, you may not use the Service. If you are using the Service on behalf of an organization, you represent 
                  and warrant that you have the authority to bind that organization to these Terms. In such cases, "you" and "your" will refer 
                  to the organization.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Owner and Data Controller</h2>
                <p className="text-gray-300 mb-4">
                  Elyon Labs, LLC<br />
                  Texas, USA<br />
                  Owner contact email: support@scholartree.app
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Content on Scholar Tree</h2>
                <p className="text-gray-300 mb-6">
                  Unless otherwise stated, all content on Scholar Tree is owned by the Owner or its licensors. The Owner makes every effort 
                  to ensure that the content is lawful and does not infringe on third-party rights. However, it cannot guarantee this in all cases.
                  Users are requested to report any issues or complaints regarding content using the contact details provided in these Terms.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Cookies and Tracking Tools</h2>
                <p className="text-gray-300 mb-6">
                  Scholar Tree may use cookies and other tracking tools to provide and enhance the Service. By using the Service, you consent 
                  to the use of cookies as described in our Cookie Policy.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Access to External Resources</h2>
                <p className="text-gray-300 mb-6">
                  Scholar Tree may provide access to external resources. The Owner does not have control over third-party content or availability 
                  and is not responsible for it. Users must comply with any third-party terms associated with such resources.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Acceptable Use</h2>
                <p className="text-gray-300 mb-6">
                  You may use Scholar Tree only as permitted by these Terms and applicable laws. Users are responsible for ensuring their actions 
                  on the Service do not violate any laws, regulations, or third-party rights.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Common Provisions</h2>
                <h3 className="text-xl font-semibold text-white mt-6 mb-3">No Waiver</h3>
                <p className="text-gray-300 mb-4">
                  Failure by the Owner to enforce any right under these Terms does not constitute a waiver of those rights.
                </p>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Service Interruption</h3>
                <p className="text-gray-300 mb-4">
                  The Owner reserves the right to interrupt the Service for maintenance, updates, or other changes. The Owner may suspend or 
                  terminate the Service entirely, notifying Users as required by law.
                  In the event of termination, the Owner will cooperate with Users to retrieve their data where legally required. Interruptions 
                  caused by external factors such as force majeure (e.g., natural disasters, strikes, or infrastructure failure) are beyond the 
                  Owner's control.
                </p>

                <h3 className="text-xl font-semibold text-white mt-6 mb-3">Service Reselling</h3>
                <p className="text-gray-300 mb-4">
                  You may not copy, sell, resell, or exploit any part of Scholar Tree without prior written permission from the Owner.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Privacy Policy</h2>
                <p className="text-gray-300 mb-6">
                  Refer to the Scholar Tree Privacy Policy for details on how your personal data is handled.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Intellectual Property Rights</h2>
                <p className="text-gray-300 mb-6">
                  All intellectual property rights, including but not limited to copyrights, trademarks, and patents associated with Scholar Tree, 
                  are owned by the Owner or its licensors. These rights are protected by applicable laws and international treaties.
                  The use of trademarks, logos, or other marks associated with Scholar Tree requires explicit permission.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Changes to These Terms</h2>
                <p className="text-gray-300 mb-6">
                  The Owner reserves the right to modify these Terms at any time. Users will be notified of significant changes. Continued use 
                  of the Service constitutes acceptance of the revised Terms. Users who do not accept changes must discontinue use of the Service.
                  Users are responsible for staying updated with the latest version of these Terms, which can be found on the Scholar Tree website.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Assignment of Contract</h2>
                <p className="text-gray-300 mb-6">
                  The Owner may transfer or assign its rights and obligations under these Terms at its discretion. Users may not assign their 
                  rights or obligations without prior written consent from the Owner.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Service Availability</h2>
                <p className="text-gray-300 mb-6">
                  Scholar Tree aims to maintain consistent service availability but does not guarantee uninterrupted access. The Owner is not 
                  liable for disruptions caused by external circumstances or necessary maintenance.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Severability</h2>
                <p className="text-gray-300 mb-6">
                  If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Contacts</h2>
                <p className="text-gray-300 mb-6">
                  All inquiries related to Scholar Tree should be sent to: support@scholartree.app
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Definitions and Legal References</h2>
                <ul className="list-disc pl-6 text-gray-300 mb-6">
                  <li className="mb-2"><span className="font-semibold">Scholar Tree (or this Application):</span> The platform and tools provided to Users.</li>
                  <li className="mb-2"><span className="font-semibold">Agreement:</span> The legally binding relationship between the Owner and the User governed by these Terms.</li>
                  <li className="mb-2"><span className="font-semibold">Owner (or We):</span> Elyon Labs, LLC, the entity providing the Service.</li>
                  <li className="mb-2"><span className="font-semibold">Service:</span> The offerings provided by Scholar Tree as described in these Terms.</li>
                  <li className="mb-2"><span className="font-semibold">User (or You):</span> Any person or entity using Scholar Tree.</li>
                  <li className="mb-2"><span className="font-semibold">Terms:</span> The provisions outlined in this document and any related agreements.</li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default TermsDialog;
