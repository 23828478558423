import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Wand2, Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { sendMessage } from '@/services/core/api';
import { useAISettings } from '@/hooks/useAISettings';

interface AIBlogAssistantProps {
    onGenerate: (content: {
        title: string;
        excerpt: string;
        content: string;
        meta_title: string;
        meta_description: string;
    }) => void;
}

export default function AIBlogAssistant({ onGenerate }: AIBlogAssistantProps) {
    const [topic, setTopic] = useState('');
    const [tone, setTone] = useState('professional');
    const [length, setLength] = useState('medium');
    const [isGenerating, setIsGenerating] = useState(false);
    const { settings: aiSettings, loading: settingsLoading } = useAISettings();

    const generateBlogPost = async () => {
        if (!topic) {
            toast.error('Please enter a topic');
            return;
        }

        if (settingsLoading) {
            toast.error('AI settings are still loading');
            return;
        }

        const selectedModel = aiSettings.selectedModel[aiSettings.provider];
        const model = aiSettings.models[aiSettings.provider].find(m => m.id === selectedModel);

        console.log('AI Settings:', {
            provider: aiSettings.provider,
            selectedModel,
            models: aiSettings.models[aiSettings.provider],
            foundModel: model
        });

        if (!model?.is_available) {
            toast.error('Selected AI model is not available');
            return;
        }

        setIsGenerating(true);
        try {
            // Create a structured prompt for the AI with strict formatting instructions
            const prompt = `Generate a blog post about: "${topic}"

Instructions:
- Tone: ${tone}
- Length: ${length}
- Use proper markdown formatting
- Avoid using any special characters or escape sequences in the content
- Keep paragraphs concise and well-structured

Respond with a valid JSON object in exactly this format:
{
    "title": "Your Title Here",
    "excerpt": "A brief 2-3 sentence excerpt",
    "content": "The full blog post content",
    "meta_title": "SEO title (max 60 chars)",
    "meta_description": "SEO description (max 160 chars)"
}`;

            const response = await sendMessage(prompt);
            
            try {
                // Clean the response to ensure valid JSON
                const cleanResponse = response.content
                    .trim()
                    // Remove any markdown code block indicators
                    .replace(/```json\n?/g, '')
                    .replace(/```\n?/g, '')
                    // Ensure proper line endings
                    .replace(/\r\n/g, '\n')
                    // Remove any trailing commas
                    .replace(/,(\s*[}\]])/g, '$1');

                const blogData = JSON.parse(cleanResponse);

                // Validate the required fields
                const requiredFields = ['title', 'excerpt', 'content', 'meta_title', 'meta_description'];
                const missingFields = requiredFields.filter(field => !blogData[field]);

                if (missingFields.length > 0) {
                    throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
                }

                onGenerate(blogData);
                toast.success('Blog post generated successfully!');
            } catch (parseError) {
                console.error('Error parsing AI response:', parseError);
                console.error('Raw response:', response.content);
                toast.error('Failed to parse AI response. Please try again.');
            }
        } catch (error) {
            console.error('Error generating blog post:', error);
            const errorMessage = error instanceof Error ? error.message : 'An error occurred';
            
            // Check for quota exceeded error
            if (errorMessage.toLowerCase().includes('quota') || errorMessage.toLowerCase().includes('insufficient_quota')) {
                toast.error('API quota exceeded. Please check your OpenAI API usage limits or try again later.');
            } else if (errorMessage.toLowerCase().includes('api key')) {
                toast.error('API key error. Please check your API key configuration.');
            } else {
                toast.error('Failed to generate blog post. Please try again.');
            }
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                AI Blog Assistant
            </h2>
            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                        Topic or Main Idea
                    </label>
                    <input
                        type="text"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                            dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        placeholder="Enter your blog topic or main idea"
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Tone
                        </label>
                        <select
                            value={tone}
                            onChange={(e) => setTone(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        >
                            <option value="professional">Professional</option>
                            <option value="casual">Casual</option>
                            <option value="friendly">Friendly</option>
                            <option value="authoritative">Authoritative</option>
                            <option value="educational">Educational</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Length
                        </label>
                        <select
                            value={length}
                            onChange={(e) => setLength(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        >
                            <option value="short">Short (~500 words)</option>
                            <option value="medium">Medium (~1000 words)</option>
                            <option value="long">Long (~2000 words)</option>
                        </select>
                    </div>
                </div>

                <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={generateBlogPost}
                    disabled={isGenerating}
                    className="w-full px-4 py-2 bg-primary-500 hover:bg-primary-600 text-white rounded-lg 
                        flex items-center justify-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {isGenerating ? (
                        <>
                            <Loader2 className="w-4 h-4 animate-spin" />
                            Generating...
                        </>
                    ) : (
                        <>
                            <Wand2 className="w-4 h-4" />
                            Generate Blog Post
                        </>
                    )}
                </motion.button>
            </div>
        </div>
    );
}
