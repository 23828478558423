import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronLeft } from 'lucide-react';
import { affiliateApi } from '@/services/api/affiliate';
import type { AffiliateTier } from '@/types/affiliate';
import { useAuth } from '@/hooks/useAuth';

const AffiliateApplicationPage: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        website: '',
        social_links: {
            instagram: '',
            twitter: '',
            linkedin: '',
            facebook: '',
            other: ''
        },
        audience_description: '',
        preferred_tier: 'basic' as AffiliateTier
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!user) {
            setError('You must be logged in to submit an application');
            setLoading(false);
            return;
        }

        try {
            await affiliateApi.submitApplication({
                ...formData,
                user_id: user.id,
                social_links: Object.fromEntries(
                    Object.entries(formData.social_links).filter(([_, value]) => value)
                )
            });
            navigate('/affiliate/thank-you');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred while submitting your application');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name.startsWith('social_links.')) {
            const socialPlatform = name.split('.')[1];
            setFormData(prev => ({
                ...prev,
                social_links: {
                    ...prev.social_links,
                    [socialPlatform]: value
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 py-12">
            <div className="container mx-auto px-4">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="max-w-2xl mx-auto"
                >
                    <button
                        onClick={() => navigate('/affiliate')}
                        className="inline-flex items-center gap-2 text-gray-400 hover:text-primary-400 transition-colors duration-200 mb-8"
                    >
                        <ChevronLeft className="w-4 h-4" />
                        Back to Affiliate Program
                    </button>

                    <div className="bg-white/5 backdrop-blur-xl border border-white/10 rounded-2xl p-8">
                        <h1 className="text-3xl font-bold text-white mb-2">Affiliate Application</h1>
                        <p className="text-gray-400 mb-8">
                            Fill out the form below to apply for our affiliate program. We'll review your application
                            and get back to you within 2-3 business days.
                        </p>

                        {error && (
                            <div className="bg-red-500/10 border border-red-500/50 rounded-lg p-4 mb-6 text-red-400">
                                {error}
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-300 mb-2">
                                        First Name *
                                    </label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        name="first_name"
                                        required
                                        value={formData.first_name}
                                        onChange={handleInputChange}
                                        className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500
                                            focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                        placeholder="Enter your first name"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-300 mb-2">
                                        Last Name *
                                    </label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        name="last_name"
                                        required
                                        value={formData.last_name}
                                        onChange={handleInputChange}
                                        className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500
                                            focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                        placeholder="Enter your last name"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                                    Email Address *
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500
                                        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                    placeholder="Enter your email address"
                                />
                            </div>

                            <div>
                                <label htmlFor="website" className="block text-sm font-medium text-gray-300 mb-2">
                                    Website (Optional)
                                </label>
                                <input
                                    type="url"
                                    id="website"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500
                                        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                    placeholder="https://your-website.com"
                                />
                            </div>

                            <div className="space-y-4">
                                <label className="block text-sm font-medium text-gray-300">
                                    Social Media Links (Optional)
                                </label>
                                {Object.keys(formData.social_links).map(platform => (
                                    <input
                                        key={platform}
                                        type="url"
                                        name={`social_links.${platform}`}
                                        value={formData.social_links[platform as keyof typeof formData.social_links]}
                                        onChange={handleInputChange}
                                        className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500
                                            focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                        placeholder={`${platform.charAt(0).toUpperCase() + platform.slice(1)} URL`}
                                    />
                                ))}
                            </div>

                            <div>
                                <label htmlFor="audience_description" className="block text-sm font-medium text-gray-300 mb-2">
                                    Audience Description *
                                </label>
                                <textarea
                                    id="audience_description"
                                    name="audience_description"
                                    required
                                    value={formData.audience_description}
                                    onChange={handleInputChange}
                                    rows={4}
                                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-500
                                        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                    placeholder="Describe your audience, their interests, and how you plan to promote ScholarTree"
                                />
                            </div>

                            <div>
                                <label htmlFor="preferred_tier" className="block text-sm font-medium text-gray-300 mb-2">
                                    Preferred Tier *
                                </label>
                                <select
                                    id="preferred_tier"
                                    name="preferred_tier"
                                    required
                                    value={formData.preferred_tier}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-white
                                        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                                >
                                    <option value="basic">Basic (10% commission)</option>
                                    <option value="advanced">Advanced (15% commission)</option>
                                    <option value="elite">Elite (24% commission)</option>
                                </select>
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className={`w-full py-3 px-4 rounded-lg text-white font-medium transition duration-200
                                    ${loading
                                        ? 'bg-primary-500/50 cursor-not-allowed'
                                        : 'bg-primary-500 hover:bg-primary-600'
                                    }`}
                            >
                                {loading ? 'Submitting...' : 'Submit Application'}
                            </button>
                        </form>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default AffiliateApplicationPage;
